import React from 'react';
import { Component } from 'react';
import ActionButton from '../component/action-button';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import Moment from 'react-moment';
import moment from 'moment';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import {
    addRefferal,
    getRefferal,
    updateReferral,
    referralStatus
} from '../api/apiService';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}
class Reference extends Component {
    constructor() {
        super();
        this.state = {
            maxRefer: "",
            note: "",
            expairyDate: "",
            referDetails: [],
            referId: "",
            isSubmitted: false
        }
        this.allReferral();
    }
    handleMaxRefer = (e) => {
        this.setState({
            maxRefer: e.target.value
        }, () => {
            return this.state.isSubmitted ? this.valid() : false
        })
    }
    handleNote = (e) => {
        this.setState({
            note: e.target.value
        }, () => {
            return this.state.isSubmitted ? this.valid() : false
        })
    }
    handleExpairyDate = (e) => {
        this.setState({
            expairyDate: e.target.value
        }, () => {
            return this.state.isSubmitted ? this.valid() : false
        })
    }
    hendleClear = () => {
        this.setState({
            maxRefer: "",
            note: "",
            expairyDate: "",
            referId: "",
        })
    }
    // Validation----
    valid() {
        let checked = true;
        if (this.state.maxRefer.length < 1) {
            checked = false;
        }
        if (this.state.note.length < 1) {
            checked = false;
        }
        if (this.state.expairyDate.length < 6) {
            checked = false;
        }
        return checked;
    }
    // Api call-------
    createReferral = async () => {
        const data = {
            "max_refer_count": this.state.maxRefer,
            "note": this.state.note,
            "expiry_date": this.state.expairyDate
        }
        store.dispatch(loader(true));
        try {
            if (this.state.referId === "") {
                const referralAdd = await addRefferal(data);
                ToastSuccess(referralAdd.data.message)
                this.allReferral();
                store.dispatch(loader(false));
            } else {
                const editReferral = await updateReferral(this.state.referId, data)
                ToastSuccess(editReferral.data.message)
                this.allReferral();
                store.dispatch(loader(false));
            }

        } catch (error) {
            console.log(error);
            ToastError(error.data.message)
            store.dispatch(loader(false));
        }
    }
    allReferral = async () => {
        store.dispatch(loader(true));
        try {
            const referralsDetails = await getRefferal();
            this.setState({ referDetails: referralsDetails.data.data })
            store.dispatch(loader(false));
        } catch (error) {
            console.log(error);
            store.dispatch(loader(false));
        }
    }
    handleReferStatus = async (id) => {
        try {
            const refarStatus = await referralStatus(id)
            this.allReferral();
            console.log(refarStatus);
            ToastSuccess(refarStatus.data.message)
        } catch (error) {
            console.log(error);
            ToastError(error.data.message)
        }
    }
    // Submit your from-----
    submitted() {
        this.setState({ isSubmitted: true })
        if (this.valid()) {
            this.createReferral();
        }
    }
    render() {
        return (
            <div className="row my-4">
                <div className="col-sm-8">
                    <div className="category-container">
                        <h4 className="text-secondary">Referral</h4>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>Referral Code</th>
                                    <th className="text-center">Max refer</th>
                                    <th>Note</th>
                                    <th>Create date</th>
                                    <th>Expairy date</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.referDetails.map(item => {
                                    return <tr key={item._id}>
                                        <td>{item.referral_code}</td>
                                        <td className="text-center">{item.max_refer_count}</td>
                                        <td>{item.note}</td>
                                        <td><Moment format="DD-MM-YYYY">
                                            {item.createdDate}
                                        </Moment></td>
                                        <td><Moment format="DD-MM-YYYY">
                                            {item.expiry_date}
                                        </Moment></td>
                                        <td>{item.isActive ? <span onClick={() => this.handleReferStatus(item._id)} className="badge badge-success cursor-pointer">Active</span> : <span onClick={() => this.handleReferStatus(item._id)} className="badge badge-danger cursor-pointer">Deactive</span>}</td>
                                        <td className="text-center">
                                            <span onClick={() => {
                                                this.setState({
                                                    referId: item._id,
                                                    maxRefer: item.max_refer_count,
                                                    note: item.note,
                                                    expairyDate: moment(item.expiry_date).format('yyyy-MM-DD'),
                                                }, () => {
                                                    console.log(this.state.expairyDate)
                                                })
                                            }
                                            }>
                                                <ActionButton>
                                                    <i className="demo-icon icon-edit">&#xe80f;</i>
                                                </ActionButton>
                                            </span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="add-category-sticky">
                        <div className="category-container">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-secondary ml-2">Create Referral</h5>

                                {this.state.referId === "" ? "" : <button onClick={() => this.hendleClear()} className="clear-btn">Clear</button>}
                            </div>
                            <div className="category-field">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Maximum refer count</label>
                                    <input
                                        onChange={(e) => this.handleMaxRefer(e)}
                                        style={this.state.maxRefer < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                        type="number"
                                        value={this.state.maxRefer}
                                        className="form-control"
                                        placeholder="Max-refer-count" />
                                </div>
                            </div>
                            <div className="category-field">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Note</label>
                                    <input
                                        onChange={(e) => this.handleNote(e)}
                                        style={this.state.note < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                        type="text"
                                        value={this.state.note}
                                        className="form-control"
                                        placeholder="Note" />
                                </div>
                            </div>
                            <div className="category-field">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Expairy data</label>
                                    <input
                                        onChange={(e) => this.handleExpairyDate(e)}
                                        style={this.state.expairyDate < 12 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                        type="date"
                                        value={this.state.expairyDate}
                                        className="form-control"
                                        placeholder="Expairy data" />
                                </div>
                                <div className="text-right">
                                    <button
                                        onClick={() => this.submitted()}
                                        className="btn btn-verify mt-2">{this.state.referId === "" ? "Add" : "Update"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Reference);
import React from 'react';

function ItemManagement(props) {
    return (
            <div>
                {props.children}
            </div>
    );
}

export default ItemManagement;
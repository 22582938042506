import axios from 'axios';
export default axios.create({
    baseURL : process.env.REACT_APP_BASE_URL,
    timeout : 30000,
    headers :{
        'Content-Type': 'application/json', 
        'x-user-type' : 'admin',
        'Authorization' : localStorage.getItem('token')
    }
});

export const notifier = axios.create({
    baseURL : process.env.REACT_APP_NOTIFIER_URL || process.env.REACT_APP_BASE_URL,
    timeout : 30000,
    headers :{
        'Content-Type': 'application/json', 
        'x-user-type' : 'admin',
        'Authorization' : localStorage.getItem('token')
    }
});

export const order = axios.create({
    baseURL : process.env.REACT_APP_ORDER_URL || process.env.REACT_APP_BASE_URL,
    timeout : 30000,
    headers :{
        'Content-Type': 'application/json', 
        'x-user-type' : 'admin',
        'Authorization' : localStorage.getItem('token')
    }
});

export const auction = axios.create({
    baseURL : process.env.REACT_APP_AUCTION_URL || process.env.REACT_APP_BASE_URL,
    timeout : 30000,
    headers :{
        'Content-Type': 'application/json', 
        'x-user-type' : 'admin',
        'Authorization' : localStorage.getItem('token')
    }
});

export const scheduler = axios.create({
    baseURL : process.env.REACT_APP_SCHEDULER_URL || process.env.REACT_APP_BASE_URL,
    timeout : 30000,
    headers :{
        'Content-Type': 'application/json', 
        'x-user-type' : 'admin',
        'Authorization' : localStorage.getItem('token')
    }
});

import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import UserOrderDetails from './user-order-details-table';
import OwnerOutletList from './owner-outlet-list-table';
import AuctionDetails from './auction-details-table';
import AccountSection from './account-section';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
export default function UserDetailsTab(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div>
            <div className="d-inline-block">
                {props.isOwner === false ?
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Order Details" {...a11yProps(0)} />
                            <Tab label="Auction Details" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    :
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Outlet List" {...a11yProps(0)} />
                            <Tab label="Order Details" {...a11yProps(1)} />
                            <Tab label="Auction Details" {...a11yProps(2)} />
                            <Tab label="Account" {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                }
            </div>
            {/* <div className="d-inline-block float-md-right mt-3">
                <span className="mr-5">See Newer</span>
                <span>See Older</span>
            </div> */}
            <div>
                {props.isOwner === false ? <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >

                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <UserOrderDetails userId={props.userId} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <AuctionDetails userId={props.userId} />
                    </TabPanel>
                </SwipeableViews>
                    :
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <OwnerOutletList data={props.data} />
                        </TabPanel>

                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <UserOrderDetails userId={props.userId} />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <AuctionDetails userId={props.userId} />
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>
                            <AccountSection userAc={props.uDetails} rerenderParentCallback={props.rerenderParentCallback} />
                        </TabPanel>
                    </SwipeableViews>
                }

            </div>
        </div>
    );
}

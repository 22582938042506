import React from 'react';

export default function ActionButton(props) {
  return (
    <>
      <button className="icon-button" onClick={props.onPress}>
        {props.children}
      </button>
    </>

  );
}

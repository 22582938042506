import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SearchIcon from '@material-ui/icons/Search';
import { getDateByOrders } from '../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import { CSVLink } from "react-csv";

const mapStateToProps = (state) => {
    return {
        loaderDisplay: state.loaderDisplay
    }
}
class OrderReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            startDate: "",
            endDate: "",
            dateOrders: [],
            dataDown: [],
            headers: []
        }
        this.getDate();
    }
    /*
    * Date
    * Today & 15 Days Before
    */
    getDate = () => {
        var today_datetime = new Date();
        let today_date = moment.utc(today_datetime).format("YYYY-MM-DD");
        var last = (today_datetime - 15 * 24 * 60 * 60 * 1000); // 15 Days ago date
        var ago_date = moment.utc(last).format("YYYY-MM-DD"); // 15 Days ago date format
        this.dateByOrders(ago_date, today_date, 1) // function call
    }
    /*
    * Input Event Handler
    */
    handleStartDate = (e) => {
        const date = moment.utc(e.target.value).format("YYYY-MM-DD")
        this.setState({ startDate: date }, () => {
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleEndDate = (e) => {
        const date = moment.utc(e.target.value).format("YYYY-MM-DD")
        this.setState({ endDate: date }, () => {
            console.log(this.state.endDate);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    /*
    * Validation
    * For Orders Search
    */
    valid = () => {
        let checked = true;
        if (this.state.startDate === "") {
            checked = false;
        }
        if (this.state.endDate === "") {
            checked = false;
        }
        return checked;
    }

    /*
    * Button Submit
    * Date By Orders Search..
    */
    dateByOrderSearch = (e) => {
        e.preventDefault()
        this.setState({ isSubmitted: true })
        if (this.valid()) {
            this.dateByOrders(this.state.startDate, this.state.endDate, 1)
        }
    }
    /*
    * CSV
    * Download ---
    */
    downloadCsv = (csvData) => {
        let data = []
        csvData.forEach(item => {
            let pro = []
            let qunty = []
            let price = 0

            let withoutDelivary = (item.orderAmount - item.deliveryCharge) //With out delivary charge
            // -2% for all foods
            var cashFreeAmount = parseFloat((withoutDelivary * 2) / 100);
            price = (withoutDelivary - cashFreeAmount)

            for (let i = 0; i < item.foods.length; i++) {
                const role = item.foods[i];
                pro.push(role.product.product_name);
                qunty.push(role.quantity);
            }
            data.push({
                createdDate: moment(item.createdDate).format('DD/MM/YYYY HH:mm'),
                orderType: item.orderType === 0 ? "Normal" : "Auction",
                outletName: item.outlet.outlet_name,
                userName: item.user.full_name,
                userId: item.user._id,
                orderId: item.orderId,
                deliveryAddress: item.deliveryAddress.address,
                pincode: item.deliveryAddress.pincode,
                discount: item.discount,
                customerPay: item.orderAmount,
                orderAmount: withoutDelivary,
                merchantPayout: price,
                product: `${pro}(Qty-${qunty})`,
                deliveryType: item.deliveryType,
                deliveryStatus: item.deliveryStatus
            });
        });
        console.log("data: ",data);
        let header = [
            { label: "Order Date", key: "createdDate" },
            { label: "Order Type", key: "orderType" },
            { label: "Outlet Name", key: "outletName" },
            { label: "Customer Name", key: "userName" },
            { label: "Customer Id", key: "userId" },
            { label: "Order Id", key: "orderId" },
            { label: "Delivery Address", key: "deliveryAddress" },
            { label: "Pincode", key: "pincode" },
            { label: "Discount", key: "discount" },
            { label: "Customer Pay Amount", key: "customerPay" },
            { label: "Order Amount", key: "orderAmount" },
            { label: "Merchant payout", key: "merchantPayout" },
            { label: "Order Details", key: "product" },
            { label: "Delivery Type", key: "deliveryType" },
            { label: "Delivery Status", key: "deliveryStatus" }
        ];
        this.setState({ dataDown: data, headers: header })
    }

    /*
    * Api
    * Date By Orders details
    */
    dateByOrders = async (sDate, eDate, pageNo) => {
        store.dispatch(loader(true));
        try {
            const orders = await getDateByOrders(sDate, eDate, pageNo);
            this.downloadCsv(orders.data.data);
            this.setState({
                dateOrders: orders.data.data,
                startDate: sDate,
                endDate: eDate
            })
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    render() {
        return (
            <div>
                <div className="category-container my-4">
                    <div className="d-flex justify-content-sm-between align-items-center">
                        <form className="d-flex my-2 mr-2">
                            <div className="form-group m-0 mr-2 d-flex align-items-center">
                                <b className="mr-1">To:</b>
                                <input
                                    onChange={(e) => this.handleStartDate(e)} // 15 days ago date
                                    style={this.state.startDate.length < 8 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                    type="date"
                                    value={this.state.startDate}
                                    className="form-tab-field"
                                />
                            </div>
                            <div className="form-group m-0 mr-2 d-flex align-items-center">
                                <b className="mr-1">From:</b>
                                <input
                                    onChange={(e) => this.handleEndDate(e)} // Today date
                                    style={this.state.endDate.length < 8 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                    type="date"
                                    value={this.state.endDate}
                                    className="form-tab-field"
                                />
                            </div>
                            <button className="btn btn-verify mr-2"
                                onClick={this.dateByOrderSearch}
                                title="Search Orders">
                                <SearchIcon />
                            </button>
                        </form>
                        {this.state.dateOrders.length === 0 ? "" :
                            <CSVLink data={this.state.dataDown} headers={this.state.headers} filename={"Hungry-top.csv"}>
                                <Tooltip title="Download CSV File" placement="top" arrow>
                                    <button className="btn btn-verify my-2 mr-2">
                                        <CloudDownloadIcon />
                                    </button>
                                </Tooltip>
                            </CSVLink>
                        }
                    </div>
                    {this.state.dateOrders.length === 0 ?
                        <div></div>
                        :
                        <>
                            <hr />
                            <table className="table dash-table table-hover text-secondary">
                                <thead>
                                    <tr className="text-left">
                                        <th>Date/Amount</th>
                                        <th>Order Id/Type</th>
                                        <th>User</th>
                                        <th>Outlet</th>
                                        <th>Payment Mode</th>
                                        <th>Payment Status</th>
                                        <th>Order Status</th>
                                        <th className="text-center">Delivery Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.dateOrders.map(item => {
                                        return <tr key={item._id} className="text-left">
                                            <td>
                                                <Moment format="DD-MM-YY HH:mm">
                                                    {item.createdDate}
                                                </Moment>
                                                <br />
                                                <span className="font-12">Amount ₹{item.orderAmount}</span>

                                            </td>
                                            <td>
                                                <Link to={`/orders/user-order-ditails/${item.orderId}`}>{item.orderId}</Link><br />
                                                <span className="font-12">{item.orderType === 0 ? "Normal" : "Auction"}</span>

                                            </td>
                                            <td><Link to={`/users-management/user-details/${item.userId}`}>{item.user.full_name}</Link></td>
                                            <td><Link style={{ fontSize: "15px" }} to={`/outlets/products/${item.outletId}`}>{item.outlet.outlet_name}</Link></td>
                                            <td>{item.paymentMode}</td>
                                            <td>
                                                <span className={`badge ${item.paymentStatus === "SUCCESS" ? "badge-success" : "badge-danger"}`}>{item.paymentStatus}</span>
                                            </td>
                                            <td>
                                                <span className={`badge ${item.orderStatus === "completed" ? "badge-success" : item.orderStatus === "preparing" ?
                                                    "badge-primary" : item.orderStatus === "out-for-delivery" ?
                                                        "badge-info" : item.orderStatus === "submitted" ?
                                                            "badge-warning" : "badge-danger"}`}>
                                                    {item.orderStatus}
                                                </span>
                                            </td>
                                            <td className="text-center">{item.deliveryStatus}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(OrderReportPage);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chart } from "react-google-charts";
import {getMonthlyReport} from '../api/apiService';
import { loader } from '../Actions/index';
import store from '../store';
export const data = [
    ["Year", "Sales"],
    ["2014", 1000],
    ["2015", 1170],
    ["2016", 660],
    ["2017", 1030],
  ];
  
  export const options = {
    animation: {
        startup: true,
        easing: 'linear'
    },
    colors: [
        '#293B3D',
        '#E36C4C',
        '#B8A1C3',
        '#E3B74C',
        '#C0BAA6'
    ]
  };

  
const mapStateToProps = (state) => {
    return {
        loaderDisplay: state.loaderDisplay
    }
}

class ReportOverView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: {}
        }
        this.allOrders();
        
    }

    allOrders = async () => {
        store.dispatch(loader(true));
        try {
            const aOrders = await getMonthlyReport(new Date());
            this.setState({ chartData: aOrders.data.data.charts });
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    
    render() {
        console.log(this.state.chartData);
        return (
            <div>
                <div className='row my-4'>
                    <div className="col-sm-6">
                        <div className="category-container">
                            <div className='graph-header'>
                                <h3>Auctions Order Graph</h3>
                                <p>Last 12 months auctions order data</p>
                            </div>
                            <Chart
                                height={'200px'}
                                width={'100%'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chartData.last12MonthAuctionOrdersChart}
                                options={{
                                    chart: {
                                        legend: "none",
                                        seriesType: 'bars',
                                      },
                                      legend: "none",
                                    // colors: ['#93a872']
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="category-container">
                            <div className='graph-header'>
                                <h3>Normal Order Graph</h3>
                                <p>Last 12 months normal order data</p>
                            </div>
                            <Chart
                                height={'200px'}
                                width={'100%'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chartData.last12MonthNormalOrdersChart}
                                options={{
                                    chart: {
                                        legend: "none",
                                        seriesType: 'bars',
                                      },
                                      legend: "none",
                                    colors: ['#93a872']
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-7 my-4">
                        <div className="category-container">
                            <div className='graph-header'>
                                <h3>Total Order Graph</h3>
                                <p>Last 12 months total order data</p>
                            </div>
                            <Chart
                                height={'200px'}
                                width={'100%'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chartData.last12MonthTotalOrdersValueChart}
                                options={{
                                    chart: {
                                        legend: "none",
                                        seriesType: 'bars',
                                      },
                                      legend: "none",
                                    colors: ['#3f4040']
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-5 my-4">
                        <div className="category-container">
                        <div className='graph-header'>
                                <h3>Outlet Graph</h3>
                                <p>Last 12 months orders data</p>
                            </div>
                            <Chart
                                height={'200px'}
                                width={'100%'}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chartData.last12MonthOutletsChart}
                                options={options}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 my-2">
                        <div className="category-container">
                        <div className='graph-header'>
                                <h3>Subscription Graph</h3>
                                <p>last 12 month subscription</p>
                            </div>
                            <Chart
                                height={'250px'}
                                width={'100%'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chartData.last12MonthSubscriptionChart}
                                options={{
                                    chart: {
                                        legend: "none"
                                      },
                                      legend: "none"
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-7 my-4">
                        <div className="category-container">
                            <div className='graph-header'>
                                <h3>Users Graph</h3>
                                <p>Last 12 months users data</p>
                            </div>
                            <Chart
                                height={'200px'}
                                width={'100%'}
                                chartType="Line"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chartData.last12MonthUsersChart}
                                options={{
                                    colors: ['#043811'],
                                    legend: "none",
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-5 my-4">
                        <div className="category-container">
                        <div className='graph-header'>
                                <h3>Bids Graph</h3>
                                <p>Last 12 months bids data</p>
                            </div>
                            <Chart
                                height={'200px'}
                                width={'100%'}
                                chartType="BarChart"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chartData.last12MonthBidsChart}
                                options={{
                                    colors: ['#f76d7d']
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ReportOverView);
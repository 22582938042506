import React, { useState, useEffect } from 'react';
import UserTab from '../component/c-usermanagement/user-manage-tab';
import { useHistory } from 'react-router-dom';
import { getAllUsers } from '../api/apiService';
import { loader } from '../Actions/index';
import { useDispatch } from 'react-redux';
import TabletMacIcon from '@material-ui/icons/TabletMac';

const UserManagement = () => {
    const history = useHistory();
    const [userData, setUserData] = useState();
    const [type, setType] = useState("user");
    // const [noPage, setNoPage] = useState("1");
    // const [ownerData, setOwnerData] = useState();
    const [userCount, setUserCount] = useState({
        "user": 0,
        "owner": 0,
        "manager": 0,
        "appUserCount": 0,
        "adminCount": 0
    });
    const dispatch = useDispatch()
    const allUserType = (t) => {
        setType(t);
        allUser(t, 1)
    }
    
    const handleUserPage = (m) => {
        // setNoPage(m.selected + 1)
        allUser(type, m.selected + 1);
    }
    // api call
    useEffect(() => {
        allUser(type , 1);
    }, []);

    const allUser = (userType, pageNo) => {
        dispatch(loader(true));
        getAllUsers(userType, pageNo).then(function (response) {
            setUserData(response.data);
            setUserCount(response.data.userCount);
            dispatch(loader(false));
        })
            .catch(function (error) {
                console.log(error);
                dispatch(loader(false));
            });
    };

    return (
        <>
            <div className="mt-4 d-sm-flex justify-content-between">
                <div className="d-flex align-items-center justify-content-between notifi-box cursor-pointer"
                    onClick={() => history.push('/users-management/add-user')}
                >
                    <div>
                        <div className="icon-container icon-1"><i className="demo-icon icon-add font-20">&#xe802;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">Add</div>
                        <div className="sub-title">New User</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container icon-2"><i className="demo-icon icon-user font-20">&#xe821;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">{userCount.user}</div>
                        <div className="sub-title">Total User</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container icon-2"><TabletMacIcon className="demo-icon font-20"/></div>
                    </div>
                    <div className="text-center">
                        <div className="title">{userCount.appUserCount}</div>
                        <div className="sub-title">App User</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container icon-3"><i className="demo-icon icon-owner font-20">&#xe820;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">{userCount.owner}</div>
                        <div className="sub-title">Owner</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container icon-4"><i className="demo-icon icon-manager font-20">&#xe814;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">{userCount.adminCount}</div>
                        <div className="sub-title">Super admin</div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                {!userData ? "" : 
                <UserTab 
                data={userData} 
                allUserType = {allUserType}
                userPageFunc={handleUserPage} 

                />}
            </div>
            {/* <!-- The Modal One--> */}
            <div className="modal" id="myModal">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">

                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
                            <h4 className="modal-title">Assign Manager</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        {/* <!-- Modal body --> */}
                        <div className="modal-body d-flex justify-content-between">
                            <div className="form-group w-50 px-2">
                                <select className="form-control mb-3" id="sel1">
                                    <option>Add New Outlet</option>
                                    <option>Ipsum elit diam</option>
                                    <option>Praesent fringilla</option>
                                    <option>Vivamus proin</option>
                                </select>
                                <button type="button" className="btn bg-btn-color px-4 py-2 text-white float-right">Assign</button>
                            </div>
                            <div className="form-group w-50 px-2">
                                <select className="form-control mb-3" id="sel1">
                                    <option>Add New Manager</option>
                                    <option>Ipsum sodales ultricies</option>
                                    <option>Augue bibendum</option>
                                    <option>Quis rhoncus</option>
                                </select>
                                <button type="button" className="btn bg-btn-lightgray px-4 py-2" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- The Modal Two--> */}
            <div className="modal" id="myModal-2">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">

                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
                            <h4 className="modal-title">Assign Owner & Outlet</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        {/* <!-- Modal body --> */}
                        <div className="modal-body d-flex justify-content-between">
                            <div className="form-group w-50 px-2">
                                <select className="form-control mb-3" id="sel1">
                                    <option>Add New Owner</option>
                                    <option>Purus dapibus est</option>
                                    <option>PDictum tristique eros</option>
                                    <option>Vulputate nunc est</option>
                                </select>
                                <button type="button" className="btn bg-btn-color px-4 py-2 text-white float-right">Assign</button>
                            </div>
                            <div className="form-group w-50 px-2">
                                <select className="form-control mb-3" id="sel1">
                                    <option>Add New Outlet</option>
                                    <option>Ipsum elit diam</option>
                                    <option>Praesent fringilla</option>
                                    <option>Vivamus proin</option>
                                </select>
                                <button type="button" className="btn bg-btn-lightgray px-4 py-2" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserManagement;
import React, { Component } from 'react';
import Moment from 'react-moment';
class SlotExpiryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  dateConvert(date, splitSym = '/') {
    var datearray = date.split(splitSym);
    let splitArr = datearray[0].split('-');
    let newdate = splitArr[0] + '-' + splitArr[1] + '-' + splitArr[2];
    return newdate;
  }
  checkStatus(slotDate) {
    let current_datetime = new Date()
    let formatted_date = current_datetime.getFullYear() + "-" + (("0" + (current_datetime.getMonth() + 1)).slice(-2)) + "-" + current_datetime.getDate();
    let cnvDate = this.dateConvert(slotDate, 'T');
    let arr_status = ["Active", "Expired", "Coming Soon"];
    let statusColor = ["#09a02c", "#F22338", "#e8a00c"];
    let finalArr = [];
    if (cnvDate === formatted_date) {
      finalArr['status'] = arr_status[0];
      finalArr['color'] = statusColor[0];
      return finalArr;
    } else if (cnvDate > formatted_date) {
      finalArr['status'] = arr_status[2];
      finalArr['color'] = statusColor[2];
      return finalArr;
    } else {
      finalArr['status'] = arr_status[1];
      finalArr['color'] = statusColor[1];
      return finalArr;
    }
  }
  render() {
    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Slot date</th>
              <th>Slot</th>
              <th className="text-center" style={{ width: "10%" }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {this.props.Expdata.map(item => {
              return <tr key={item._id}>
                <td><Moment format="DD-MM-yy">{item.slotDate}</Moment></td>

                <td>
                  <table style={{ minWidth: "100%", border: "1px solid", textAlign:"center" }}>
                    <thead>
                      <tr style={{color:"GrayText"}}>
                        <th style={{ width: "40%" }}>Name</th>
                        <th style={{ width: "20%" }}>Start Time</th>
                        <th style={{ width: "20%" }}>End Time</th>
                        <th style={{ width: "20%" }}>Base Price</th>
                        <th style={{ width: "20%" }}>Public/Private</th>
                      </tr>
                    </thead>
                    <tbody style={{ color: "#000" }}>
                      {item.slots.map((m) => {
                        return (
                          <tr key={m._id}>
                            <td><b>{m.name}</b></td>
                            <td><b><Moment format="HH:mm">{m.slotStartTime}</Moment></b></td>
                            <td><b><Moment format="HH:mm">{m.slotEndTime}</Moment></b></td>
                            <td><b>{m.basePrice}</b></td>
                            <td style={{ color: "blue" }}><b>{m.isPrivate ? "Private" : ""}</b></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* <ul className="list-group list-group-flush">
                    {
                      item.slots.map(m => {
                        return <li className="list-group-item slot-list" key={m._id}>
                          <span className="w-75 mr-1">
                            Name:<kbd>{m.name}</kbd> Start Time:<kbd><Moment format="HH:mm" >{m.slotStartTime}</Moment></kbd>  End Time:<kbd><Moment format="HH:mm">{m.slotEndTime}</Moment></kbd>Base Price:  <kbd>{m.basePrice}</kbd>
                          </span>
                          <span className="badge badge-primary mr-1">{m.isPrivate ? "Private" : ""}</span>
                        </li>
                      })
                    }
                  </ul> */}
                </td>
                <td className="text-center">
                  <span className="badge badge-success" style={{ backgroundColor: this.checkStatus(item.slotDate)['color'] }}>{this.checkStatus(item.slotDate)['status']}</span>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SlotExpiryTable;

import React, { useEffect, useState } from 'react';
import NotificationFromTwo from '../component/c-notification/notificationFromTwo';
import ActiveTable from '../component/c-notification/active-table';
import SendIcon from '@material-ui/icons/Send';
import { getAllNotifications, postTopicNotify, uploadImage } from '../api/apiService';
import { ToastSuccess } from "../component/toast-notification";
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}
function Notification() {
    const [isSubmited, SetIsSubmited] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [topic, setTopic] = useState("");
    const [title, setTitle] = useState("");
    const [msg, setMsg] = useState("");
    const [image, setImage] = useState(null);
    const [msgImg, setMsgImg] = useState(null);
    const [dropMenu, setDropMenu] = useState(false);
    const [selectValue, setSelectValue] = useState([]);
    const options = [
        { user: "appUser" },
        { user: "admin" },
        { user: "manager" },
        { user: "merchant" }
    ];

    const handleChangeTitle = (e) => {
        setTitle(e.target.value)
        console.log(topic);
    }
    const handleChangeMsg = (e) => {
        setMsg(e.target.value)
    }
    const onMsgImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImage(URL.createObjectURL(img));
            setMsgImg(img)
        }
    }
    // Validation----------
    const valid = () => {
        let checked = true
        if (topic === "") {
            checked = false;
        }
        if (title === "") {
            checked = false;
        }
        if (msg === "") {
            checked = false;
        }
        return checked;
    }
    // Api call----------
    useEffect(() => {
        allNotifications();
    }, []);
    const allNotifications = async () => {
        store.dispatch(loader(true));
        try {
            const allNotify = await getAllNotifications();
            setNotifications(allNotify.data.data);
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex.data.message);
            store.dispatch(loader(false));
        }
    }
    const sendImgMsg = async () => {
        store.dispatch(loader(true));
        if (msgImg) {
            try {
                const loadImg = await uploadImage(msgImg, "notifications");
                const data = {
                    "title": title,
                    "message": msg,
                    "imageUrl": loadImg.data.data.location,
                    "type": "general"
                }
                sendMessage(data);
            } catch (ex) {
                store.dispatch(loader(false));
                console.log(ex.data.message);
            }
        } else {
            const data = {
                "title": title,
                "message": msg,
                "type": "general"
            }
            sendMessage(data);
        }
    }
    const sendMessage = async (data) => {
        store.dispatch(loader(true));

        try {
            const messageSend = await postTopicNotify(topic, data);
            ToastSuccess(messageSend.data.message);
            SetIsSubmited(false);
            setTopic("");
            setTitle("");
            setMsg("");
            setImage(null);
            allNotifications();
            store.dispatch(loader(false));
        } catch (er) {
            store.dispatch(loader(false));
            console.log(er);
        }
    }
    // Sand message --------
    const sendMsg = () => {
        SetIsSubmited(true);
        if (valid()) {
            sendImgMsg();
        }
    }
    return (
        <>
            <div className="my-4 d-flex justify-content-between">
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container icon-5"><i className="demo-icon icon-add font-20">&#xe802;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">Add</div>
                        <div className="sub-title">new</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container icon-7 "><i className="demo-icon icon-add font-20">&#xe801;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">0</div>
                        <div className="sub-title">Current</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container outlet-icon-1"><i className="demo-icon icon-add font-20">&#xe806;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">0</div>
                        <div className="sub-title">Upcoming</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between notifi-box">
                    <div>
                        <div className="icon-container icon-3"><i className="demo-icon icon-add font-20">&#xe812;</i></div>
                    </div>
                    <div className="text-center">
                        <div className="title">0</div>
                        <div className="sub-title">Expired</div>
                    </div>
                </div>
            </div>

            <div className="row my-4">
                <div className="col-sm-7">
                    <div className="category-container">
                        <ActiveTable msgData={notifications} />
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="category-container overflow-auto mb-3">
                        <div className="msg-box-logo">
                            <h5 className="text-secondary">Group Notification</h5>
                            <img src="../assate/images/notification-bell.png" alt="" />
                        </div>
                        <div className="pt-0">
                            <div>
                                <div className="row mt-3">
                                    <div className="col-sm-9">
                                        <div>
                                            <div className="select-box">
                                                <div
                                                    className="options-container"
                                                    style={dropMenu ? { marginTop: "42px" } : { display: "none" }}>
                                                    {options.map((item, i) => {
                                                        return (
                                                            <div
                                                                className="option"
                                                                key={i}
                                                                onClick={() => {
                                                                    setTopic(item.user);
                                                                    setSelectValue([item])
                                                                }}
                                                            >
                                                                <label className="ml-1 text-capitalize">{item.user}</label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="selected"
                                                    onClick={() => setDropMenu(!dropMenu)}
                                                    style={topic.length === 0 && isSubmited ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                                                >
                                                    {(dropMenu && selectValue.length) >= 1 || (!dropMenu && selectValue.length) >= 1 ? "" : "User Type"}
                                                    {selectValue.map((m, i) => {
                                                        return <span className="badge bg-warning text-dark mx-1 text-capitalize" key={i}>
                                                            {m.user}
                                                        </span>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <p className="error-msg"></p>
                                        <div className="form-group m-0">
                                            <input
                                                onChange={ev => handleChangeTitle(ev)}
                                                type="text"
                                                value={title}
                                                className="form-control"
                                                style={title === "" && isSubmited ? { border: "1px solid red" } : {}}
                                                placeholder="Title" />
                                        </div>
                                        <p className="error-msg"></p>
                                    </div>
                                    <div className="col-sm-3 pl-0">
                                        <div>
                                            <label className="msg-ing-container cursor-pointer" htmlFor="file2">
                                                <img
                                                    src={image} alt=""
                                                    value={image}
                                                    // style={imageTwoError !== "" ? { border: "1px solid red" } : {}}
                                                    className="upload-image" />
                                                <i style={image !== null ? { color: "transparent" } : {}}
                                                    className="demo-icon icon-camera">&#xe807;</i>
                                                <input type="file" id="file2" accept="image/*" onChange={(e) => onMsgImage(e)} />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="msg-container">
                                    <textarea
                                        style={msg === "" && isSubmited ? { border: "1px solid red" } : {}}
                                        value={msg}
                                        onChange={(e) => handleChangeMsg(e)}
                                        rows="4" placeholder="Message ....." />
                                    {/* <p className="error-msg"></p> */}
                                </div>
                                <div className="text-right">
                                    <button
                                        onClick={sendMsg}
                                        type="button"
                                        className="btn btn-verify mt-2" data-toggle="modal">
                                        <SendIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NotificationFromTwo />

                </div>
            </div>
        </>
    );
}

export default connect(mapStateToProps)(Notification);
import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { auctionFoodStatus } from '../../api/apiService';
import { ToastSuccess, ToastError } from "../../component/toast-notification";
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}
class UpcomingFoodsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upComingData: this.props.data
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            this.setState({ upComingData: this.props.data });
        }
    }
    handleChangeFoodStatus = async (bookId) => {
        store.dispatch(loader(false));
        try {
            const food = await auctionFoodStatus(bookId)
            store.dispatch(loader(false));
            ToastSuccess(food.data.message)
            this.props.rerenderParentCallback();
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
            ToastError(ex.message);
        }
    }
    render() {
        return (
            <div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th style={{ width: "10%" }}>Auction Date</th>
                            <th>Auction Type</th>
                            <th>Slot name</th>
                            <th><span>Food</span></th>
                            <th>Outlet Name</th>
                            <th className="text-center">No Of Winers</th>
                            <th className="text-center">Base Price</th>
                            <th className="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.upComingData.map(item => {
                            return <tr key={item._id} className="text-secondary">
                                <td>
                                    <Moment format="DD-MM-YYYY HH:mm">
                                        {item.startTime}
                                    </Moment>
                                    {/* {item.dateId.slots.map(m => {
                                        if (m._id === item.slotId) {
                                            return <Moment key={m._id} format="DD-MM-YYYY HH:mm">
                                                {m.slotStartTime}
                                            </Moment>
                                        }
                                    })} */}
                                </td>
                                <td>
                                    <p className='m-0'>{item.isPrivate ? "Private" : "Public"}</p>
                                    {item.isPrivate ? item.code : ""}
                                </td>
                                <td>
                                    {item.dateId.slots.map(m => {
                                        if (m._id === item.slotId) {
                                            return m.name
                                        }
                                    })}
                                </td>
                                <td>
                                    <label className="d-flex m-0">
                                        <img src={item.productId.image ? item.productId.image : "/assate/images/food-logo.png"} className="img-fluid table-img mr-3" alt="" />
                                        <div>
                                            <Link to={`/outlets/add-on-product-table/${item.productId._id}`}>{item.productId.product_name}</Link>
                                            <p className="font-10 m-0">₹{item.productId.sell_price_without_tax}</p>
                                        </div>
                                    </label>
                                </td>
                                <td>
                                    <label className="d-flex m-0">
                                        <img src={item.outletId.outlet_images ? item.outletId.outlet_images : "/assate/images/store-logo.png"} className="img-fluid table-img mr-3" alt="" />
                                        <div>
                                            <Link to={`/outlets/products/${item.outletId._id}`}>{item.outletId.outlet_name}</Link>
                                            <p className="font-10 m-0">{item.outletId.address_details.address}</p>
                                        </div>
                                    </label>
                                </td>
                                <td className="text-center">{item.noOfWiners}</td>
                                <td className="text-center">{item.basePrice}</td>
                                <td className="text-center">
                                    <Switch
                                        checked={item.isActive}
                                        onChange={() => this.handleChangeFoodStatus(item._id)}
                                        name="checkedUserActive"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {this.state.upComingData.length < 1 ? <h3 className="text-center w-100">No data</h3> : ""}
            </div>
        );
    }
}

export default connect(mapStateToProps)(UpcomingFoodsTable);
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import BackspaceIcon from '@material-ui/icons/Backspace';
import { ToastSuccess, ToastError } from '../component/toast-notification';
import {
    getAuctionDetails,
    getAuctionBidDetails,
    getCoupons,
    initiateCoupon
} from '../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import { Link } from 'react-router-dom';

const mapStateToProps = (store) => {
    return {
        socketId: store.loaderReducer.socketId,
        loaderDisplay: store.loaderDisplay
    }
}
class AuctionBitDetails extends Component {
    type = new URLSearchParams(this.props.location.search).get('type');
    constructor(props) {
        super(props);
        this.state = {
            slotId: this.props.match.params.slotBookId,
            auctionData: null,
            auctionBidData: [],
            modalShow: false, // modal
            winOfNo: [],
            isModal: false,
            couponId: [],
            allCouponList: [],
            searchCouponList: [],
            searchText: "",
            selectValue: [],
            dropMenu: false
        }
        if (this.props.socketId !== null) {
            this.initSocket();
        } else {
            setTimeout(() => {
                if (this.props.socketId !== null) {
                    this.initSocket();
                }
            }, 5000)
        }
        this.aucDetails(this.state.slotId);
        this.aucBidDetails(1, true);
        this.allCoupon();
    }
    /*
    * Auction
    * Socket
    */
    initSocket = () => {
        this.props.socketId.on(this.state.slotId, (data) => {
            this.aucBidDetails(1, false);
        });
    }
    /*
    * Modal
    * Show And Hide
    */
    handleModalShowHide = () => {
        this.setState({ modalShow: !this.state.modalShow });
        if (!this.state.modalShow) {
            this.setState({
                winOfNo: [],
                isModal: false,
                couponId: [],
                selectValue: [],
                dropMenu: false
            })
        }
    }
    // multi dropdown ----------
    dropdownMenuOpen = () => {
        this.setState({ dropMenu: !this.state.dropMenu, dropFoodMenu: false })
    }
    handleClickOption = (e) => {
        let a = [];
        let selectedCouponId = [];
        var index = a.findIndex(x => x._id === e._id)
        if (index === -1) {
            a.push(e);
        } else {
            var num = a.indexOf(e);
            if (num > -1) {
                a.splice(num, 1);
            }
        }
        a.forEach(m => { selectedCouponId.push(m._id) })
        this.setState({ selectValue: a, couponId: selectedCouponId }, () => {
        })
    }
    deletDropSelectItem = (e) => {
        this.setState({
            selectValue: this.state.selectValue.filter(item => item._id !== e._id),
            couponId: []
        });
    }
    handleSearch = (e) => {
        let filter = e.target.value.toUpperCase();
        let list = this.state.allCouponList;
        let arr = [];
        for (var i = 0; i < list.length; i++) {
            let a = list[i].code.toUpperCase();
            if (a.indexOf(filter) > -1) {
                arr.push(list[i]);
            }
        }
        this.setState({ searchCouponList: arr, searchText: filter });
    }
    // multi select End--------------------

    /*
    * Input Event
    * Winner Of Number
    */
    handleWinOfNo = (e) => {
        let number = parseInt(e.target.value)
        // let noOfUsers = [];
        let setUsers = [];
        // this.state.auctionBidData.forEach((m, i) => {
        //     let usersCount = i + 1
        //     if (usersCount > 0) {
        //         noOfUsers.push(m.user._id)
        //     }
        // })
        // noOfUsers.forEach((users, i) => {
        //     if (i < number) {
        //         setUsers.push(users)
        //     }
        // })
        this.state.auctionBidData.forEach((m, i) => {
            if (i > 0 && i < number) {
                setUsers.push(m.user._id)
            }
        })
        this.setState({
            winOfNo: setUsers,
        }, () => {
            return this.state.isModal ? this.validModal() : false;
        })
    }

    /*
    * Validation
    * For Modal
    */
    validModal = () => {
        let checked = true;
        if (this.state.winOfNo.length < 1) {
            checked = false
        }
        return checked
    }

    /*
    * Api
    * Auction Details
    */
    aucDetails = async (id) => {
        store.dispatch(loader(true));
        try {
            const aucD = await getAuctionDetails(id);
            this.setState({
                auctionData: aucD.data.data
            });
            store.dispatch(loader(false));

        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }

    /*
    * Api
    * Auction BId Details
    */
    aucBidDetails = async (pageNo, loaderStatus) => {
        store.dispatch(loader(loaderStatus));
        try {
            const aucBD = await getAuctionBidDetails(`${this.state.slotId}?pageNo=${pageNo}`);
            this.setState({
                auctionBidData: aucBD.data.data
            })
            store.dispatch(loader(false));

        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }

    /*
    * Api
    * All Coupons
    */
    allCoupon = async () => {
        store.dispatch(loader(true));
        try {
            const coupon = await getCoupons();
            let x = coupon.data.data
            let couponFood = [];
            x.forEach(m => {
                if (m.foodType === "food") {
                    couponFood.push(m)
                }
            })
            this.setState({ allCouponList: couponFood }, () => {
            })
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }

    /*
    * Api
    * Coupons Set By 
    * Auction Users
    */
    couponInit = async () => {
        store.dispatch(loader(true));
        const data = {
            "outlet": this.state.auctionData.outletId,
            "product": this.state.auctionData.productId,
            "users": this.state.winOfNo
        }
        try {
            const init = await initiateCoupon(this.state.couponId[0], data);
            store.dispatch(loader(false));
            ToastSuccess(init.data.message)
            this.handleModalShowHide();
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
            ToastError(ex.response.message)
        }
    }

    //  -------submit for modal -------------
    submittedSetCoupon = () => {
        this.setState({ isModal: true });
        if (this.validModal()) {
            this.couponInit();
        }
    }
    render() {
        return (
            <>
                {
                    this.state.auctionData !== null ?
                        <div className="category-container mt-4">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h5>Product</h5>
                                    <div className="food-details-container d-flex justify-content-between align-content-end">
                                        <div className="d-flex">
                                            <div className="food-img-sec">
                                                <img src={this.state.auctionData.product.image ? this.state.auctionData.product.image : "/assate/images/food-logo.png"} className="addon-product-table-img" alt="" />
                                            </div>
                                            <div>
                                                <h5><Link to={`/outlets/add-on-product-table/${this.state.auctionData.product._id}`}>{this.state.auctionData.product.product_name}</Link></h5>
                                                <div className="discription-box">
                                                    <p className="m-0 sub-title"><b>Price without tex :</b> {this.state.auctionData.product.sell_price_without_tax}</p>
                                                    <p className="text-secondary m-0 sub-title">{this.state.auctionData.product.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <h5>Resturent</h5>
                                    <div className="food-details-container d-flex justify-content-between align-content-end">
                                        <div className="d-flex">
                                            <div className="food-img-sec">
                                                <img src={this.state.auctionData.outlet !== null && this.state.auctionData.outlet.outlet_images ? this.state.auctionData.outlet.outlet_images : "/assate/images/store-logo.png"} className="addon-product-table-img" alt="" />
                                            </div>
                                            <div>
                                                <h5><Link to={`/outlets/products/${this.state.auctionData.outlet._id}`}>{this.state.auctionData.outlet.outlet_name}</Link></h5>
                                                <div className="discription-box">
                                                    <p className=" m-0 sub-title"><b>Open :</b> {this.state.auctionData.outlet.opening_time} <b>Close : </b>{this.state.auctionData.outlet.closing_time}</p>
                                                    <p className="text-secondary m-0 sub-title">{this.state.auctionData.outlet.address_details.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div></div>
                }
                {this.state.auctionData !== null ?
                    <div className="category-container mt-4 w-50" >
                        <div className="d-flex justify-content-between mb-1">
                            {this.type === "expaire" ? <h4 className="text-danger">Expaire</h4> : <h4 className="text-success">Active</h4>}
                            {this.type === "expaire" ? <button className="btn btn-verify" onClick={this.handleModalShowHide}>Set coupon</button> : ""}
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th><span className="ml-2">Winers</span></th>
                                    <th>Bid Price</th>
                                    <th className="text-center">Rank</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.auctionBidData.map((item, index) => {
                                    return <tr key={item._id} id={index}>
                                        <td>
                                            <img src={item.user.image ? item.user.image : "/assate/images/profile.png"} className="img-fluid user-profile mr-3" alt="hungry top" />
                                            <Link to={`/users-management/user-details/${item.user._id}`}>{item.user.full_name}</Link>
                                        </td>
                                        <td>{item.bidPrice}</td>
                                        <td className="text-center">
                                            {index + 1 <= this.state.auctionData.noOfWiners ? <span className="badge badge-success">{index + 1}</span> : <span>{index + 1}</span>}
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                    :
                    <div></div>
                }
                {/* modal start */}
                <Modal
                    show={this.state.modalShow}
                    onHide={this.handleModalShowHide}
                    backdrop="static"
                    size="sm"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Coupon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="category-field my-2">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Coupon</label>
                                    <div className="category-field mb-2">



                                        <div className="select-box">
                                            <div
                                                className="options-container"
                                                style={this.state.dropMenu ? {} : { display: "none" }}>
                                                {this.state.searchCouponList.length === 0 && this.state.searchText === "" ?
                                                    this.state.allCouponList.map((item, i) => {
                                                        return (
                                                            <div
                                                                className="option"
                                                                key={item._id}
                                                                onClick={() => this.handleClickOption(item)}
                                                            >
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="para-shot font-15"><b>{item.code}</b></div>
                                                                    <span>
                                                                        <kbd>{item.percentage ? `${item.amount}%` : `₹${item.amount}`}</kbd>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                    :
                                                    this.state.searchCouponList.map((item, i) => {
                                                        return (
                                                            <div
                                                                className="option"
                                                                key={item._id}
                                                                onClick={() => this.handleClickOption(item)}
                                                            >
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="para-shot font-15"><b>{item.code}</b></div>
                                                                    <span>
                                                                        <kbd>{item.percentage ? `${item.amount}%` : `₹${item.amount}`}</kbd>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className="selected"
                                                onClick={this.dropdownMenuOpen}
                                                style={this.state.couponId.length === 0 && this.state.isModal ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                                            >
                                                {(this.state.dropMenu && this.state.selectValue.length) >= 1 || (!this.state.dropMenu && this.state.selectValue.length) >= 1 ? "" : "Select Coupon"}
                                                {this.state.selectValue.map((m, i) => {
                                                    return <span className="badge bg-warning text-dark mx-1" key={i}>
                                                        {m.code}
                                                        <BackspaceIcon
                                                            onClick={() => this.deletDropSelectItem(m)}
                                                            style={{ fontSize: "14px", color: "red" }}
                                                        />
                                                    </span>
                                                })}
                                            </div>
                                            <div
                                                className="search-box"
                                                style={this.state.dropMenu ? {} : { display: "none" }}
                                            >
                                                <input
                                                    onKeyUp={(e) => this.handleSearch(e)}
                                                    type="search"
                                                    placeholder="Search ..."
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">No Of users</label>
                                    <input
                                        onChange={(e) => this.handleWinOfNo(e)}
                                        style={this.state.winOfNo.length < 1 && this.state.isModal ? { border: "1px solid red" } : {}}
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter the number" />
                                </div>
                            </div>
                            <div className="text-right my-3">
                                <button
                                    onClick={() => this.submittedSetCoupon()}
                                    type="button"
                                    className="btn btn-verify"
                                    data-toggle="modal">Add
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default connect(mapStateToProps)(AuctionBitDetails);
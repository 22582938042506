import React from 'react';
import { Link } from 'react-router-dom';

export default function ActiveTable(props) {
  return (
    <>
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Image</th>
              <th>Topic</th>
              <th>Title</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {props.msgData.map(item => {
              return <tr key={item._id}>
                <td>
                  <img src={item.imageUrl}
                    onError={(e) => { e.target.onerror = null; e.target.src = "/assate/images/notification-logo.jpg" }}
                    className="img-fluid table-img" alt="" />
                </td>
                <td>{item.topic ? item.topic : item.sentTo.full_name}</td>
                <td>{item.title}</td>
                <td>{item.message}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

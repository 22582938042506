import React, { Component } from 'react';

class FormSetings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            delivaryCharage: "",
        }
    }
    /*
    * Event Handler
    */
    handleDelivaryCharage = (e) => {
        this.setState({ delivaryCharage: e.target.value }, () => {
            return this.state.isSubmitted ? this.valid() : false
        });
    }
    /*
    * Form Validation
    */
    valid = () => {
        let checked = true;
        if (this.state.delivaryCharage === "") {
            checked = false;
        }
        return checked
    }
    /*
    * Form Submit button
    */
    submitted = () => {
        this.setState({ isSubmitted: true })
        if (this.valid()) {
            const data = {
                delivaryCharage: this.state.delivaryCharage,
            }
            if (window.confirm("Do you want to change delivary Charage")) {
                this.props.parentFunctionCall(data)
                this.setState({ isSubmitted: false, delivaryCharage: "" })
            }
        }
    }
    render() {
        return (
            <div>
                <div className="category-container">
                    <h5 className="text-secondary">Others Option</h5>
                    <div>
                        <div className="form-group mb-2">
                            <label className="font-12 mb-0 mr-3">Change Delivary Price : </label>
                            <span className='badge badge-pill badge-success font-10'>{this.props.delivaryCharage}</span>
                            <input
                                onChange={(e) => this.handleDelivaryCharage(e)}
                                style={this.state.delivaryCharage === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                type="number"
                                value={this.state.delivaryCharage}
                                className="form-control"
                                placeholder="Delivary charage"
                            />
                            <div className="text-right mt-2">
                                <button className="btn btn-verify"
                                    onClick={() => this.submitted()}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormSetings;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import UsersTable from './users-table';
import RastaurantTable from './restaurant-owner-table';
import ManagerTable from './manager-table';
import AdminTable from './admin-table';
import { allSearch } from '../../api/apiService';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function UserTab(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [uSearch, setUSearch] = React.useState("");
    const [uData, setUdata] = React.useState(props.data);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setUSearch("");
        setUdata(props.data);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const handleUserSearch = (event) => {
        setUSearch(event.target.value);
        if (event.target.value.length < 1) {
            setUdata(props.data);
        } else {

            userSearch(event.target.value);
        }

    };
    // api call------------
    useEffect(() => {
        setUdata(props.data);
    }, [props.data]);
    const userSearch = async (txt) => {
        let searchType = "";
        if(value === 0){
            searchType = "user"
        }
        if(value === 1){
            searchType = "owner"
        }
        if(value === 2){
            searchType = "manager"
        }
        if(value === 3){
            searchType = "admin"
        }
        try {
            const search = await allSearch(searchType, encodeURIComponent(txt));
            const info = {
                data: search.data.data !== undefined ? search.data.data : [],
                message: uData.message,
                pageInfo: {
                    currentPage: 1,
                    itemLength: 15,
                    itemPerPage: 15,
                    maxPageNo: 1,
                    nextPageNo: 1
                },
                status: uData.status,
                userCount: uData.userCount,
            };
            setUdata(info);
        } catch (ex) {
            console.log(ex);
        }
    }

    return (
        <div className="category-container mb-4">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Users" {...a11yProps(0)} />
                        <Tab label="Restaurant Owner" {...a11yProps(1)} />
                        <Tab label="Manager" {...a11yProps(2)} />
                        <Tab label="Admin" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <div className="table-search">
                    <div className="search-container">
                        <input onChange={(e) => handleUserSearch(e)} value={uSearch} type="search" placeholder="Search.." name="search" autoFocus />

                    </div>
                </div>
            </div>
            <div>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <UsersTable
                            data={uData}
                            allUserType={props.allUserType}
                            userPageFunc={props.userPageFunc} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <RastaurantTable
                            data={uData}
                            allUserType={props.allUserType}
                            userPageFunc={props.userPageFunc} />
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <ManagerTable
                            data={uData}
                            allUserType={props.allUserType}
                            userPageFunc={props.userPageFunc} />
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <AdminTable
                            data={uData}
                            allUserType={props.allUserType}
                            userPageFunc={props.userPageFunc} />
                    </TabPanel>
                </SwipeableViews>
            </div>
        </div>
    );
}

import React from "react";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { connect } from "react-redux";
import { loader } from "../Actions/index";
import store from "../store";
import "rc-time-picker/assets/index.css";
import { ToastSuccess, ToastError } from "../component/toast-notification";
import {
  addNewOutlet,
  getOwner,
  getOutlet,
  outletUpdate,
  addOutletImg,
  uploadImage,
  allSearch,
} from "../api/apiService";

const mapStateToProps = (store) => {
  return {
    loaderDisplay: store.loaderDisplay,
  };
};
class OutletAddNew extends React.Component {
  isUpdate = false;
  type = new URLSearchParams(this.props.location.search).get("type");
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      ownerSelect: this.props.match.params.ownerId
        ? this.props.match.params.ownerId
        : "",
      imageOne: null,
      outletImg: null,
      imageTwo: null,
      addvertiseImg: null,
      outletName: "",
      brandName: "",
      fssaiNo: "",
      gstNo: "",
      landmark: "",
      phoneNo: "",
      locality: "",
      description: "",
      outletAddress: "",
      addMapLat: "",
      addMapLong: "",
      openTime: moment(),
      closeTime: moment(),
      breakStart: moment(),
      breakEnd: moment(),
      pinNo: "",
      startTime: moment(),
      endTime: moment(),
      preparationTime: "",
      cost: "",
      headerText: "",
      footerText: "",
      rowColum: "col-sm-3 col-12",
      allOwnerData: [],
      updateOwnwrId: null,
      ownerApiPageNo: 1,
      selectValue: [],
      dropMenu: false,
      searchOwner: [],
      selectedHookes: [],
      webHooks: [],
      webHookUrl: "",
      webHookMethod: "post",
      searchText: "",
      resturentType: "",
    };
    if (this.props.match.params.ownerId && this.type === "new") {
      this.isUpdate = false;
    } else if (this.props.match.params.ownerId) {
      this.isUpdate = true;
      this.editOutlet();
    } else {
      this.outletOwner(this.state.ownerApiPageNo);
    }
  }

  handleHooksSelect = function (selectedItems) {
    const flavors = [];
    for (let i = 0; i < selectedItems.length; i++) {
      flavors.push(selectedItems[i].value);
    }
    this.setState({ selectedHookes: flavors });
  };

  // multi dropdown ----------
  dropdownMenuOpen = () => {
    this.setState({ dropMenu: !this.state.dropMenu });
    if (!this.state.dropMenu) {
      document.getElementById("myText").focus();
    }
  };
  handleClickOption = (e) => {
    let a = [];
    var index = a.findIndex((x) => x._id === e._id);
    if (index === -1) {
      a.push(e);
    } else {
      var num = a.indexOf(e);
      if (num > -1) {
        a.splice(num, 1);
      }
    }
    this.setState({ selectValue: a, ownerSelect: a[0]._id });
  };
  deletDropSelectItem = (e) => {
    this.setState({
      selectValue: this.state.selectValue.filter((item) => item._id !== e._id),
      ownerSelect: "",
    });
  };
  // end multi dropdown---------
  handleChangeOutlet = (e) => {
    this.setState(
      {
        outletName: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeBrand = (e) => {
    this.setState(
      {
        brandName: e.target.value,
      },
      () => {
        // return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeFssai = (e) => {
    this.setState(
      {
        fssaiNo: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeGstNo = (e) => {
    this.setState(
      {
        gstNo: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeLandmark = (e) => {
    this.setState(
      {
        landmark: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangePhoneNo = (e) => {
    this.setState(
      {
        phoneNo: e.target.value,
      },
      () => {
        console.log(this.state.phoneNo);
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeLocality = (e) => {
    this.setState(
      {
        locality: e.target.value,
      },
      () => {
        console.log(this.state.locality);
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeDescription = (e) => {
    this.setState(
      {
        description: e.target.value,
      },
      () => {
        console.log(this.state.description);
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeAddress = (e) => {
    this.setState(
      {
        outletAddress: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeMapLat = (event) => {
    this.setState(
      {
        addMapLat: event.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeMapLong = (event) => {
    this.setState(
      {
        addMapLong: event.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeOpenTime = (value) => {
    this.setState(
      {
        openTime: value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeCloseTime = (value) => {
    this.setState(
      {
        closeTime: value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeBreakStart = (value) => {
    this.setState(
      {
        breakStart: value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeBreakEnd = (value) => {
    this.setState(
      {
        breakEnd: value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangePin = (e) => {
    this.setState(
      {
        pinNo: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeStartTime = (value) => {
    this.setState(
      {
        startTime: value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeEndTime = (value) => {
    this.setState(
      {
        endTime: value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangePreparationTime = (e) => {
    this.setState(
      {
        preparationTime: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeCost = (e) => {
    this.setState(
      {
        cost: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleWebhookUrl = (e) => {
    this.setState(
      {
        webHookUrl: e.target.value,
      },
      () => {
        //return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };

  handleWebhookMethod = (e) => {
    this.setState(
      {
        webHookMethod: e.target.value,
      },
      () => {
        //return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };

  handleChangeHeaderText = (e) => {
    this.setState(
      {
        headerText: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeFooterText = (e) => {
    this.setState(
      {
        footerText: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  handleChangeResturentType = (e) => {
    this.setState(
      {
        resturentType: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  onImageChangeOne = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState(
        {
          imageOne: URL.createObjectURL(img),
          outletImg: img,
        },
        () => {
          return this.state.isSubmitted ? this.valid() : false;
        }
      );
    }
  };
  onImageChangeTwo = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState(
        {
          imageTwo: URL.createObjectURL(img),
          addvertiseImg: img,
        },
        () => {
          return this.state.isSubmitted ? this.valid() : false;
        }
      );
    }
  };
  handleSearch = async (type, txt) => {
    this.setState({ searchText: txt.target.value });
    try {
      const search = await allSearch(
        type,
        encodeURIComponent(txt.target.value)
      );
      let dataValue = search.data.data;
      this.setState({ searchOwner: dataValue });
    } catch (ex) {
      console.log(ex);
    }
  };
  // valadations////
  valid() {
    let checked = true;
    if (this.state.ownerSelect === "") {
      checked = false;
    }
    if (this.state.imageOne === null) {
      checked = false;
    }
    if (this.state.imageTwo === null) {
      checked = false;
    }
    if (this.state.outletName === "") {
      checked = false;
    }
    if (this.state.fssaiNo === "") {
      checked = false;
    }
    // if (this.state.gstNo === "") {
    //   checked = false;
    // }
    if (this.state.landmark === "") {
      checked = false;
    }
    if (this.state.description === "") {
      checked = false;
    }
    if (this.state.phoneNo.length < 10) {
      checked = false;
    }
    if (this.state.locality === "") {
      checked = false;
    }
    if (this.state.outletAddress === "") {
      checked = false;
    }
    if (this.state.addMapLat === "") {
      checked = false;
    }
    if (this.state.addMapLong === "") {
      checked = false;
    }
    if (this.state.openTime.format("HH:mm").length < 4) {
      checked = false;
    }
    if (this.state.closeTime.format("HH:mm").length < 4) {
      checked = false;
    }
    if (this.state.breakStart.format("HH:mm").length < 4) {
      checked = false;
    }
    if (this.state.breakEnd.format("HH:mm").length < 4) {
      checked = false;
    }
    if (this.state.pinNo.length !== 6) {
      checked = false;
    }
    if (this.state.startTime.format("HH:mm").length < 4) {
      checked = false;
    }
    if (this.state.endTime.format("HH:mm").length < 4) {
      checked = false;
    }
    if (this.state.preparationTime === "") {
      checked = false;
    }
    if (this.state.cost === "") {
      checked = false;
    }
    if (this.state.headerText === "") {
      checked = false;
    }
    if (this.state.footerText === "") {
      checked = false;
    }
    if (this.state.resturentType === "") {
      checked = false;
    }
    return checked;
  }
  // api call---
  updateOutletImg = async (outletId) => {
    try {
      if (this.state.outletImg !== null) {
        const outImg = await uploadImage(this.state.outletImg, "outlets");
        if (this.state.addvertiseImg !== null) {
          const adImg = await uploadImage(this.state.addvertiseImg, "outlets");
          const data = {
            outlet_images: outImg.data.data.location,
            advertise_image: adImg.data.data.location,
          };
          const dataImage = await addOutletImg(outletId, data);
        } else {
          const data = {
            outlet_images: outImg.data.data.location,
          };
          const dataImage = await addOutletImg(outletId, data);
        }
      } else if (this.state.addvertiseImg !== null) {
        const adImg = await uploadImage(this.state.addvertiseImg, "outlets");
        const data = {
          advertise_image: adImg.data.data.location,
        };
        const dataImage = await addOutletImg(outletId, data);
      }
      store.dispatch(loader(false));
    } catch (ex) {
      ToastError(ex.response.data.message);
      store.dispatch(loader(false));
    }
  };

  outletOwner = (PageNo) => {
    const self = this;
    getOwner(PageNo)
      .then(function (response) {
        if (self.state.ownerApiPageNo > 1) {
          let resultAr = [...self.state.allOwnerData, ...response.data.data];
          self.setState({ allOwnerData: resultAr }, () => {});
        } else {
          self.setState({ allOwnerData: response.data.data }, () => {});
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  editOutlet = () => {
    const self = this;
    getOutlet(this.props.match.params.ownerId)
      .then(function (response) {
        self.setState({
          outletName: response.data.data.outlet_name,
          brandName: response.data.data.brand_name,
          fssaiNo: response.data.data.FSSAI_no,
          gstNo: response.data.data.GST_number
            ? response.data.data.GST_number
            : "",
          phoneNo: response.data.data.phone ? response.data.data.phone : "",
          locality: response.data.data.locality
            ? response.data.data.locality
            : "",
          description: response.data.data.description
            ? response.data.data.description
            : "",
          outletAddress: response.data.data.address_details.address,
          pinNo: response.data.data.address_details.pincode,
          landmark: response.data.data.address_details.landmark,
          addMapLat: response.data.data.address_details.lat,
          addMapLong: response.data.data.address_details.long,
          openTime: moment(response.data.data.opening_time, "HH:mm"),
          closeTime: moment(response.data.data.closing_time, "HH:mm"),
          breakStart: moment(response.data.data.break_start, "HH:mm"),
          breakEnd: moment(response.data.data.break_end, "HH:mm"),
          startTime: moment(response.data.data.delivery_start_time, "HH:mm"),
          endTime: moment(response.data.data.delivery_end_time, "HH:mm"),
          preparationTime: response.data.data.food_preparation_time,
          cost: response.data.data.cost_for_two,
          headerText: response.data.data.bill_header_text,
          footerText: response.data.data.bill_footer_text,
          resturentType: response.data.data.resturentType,
          imageOne: response.data.data.outlet_images,
          imageTwo: response.data.data.advertise_image,
          webHooks: response.data.data.webhooks,
          webHookUrl:
            response.data.data.webhooks != null &&
              response.data.data.webhooks.length > 0
              ? response.data.data.webhooks[0].url
              : "",
          selectedHookes:
            response.data.data.webhooks != null &&
              response.data.data.webhooks.length > 0
              ? response.data.data.webhooks[0].hooks
              : [],
        });
      })
      .catch(function (error) {
        console.log(error);
        ToastError(error.response.message);
      });
  };

  newOutlet = () => {
    store.dispatch(loader(true));
    let self = this;
    const data = {
      owner: this.state.ownerSelect,
      outlet_name: this.state.outletName,
      brand_name: this.state.brandName,
      phone: this.state.phoneNo,
      locality: this.state.locality,
      description: this.state.description,
      FSSAI_no: this.state.fssaiNo,
      address_details: {
        address: this.state.outletAddress,
        lat: this.state.addMapLat,
        long: this.state.addMapLong,
        pincode: this.state.pinNo,
        landmark: this.state.landmark,
      },
      GST_number: this.state.gstNo,
      opening_time: this.state.openTime.format("HH:mm"),
      closing_time: this.state.closeTime.format("HH:mm"),
      break_start: this.state.breakStart.format("HH:mm"),
      break_end: this.state.breakEnd.format("HH:mm"),
      delivery_start_time: this.state.startTime.format("HH:mm"),
      delivery_end_time: this.state.endTime.format("HH:mm"),
      food_preparation_time: this.state.preparationTime,
      cost_for_two: this.state.cost,
      bill_header_text: this.state.headerText,
      bill_footer_text: this.state.footerText,
      resturentType: this.state.resturentType,
      webhooks: [
        {
          url: this.state.webHookUrl,
          method: "post",
          hooks: this.state.selectedHookes,
        },
      ],
    };
    if (this.isUpdate) {
      outletUpdate(this.state.ownerSelect, data)
        .then(function (response) {
          self.updateOutletImg(response.data.data._id);
          store.dispatch(loader(false));
          ToastSuccess(response.data.message);
          self.props.history.goBack();
        })
        .catch(function (error) {
          console.log(error);
          ToastError(error.response.data.message);
          store.dispatch(loader(false));
        });
    } else {
      addNewOutlet(data)
        .then(function (response) {
          console.log(response.data.data._id);
          self.updateOutletImg(response.data.data._id);
          store.dispatch(loader(false));
          ToastSuccess(response.data.message);
          self.props.history.goBack();
        })
        .catch(function (error) {
          ToastError(error.response.data.message);
          console.log(error);
          store.dispatch(loader(false));
        });
    }
  };
  submited = () => {
    this.setState({ isSubmitted: true });

    if (this.valid()) {
      this.newOutlet();
    }
  };

  handleScrollOwn = (e) => {
    let scrollEffect =
      e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop < 1;
    if (scrollEffect) {
      this.setState({ ownerApiPageNo: this.state.ownerApiPageNo + 1 }, () => {
        this.outletOwner(this.state.ownerApiPageNo);
      });
    }
  };
  render() {
    return (
      <>
        <div className="my-3">
          <button
            onClick={() => this.submited()}
            type="button"
            className="btn btn-verify"
          >
            {this.type ? "Add" : "Update"}
          </button>
          <button
            type="button"
            onClick={() => this.props.history.goBack()}
            className="btn btn-cancel"
          >
            Cancel
          </button>
          {/* <Link to="/user-management"></Link> */}
        </div>
        <div className="container-fluid add-user-container py-3">
          <h5 className="mt-4 mb-3">Outlet Registration</h5>
          <div className="row">
            {this.props.match.params.ownerId ? (
              <></>
            ) : (
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="font-12 m-0">Owner select</label>

                  <div className="select-box">
                    <div
                      className="options-container"
                      style={this.state.dropMenu ? {} : { display: "none" }}
                    >
                      {this.state.searchText === ""
                        ? this.state.allOwnerData.map((item, i) => {
                          return (
                            <div
                              className="option"
                              key={item._id}
                              onClick={() => this.handleClickOption(item)}
                            >
                              <label>{item.full_name}</label>
                            </div>
                          );
                        })
                        : this.state.searchOwner &&
                        this.state.searchOwner.map((item, i) => {
                          return (
                            <div
                              className="option"
                              key={item._id}
                              onClick={() => this.handleClickOption(item)}
                            >
                              <label>{item.full_name}</label>
                            </div>
                          );
                        })}
                    </div>
                    <div className="selected" onClick={this.dropdownMenuOpen}>
                      {(this.state.dropMenu && this.state.selectValue.length) >=
                        1 ||
                        (!this.state.dropMenu && this.state.selectValue.length) >=
                        1
                        ? ""
                        : "Select Owner"}
                      {this.state.selectValue.map((m, i) => {
                        return (
                          <span
                            className="badge bg-warning text-dark mx-1"
                            key={i}
                          >
                            {m.full_name}
                            {/* <BackspaceIcon
                            onClick={() => this.deletDropSelectItem(m)}
                            style={{ fontSize: "14px", color: "red" }}
                          /> */}
                          </span>
                        );
                      })}
                    </div>
                    <div
                      className="search-box"
                      style={this.state.dropMenu ? {} : { display: "none" }}
                    >
                      <input
                        onChange={(e) => this.handleSearch("owner", e)}
                        id="myText"
                        type="search"
                        placeholder="Search ..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={!this.state.ownerSelect ? "col-sm-3" : "col-sm-3"}>
              <div className="form-group m-0">
                <label className="font-12 m-0">Outlet Name</label>
                <input
                  onChange={(e) => this.handleChangeOutlet(e)}
                  style={
                    this.state.outletName === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.outletName}
                  className="form-control"
                  placeholder="Outlet Name"
                  id="usr1"
                />
              </div>
              <p className="error-msg">
                {this.state.outletName === "" && this.state.isSubmitted
                  ? "Please enter outlet name"
                  : ""}
              </p>
            </div>
            <div className={!this.state.ownerSelect ? "col-sm-3" : "col-sm-3"}>
              <div className="form-group m-0">
                <label className="font-12 m-0">Brand Name</label>
                <input
                  onChange={(e) => this.handleChangeBrand(e)}
                  type="text"
                  value={this.state.brandName}
                  className="form-control"
                  placeholder="Brand Name"
                  id="usr1"
                />
              </div>
            </div>
            <div className={!this.state.ownerSelect ? "col-sm-3" : "col-sm-3"}>
              <div className="form-group m-0">
                <label className="font-12 m-0">Pin No</label>
                <input
                  onChange={(e) => this.handleChangePin(e)}
                  style={
                    this.state.pinNo.length !== 6 && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="number"
                  value={this.state.pinNo}
                  className="form-control"
                  placeholder="PIN no"
                  id="usr9"
                />
              </div>
              <p className="error-msg">
                {this.state.pinNo.length !== 6 && this.state.isSubmitted
                  ? "Enter pin no"
                  : ""}
              </p>
            </div>
            <div className={!this.state.ownerSelect ? "col-sm-3" : "col-sm-3"}>
              <div className="form-group m-0">
                <label className="font-12 m-0">Landmark</label>
                <input
                  onChange={(e) => this.handleChangeLandmark(e)}
                  style={
                    this.state.landmark === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.landmark}
                  className="form-control"
                  placeholder="Landmark"
                  id="usr4"
                />
              </div>
              <p className="error-msg">
                {this.state.landmark === "" && this.state.isSubmitted
                  ? "Please enter landmark"
                  : ""}
              </p>
            </div>

            <div className={!this.state.ownerSelect ? "col-sm-3" : "col-sm-3"}>
              <div className="form-group m-0">
                <label className="font-12 m-0">Resturent Type</label>
                <select
                  onChange={(e) => this.handleChangeResturentType(e)}
                  value={this.state.resturentType}
                  className="form-control"
                  style={
                    this.state.resturentType === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                >
                  <option value="">Select Type</option>
                  <option value="veg">Veg</option>
                  <option value="non-veg">Non-Veg</option>
                  <option value="vegan">Vegan</option>
                  <option value="both">Both</option>
                </select>
              </div>
              <p className="error-msg">
                {this.state.resturentType === "" && this.state.isSubmitted
                  ? "Please select Resturent Type"
                  : ""}
              </p>
            </div>

          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group m-0">
                <label className="font-12 m-0">Descriptions</label>
                <input
                  onChange={(ev) => this.handleChangeDescription(ev)}
                  style={
                    this.state.description === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.description}
                  className="form-control"
                  placeholder="Descriptions"
                />
              </div>
              <p className="error-msg">
                {this.state.description === "" && this.state.isSubmitted
                  ? "Enter description"
                  : ""}
              </p>
            </div>
            <div className="col-sm-3">
              <div className="form-group m-0">
                <label className="font-12 m-0">Phone No</label>
                <input
                  onChange={(ev) => this.handleChangePhoneNo(ev)}
                  style={
                    this.state.phoneNo.length !== 10 && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="number"
                  value={this.state.phoneNo}
                  className="form-control"
                  placeholder="Phone no"
                />
              </div>
              <p className="error-msg">
                {this.state.phoneNo.length !== 10 && this.state.isSubmitted
                  ? "Enter your ph no"
                  : ""}
              </p>
            </div>
            <div className="col-sm-3">
              <div className="form-group m-0">
                <label className="font-12 m-0">Locality</label>
                <input
                  onChange={(ev) => this.handleChangeLocality(ev)}
                  style={
                    this.state.locality === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.locality}
                  className="form-control"
                  placeholder="Locality"
                />
              </div>
              <p className="error-msg">
                {this.state.locality === "" && this.state.isSubmitted
                  ? "Enter your locality"
                  : ""}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Outlet Address</label>
                <input
                  onChange={(e) => this.handleChangeAddress(e)}
                  style={
                    this.state.outletAddress === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.outletAddress}
                  className="form-control"
                  placeholder="Outlet Address"
                  id="usr5"
                />
              </div>
              <p className="error-msg">
                {this.state.outletAddress === "" && this.state.isSubmitted
                  ? "Please enter address"
                  : ""}
              </p>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Latitude</label>
                <input
                  onChange={(ev) => this.handleChangeMapLat(ev)}
                  style={
                    this.state.addMapLat === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="number"
                  value={this.state.addMapLat}
                  className="form-control"
                  placeholder="Lat"
                />
              </div>
              <p className="error-msg">
                {this.state.addMapLat === "" && this.state.isSubmitted
                  ? "Please enter lat"
                  : ""}
              </p>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Longitude</label>
                <input
                  onChange={(ev) => this.handleChangeMapLong(ev)}
                  style={
                    this.state.addMapLong === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="number"
                  value={this.state.addMapLong}
                  className="form-control"
                  placeholder="Long"
                  id="usr6"
                />
              </div>
              <p className="error-msg">
                {this.state.addMapLong === "" && this.state.isSubmitted
                  ? "Please enter long"
                  : ""}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr3">
                  GST number
                </label>
                <input
                  onChange={(e) => this.handleChangeGstNo(e)}
                  // style={this.state.addMapLong === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                  type="text"
                  value={this.state.gstNo}
                  className="form-control"
                  placeholder="GST Number"
                  id="usr3"
                />
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr2">
                  FSSAI no
                </label>
                <input
                  onChange={(e) => this.handleChangeFssai(e)}
                  style={
                    this.state.fssaiNo === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.fssaiNo}
                  className="form-control"
                  placeholder="Outlet FSSAI No."
                  id="usr2"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr7">
                  Outlet Opening Time
                </label>
                <TimePicker
                  onChange={this.handleChangeOpenTime}
                  placeholder="HH:MM"
                  defaultValue={this.state.openTime}
                  value={this.state.openTime}
                  use12Hours={false}
                  showSecond={false}
                />
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr8">
                  Outlet Closing Time
                </label>
                <TimePicker
                  onChange={this.handleChangeCloseTime}
                  placeholder="HH:MM"
                  defaultValue={this.state.closeTime}
                  value={this.state.closeTime}
                  use12Hours={false}
                  showSecond={false}
                />
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr7">
                  Outlet Break Time Start
                </label>
                <TimePicker
                  onChange={this.handleChangeBreakStart}
                  placeholder="HH:MM"
                  defaultValue={this.state.breakStart}
                  value={this.state.breakStart}
                  use12Hours={false}
                  showSecond={false}
                />
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr8">
                  Outlet Break Time End
                </label>
                <TimePicker
                  onChange={this.handleChangeBreakEnd}
                  placeholder="HH:MM"
                  defaultValue={this.state.breakEnd}
                  value={this.state.breakEnd}
                  use12Hours={false}
                  showSecond={false}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr10">
                  Delivery Start Time
                </label>
                <TimePicker
                  onChange={this.handleChangeStartTime}
                  placeholder="HH:MM"
                  defaultValue={this.state.startTime}
                  value={this.state.startTime}
                  use12Hours={false}
                  showSecond={false}
                />
              </div>
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr12">
                  Food Preparation Time
                </label>
                <input
                  onChange={(e) => this.handleChangePreparationTime(e)}
                  style={
                    this.state.preparationTime === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.preparationTime}
                  placeholder="20 min"
                  className="form-control"
                  id="usr12"
                />
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr11">
                  Delivery End Time
                </label>
                <TimePicker
                  onChange={this.handleChangeEndTime}
                  placeholder="HH:MM"
                  defaultValue={this.state.endTime}
                  value={this.state.endTime}
                  use12Hours={false}
                  showSecond={false}
                />
              </div>
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr13">
                  Cost for Two People
                </label>
                <input
                  onChange={(e) => this.handleChangeCost(e)}
                  style={
                    this.state.cost === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="number"
                  value={this.state.cost}
                  className="form-control"
                  id="usr13"
                />
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr15">
                  Bill Header Text
                </label>
                <input
                  onChange={(e) => this.handleChangeHeaderText(e)}
                  style={
                    this.state.headerText === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.headerText}
                  className="form-control"
                  id="usr15"
                />
              </div>
              <div className="form-group m-0">
                <label className="font-12 m-0" htmlFor="usr16">
                  Bill Footer Text
                </label>
                <input
                  onChange={(e) => this.handleChangeFooterText(e)}
                  style={
                    this.state.footerText === "" && this.state.isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  value={this.state.footerText}
                  className="form-control"
                  id="usr16"
                />
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="new-outlet-image-container">
                <div>
                  <label>Outlet image</label>
                  <label
                    className="upload-image-container cursor-pointer"
                    htmlFor="file"
                  >
                    <img
                      src={this.state.imageOne}
                      alt=""
                      style={
                        this.state.imageOne === null && this.state.isSubmitted
                          ? { border: "1px solid red" }
                          : {}
                      }
                      className="upload-image"
                    />
                    <i
                      style={
                        this.state.imageOne !== null
                          ? { color: "transparent" }
                          : {}
                      }
                      className="demo-icon icon-camera"
                    >
                      &#xe807;
                    </i>
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      onChange={(e) => this.onImageChangeOne(e)}
                    />
                  </label>
                </div>
                <div>
                  <div>
                    <label>Ad image</label>
                    <label
                      className="upload-image-container cursor-pointer"
                      htmlFor="file2"
                    >
                      <img
                        src={this.state.imageTwo}
                        alt=""
                        style={
                          this.state.imageTwo === null && this.state.isSubmitted
                            ? { border: "1px solid red" }
                            : {}
                        }
                        className="upload-image"
                      />
                      <i
                        style={
                          this.state.imageTwo !== null
                            ? { color: "transparent" }
                            : {}
                        }
                        className="demo-icon icon-camera"
                      >
                        &#xe807;
                      </i>
                      <input
                        type="file"
                        id="file2"
                        accept="image/*"
                        onChange={(e) => this.onImageChangeTwo(e)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {this.state.webHooks != null && this.state.webHooks.length > 0 ? (
              this.state.webHooks.map((h) => {
                return (
                  <>
                    <div className="col-sm-12">
                      <h6 className="mt-4 mb-3">Webhook</h6>
                      <div>
                        <div className="form-group m-0">
                          <label className="font-12 m-0" htmlFor="usr13">
                            Enter Webhook URL
                          </label>
                          <input
                            onChange={(e) => this.handleWebhookUrl(e)}
                            type="text"
                            className="form-control"
                            id="usr13"
                            value={this.state.webHookUrl}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div>
                        <label className="font-12 m-0">Method</label>
                        <select
                          className="form-control"
                          onChange={(ev) => this.handleWebhookMethod(ev)}
                        >
                          <option value="0">POST</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div>
                        <label className="font-12 m-0">Hooks</label>
                        <select
                          multiple={true}
                          className="form-control"
                          onChange={(e) =>
                            this.handleHooksSelect(e.target.selectedOptions)
                          }
                          value={this.state.selectedHookes}
                        >
                          <option value="initiated">ORDER INIT</option>
                          <option value="submitted">ORDER SUBMITTED</option>
                          <option value="preparing">ORDER PREPARING</option>
                          <option value="food-ready">ORDER FOOD_READY</option>
                          <option value="canceled">ORDER CANCELED</option>
                          <option value="outlet-canceled">
                            ORDER OUTLET_CANCELED
                          </option>
                          <option value="out-for-delivery">
                            ORDER OUT_FOR_DELIVERY
                          </option>
                          <option value="completed">ORDER COMPLETED</option>
                        </select>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>
                <div className="col-sm-12">
                  <h6 className="mt-4 mb-3">Webhook</h6>
                  <div>
                    <div className="form-group m-0">
                      <label className="font-12 m-0" htmlFor="usr13">
                        Enter Webhook URL
                      </label>
                      <input
                        onChange={(e) => this.handleWebhookUrl(e)}
                        type="text"
                        className="form-control"
                        id="usr13"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div>
                    <label className="font-12 m-0">Method</label>
                    <select
                      className="form-control"
                      onChange={(ev) => this.handleWebhookMethod(ev)}
                    >
                      <option value="0">POST</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div>
                    <label className="font-12 m-0">Hooks</label>
                    <select
                      multiple={true}
                      className="form-control"
                      onChange={(e) =>
                        this.handleHooksSelect(e.target.selectedOptions)
                      }
                      value={this.state.selectedHookes}
                    >
                      <option value="initiated">ORDER INIT</option>
                      <option value="submitted">ORDER SUBMITTED</option>
                      <option value="preparing">ORDER PREPARING</option>
                      <option value="food-ready">ORDER FOOD_READY</option>
                      <option value="canceled">ORDER CANCELED</option>
                      <option value="outlet-canceled">
                        ORDER OUTLET_CANCELED
                      </option>
                      <option value="out-for-delivery">
                        ORDER OUT_FOR_DELIVERY
                      </option>
                      <option value="completed">ORDER COMPLETED</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(OutletAddNew);

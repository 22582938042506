import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserAuctionDetails } from '../../api/apiService';
import Moment from 'react-moment';

export default function AuctionDetails(props) {
  const [userADetails, setUserADetails] = useState([]);
  // Api call-------------
  useEffect(() => {
    userAuctionDetails(props.userId);
  }, []);
  const userAuctionDetails = async (userId) => {
    try {
      const userAucDetails = await getUserAuctionDetails(userId)
      setUserADetails(userAucDetails.data.data)
    } catch (ex) {
      console.log(ex);
    }
  }

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th style={{width: "30%"}}>Food</th>
            <th>Outlet</th>
            <th className="text-center">Slot Date</th>
            <th className="text-center">Base Price</th>
            <th className="text-center">Bid Price</th>
          </tr>
        </thead>
        <tbody>
          {userADetails.map(item => {
            return <tr key={item._id}>
              <td>
                <label className="d-flex m-0">
                  <img src={item.auction.product.image ? item.auction.product.image : "/assate/images/food-logo.png"} className="img-fluid table-img mr-3" alt="" />
                  <div>
                    <Link to={`/outlets/add-on-product-table/${item.auction.product._id}`}>{item.auction.product.product_name}</Link>
                    <p className="font-10 m-0">{item.auction.product.sell_price_without_tax}</p>
                  </div>
                </label>
              </td>
              <td>
                <label className="d-flex align-items-center m-0">
                  <img src={item.auction.outlet.outlet_images ? item.auction.outlet.outlet_images : "/assate/images/store-logo.png"} className="img-fluid table-img mr-3" alt="" />
                  <div>
                    <Link to={`/outlets/products/${item.auction.outlet._id}`}>{item.auction.outlet.outlet_name}</Link>
                  </div>
                </label>
              </td>
              <td className="text-center"><Moment format="YYYY-MM-DD">{item.auction.slot.slotDate}</Moment></td>
              <td className="text-center">{item.auction.basePrice}</td>
              <td className="text-center">{item.bidPrice}</td>
            </tr>
          })}

        </tbody>
      </table>
    </>
  );
}

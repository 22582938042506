const palyNotifyTone = () => {
    const audio = new Audio("./music/order_notify.mp3");
    audio.play()
    // if(status){
    // }else{
    //     audio.pause()
    // }
}

export{
    palyNotifyTone
}
import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import ActionButton from '../action-button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ToastSuccess, ToastError } from "../toast-notification";
import {
    getCoupons,
    createCoupon,
    couponUpdate,
    deleteCouponCode
} from '../../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

class Coupons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isUpdate: false,
            amountType: true,
            couponCode: "",
            amount: "",
            couponType: "single",
            foodType: "food",
            couponId: "",
            couponStatus: false,
            allCouponList: [],
        }
        this.allCoupon();
    }
    // -----------Event target ------------
    handleRadioAmountType = (e) => {
        if (e.target.value === "true") {
            this.setState({
                amountType: true,
            }, () => {
                console.log(this.state.amountType);
            })
        } else {
            this.setState({
                amountType: false,
                flatUpTo: true,
            }, () => {
                console.log(this.state.amountType);
            })
        }
    }
    handleAmount = (e) => {
        this.setState({
            amount: e.target.value
        }, () => {
            console.log(this.state.amount);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleCouponCode = (e) => {
        this.setState({
            couponCode: e.target.value
        }, () => {
            console.log(this.state.couponCode);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleRadioCouponType = (e) => {
        this.setState({
            couponType: e.target.value
        }, () => {
            console.log(this.state.couponType);
        })
    }
    handleRadioOutletFood = (e) => {
        this.setState({
            foodType: e.target.value
        }, () => {
            console.log(this.state.foodType);
        })
    }
    handleFormClear = () => {
        this.setState({
            isSubmitted: false,
            isUpdate: false,
            amountType: true,
            couponCode: "",
            amount: "",
            couponType: "single",
            foodType: "food",
            couponId: "",
            couponStatus: false,
        })
    }
    handleUpdateCoupon = (m) => {
        this.setState({
            isUpdate: true,
            couponCode: m.code,
            amountType: m.percentage,
            amount: m.amount,
            couponType: m.couponType,
            foodType: m.foodType,
            couponId: m._id,
            couponStatus: m.isActive
        })
    }
    handleChangeStatus = (status) =>{
        this.setState({
            isUpdate: true,
            couponCode: status.code,
            amountType: status.percentage,
            amount: status.amount,
            couponType: status.couponType,
            foodType: status.foodType,
            couponId: status._id,
            // couponStatus: status.isActive
        })
        if(status.isActive){
            this.setState({couponStatus: false },()=>{
                this.newCouponAndUpdate();
            })
        }else{
            this.setState({couponStatus: true },()=>{
                this.newCouponAndUpdate();
            })
        }
    }
    //  -------------Validtion ------------
    valid = () => {
        let checked = true;
        if (this.state.amount === "") {
            checked = false;
        }
        if (this.state.couponCode === "") {
            checked = false;
        }
        return checked
    }
    // ------- Api call start-------------------
    allCoupon = async () => {
        store.dispatch(loader(true));
        try {
            const coupon = await getCoupons();
            this.setState({ allCouponList: coupon.data.data }, () => {
            })
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }

    newCouponAndUpdate = async () => {
        store.dispatch(loader(true));
        let amount = parseInt(this.state.amount);

        if (this.state.isUpdate) {
            const data = {
                "percentage": this.state.amountType,
                "amount": amount,
                "isActive": this.state.couponStatus
            }
            try {
                const update = await couponUpdate(this.state.couponId, data)
                store.dispatch(loader(false));
                ToastSuccess(update.data.message);
                this.allCoupon();
                this.handleFormClear();
            } catch (ex) {
                console.log(ex.response.data.message);
                store.dispatch(loader(false));
                ToastError(ex.response.data.message);
            }
        } else {
            const data = {
                "code": this.state.couponCode,
                "percentage": this.state.amountType,
                "amount": amount,
                "couponType": this.state.couponType,
                "foodType": this.state.foodType,
                "isActive": this.state.couponStatus
            }
            try {
                const couponNew = await createCoupon(data);
                store.dispatch(loader(false));
                ToastSuccess(couponNew.data.message);
                this.handleFormClear()
                this.allCoupon();
            } catch (ex) {
                console.log(ex);
                store.dispatch(loader(false));
                ToastError(ex.response.data.message);
            }
        }

    }
    //    -------------Delete coupon api -------
    deleteCoupon = async (id) => {
        if (window.confirm("If you delete one, the coupon of all the outlets and product will be deleted")) {
            try {
                const Delete = await deleteCouponCode(id);
                ToastSuccess(Delete.data.message);
                this.allCoupon();
            } catch (ex) {
                ToastError(ex.response.data.message)
            }
        }
    }
    // ------- Api call end-------------------
    //  -------- Coupon create ---------
    handleSubmitCoupon = () => {
        this.setState({ isSubmitted: true })
        if (this.valid()) {
            this.newCouponAndUpdate();
        }
    }
    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="category-container">
                                <h4 className="text-secondary mb-4">Coupons</h4>
                                {this.state.allCouponList.map(item => {
                                    return (
                                        <div className="coupon-container my-3" key={item._id}>
                                            <div className="coupon-code w-75">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <div className="offer-clip">
                                                            <h5 className="m-0">{item.code}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end align-items-baseline">
                                                        {/* <Tooltip title="Add Offer" placement="right" arrow>
                                                            <div
                                                            // onClick={() => this.handleUpdateOffer(item)}
                                                            >
                                                                <ActionButton>
                                                                    <i className="demo-icon icon-add">&#xe802;</i>
                                                                </ActionButton>
                                                            </div>
                                                        </Tooltip> */}
                                                        <Tooltip title="Edit Offer" placement="right" arrow>
                                                            <div
                                                                onClick={() => this.handleUpdateCoupon(item)}
                                                            >
                                                                <ActionButton>
                                                                    <i className="demo-icon icon-edit">&#xe80f;</i>
                                                                </ActionButton>
                                                            </div>
                                                        </Tooltip>
                                                        <Tooltip title="Delete Offer" placement="right" arrow>
                                                            <div
                                                                onClick={() => this.deleteCoupon(item._id)}
                                                            >
                                                                <ActionButton>
                                                                    <i className="demo-icon icon-delete">&#xe80e;</i>
                                                                </ActionButton>
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div className="coupon-footer">
                                                    <div>
                                                        <b className="text-capitalize">{item.couponType}</b><br />
                                                        <b className="font-12">Coupon type</b>
                                                    </div>
                                                    <div>
                                                        <b className="text-capitalize">{item.foodType}</b><br />
                                                        <b className="font-12">Food type</b>
                                                    </div>
                                                    <Tooltip title="Status update" placement="right-end" arrow>
                                                        <Switch
                                                            checked={item.isActive}
                                                            onChange={(e)=>this.handleChangeStatus(item)}
                                                            name="checkedDiscount"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="total-users">
                                                <h1>{item.percentage ? `${item.amount}%` : `₹ ${item.amount}`}</h1>
                                                <p>OFFER</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="category-container mb-3">
                                <div className="d-flex justify-content-between">
                                    <h5 className="text-secondary ml-2">Create Coupon</h5>
                                    <button
                                        onClick={() => { this.handleFormClear() }}
                                        className="clear-btn">Clear
                                    </button>
                                </div>
                                <div className="category-field">

                                    <RadioGroup aria-label="percentageAmount" name="percentageAmount"
                                        value={`${this.state.amountType}`}
                                        onChange={(e) => this.handleRadioAmountType(e)}
                                    >
                                        <div className="d-flex">
                                            <FormControlLabel value="true" control={<Radio color="default" />} label="Percentage % " />
                                            <FormControlLabel value="false" control={<Radio color="default" />} label="Amount ₹" />
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div className="category-field">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group m-0">
                                                <label className="font-12 m-0">Amount</label>
                                                <input
                                                    onChange={(e) => this.handleAmount(e)}
                                                    style={this.state.amount === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                    type="number"
                                                    value={this.state.amount}
                                                    className="form-control"
                                                    placeholder={this.state.amountType ? "5% - 100%" : "Amount ₹"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group m-0">
                                                <label className="font-12 m-0">Enter coupon code</label>
                                                <input
                                                    onChange={(e) => this.handleCouponCode(e)}
                                                    style={this.state.couponCode === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                    type="text"
                                                    value={this.state.couponCode}
                                                    className="form-control"
                                                    placeholder="FRD012"
                                                    disabled={this.state.isUpdate ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="category-field">
                                    <div className="form-group m-0">
                                        {/* <RadioGroup aria-label="couponsT" name="couponsT"
                                        value={`${this.state.couponType}`}
                                        onChange={(e) => this.handleRadioCouponType(e)}
                                        >
                                            <div className="d-flex align-items-center">
                                                <b className="mb-2 mr-3">Coupon type:</b>
                                                <FormControlLabel
                                                    value="all"
                                                    control={<Radio color="default" />}
                                                    label="All"
                                                // disabled={this.state.isUpdate ? this.state.outletFood === "outiet" ? false : true : false}
                                                />
                                                <FormControlLabel
                                                    value="single"
                                                    control={<Radio color="default" />}
                                                    label="Single"
                                                // disabled={this.state.isUpdate ? this.state.outletFood === "food" ? false : true : false}
                                                />
                                            </div>
                                        </RadioGroup> */}
                                        <RadioGroup aria-label="foodT" name="foodT"
                                            value={`${this.state.foodType}`}
                                            onChange={(e) => this.handleRadioOutletFood(e)}
                                        >
                                            <div className="d-flex align-items-center">
                                                <b className="mb-2 mr-3">Foods type:</b>
                                                <FormControlLabel
                                                    value="food"
                                                    control={<Radio color="default" />}
                                                    label="Food"
                                                    disabled={this.state.isUpdate ? true : false}
                                                />
                                                <FormControlLabel
                                                    value="outlet"
                                                    control={<Radio color="default" />}
                                                    label="Outlet"
                                                    disabled={this.state.isUpdate ? true : false}
                                                />
                                            </div>
                                        </RadioGroup>
                                    </div>
                                    <div className="text-right">
                                        <button
                                            className="btn btn-verify mt-2"
                                            onClick={this.handleSubmitCoupon}
                                        >
                                            {this.state.isUpdate ? "Update" : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}


export default connect(mapStateToProps)(Coupons);
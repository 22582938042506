import React, { Component } from 'react';
import { ToastSuccess, ToastError } from "../../component/toast-notification";
import { Modal, ModalFooter } from "react-bootstrap";
import {
    verifyBankAccount,
    resetBankAccount,
    newBankAccount,
    verifyKycDetails
} from '../../api/apiService';

import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}
class AccountSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userAc: this.props.userAc,
            modalShow: false,
            isModalSubmit: false,
            name: "",
            accountNo: "",
            bankName: "",
            branchName: "",
            accountType: "",
            ifscCode: ""
        }
        console.log(this.props.userAc);
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.userAc !== prevProps.userAc) {
            this.setState({ userAc: this.props.userAc });
        }
    }
    handleModalShowHide = () => {
        this.setState({ modalShow: !this.state.modalShow })
    }
    handleEvent = (event) => {
        let name = event.target.name;
        this.setState({ [name]: event.target.value }, () => {
            return this.state.isModalSubmit ? this.valid() : false;
        });

    }
    valid = () => {
        let checked = true;
        if (this.state.name === "") {
            checked = false;
        }
        if (this.state.accountNo === "") {
            checked = false;
        }
        if (this.state.bankName === "") {
            checked = false;
        }
        if (this.state.branchName === "") {
            checked = false;
        }
        if (this.state.accountType === "") {
            checked = false;
        }
        if (this.state.ifscCode === "") {
            checked = false;
        }
        return checked
    }
    submittedBankDetails = () => {
        this.setState({ isModalSubmit: true })
        if (this.valid()) {
            this.bankDetails();
        }
    }
    // Api Call ----------------
    // KYC verify -------
    kycDetailsVerify = async (userId, action) => {
        const data = {
            "actionUserId": userId,
            "action": action
        }
        try {
            const kyc = await verifyKycDetails(data);
            console.log(kyc.data.data);
            ToastSuccess(kyc.data.message);
            this.props.rerenderParentCallback(userId);
        } catch (ex) {
            console.log(ex);
            ToastError(ex.message);
        }
    }
    // Bank details add -------------
    bankDetails = async () => {
        const data = {
            "actionUserId": this.state.userAc._id,
            "beneficiary": this.state.name,
            "accountNo": this.state.accountNo,
            "bankName": this.state.bankName,
            "branchName": this.state.branchName,
            "accountType": this.state.accountType,
            "IFSCCode": this.state.ifscCode
        }
        store.dispatch(loader(true));
        try {
            const bank = await newBankAccount(data);
            console.log(bank.data.data);
            store.dispatch(loader(false));
            ToastSuccess(bank.data.message)
            this.props.rerenderParentCallback(bank.data.data.user._id);
            this.setState({
                modalShow: false,
                isModalSubmit: false,
                name: "",
                accountNo: "",
                bankName: "",
                branchName: "",
                accountType: "",
                ifscCode: ""
            });
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
            ToastError(ex.response.data.message)
        }
    }
    // ------------ Bank verify and reset -------------------
    hendleBankAccount = async (type, userId) => {
        if (type === "verify") {
            store.dispatch(loader(true));
            try {
                const verify = await verifyBankAccount(userId);
                store.dispatch(loader(false));
                this.props.rerenderParentCallback(userId);
                ToastSuccess(verify.data.message)
            } catch (ex) {
                console.log(ex);
                store.dispatch(loader(false));
                ToastError(ex.response.data.message)
            }
        }
        if (type === "reset") {
            if (window.confirm("Do you want to delete bank account details ?")) {
                store.dispatch(loader(true));
                try {
                    const reset = await resetBankAccount(userId);
                    store.dispatch(loader(false));
                    this.props.rerenderParentCallback(userId);
                    ToastSuccess(reset.data.message)
                } catch (ex) {
                    console.log(ex);
                    store.dispatch(loader(false));
                    ToastError(ex.response.data.message)
                }
            }
        }
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="kyc-container">
                                <div className="kyc-header">
                                    <h1 className="text-center">KYC</h1>
                                </div>
                                <div>
                                    {this.state.userAc.account !== null ?
                                        <div className="acc-details">
                                            <div>
                                                <p><b>KYC type :</b> {this.state.userAc.account.kycType}</p>
                                                <p><b>KYC no :</b> {this.state.userAc.account.kycNo}</p>
                                            </div>
                                            <div className="d-flex justify-content-between mt-4">
                                                <div className="kyc-img-container mt-2">
                                                    {/* <img className="kyc-img" src={this.state.userAc.account.docUrl} alt="" /> */}
                                                    {this.state.userAc.account.docUrl ? <img src={this.state.userAc.account.docUrl}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = "/assate/images/no-image-available.jpg" }}
                                                        className="kyc-img" alt="" /> : <img src="/assate/images/no-image-available.jpg"
                                                            className="kyc-img" alt="" />}
                                                </div>
                                                <div className="d-flex align-items-end">
                                                    <button className="btn btn-verify mr-1" onClick={() => this.kycDetailsVerify(this.state.userAc._id, "decline")}>Decline</button>
                                                    {this.state.userAc.account.kycVerified === true ? 
                                                    ""
                                                        : <button className="btn btn-verify"
                                                            onClick={() => this.kycDetailsVerify(this.state.userAc._id, "accept")}>Approve</button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="kyc-not-found">
                                            <img src="/assate/images/no-data-found.png" alt="" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="kyc-container">
                                <div className="kyc-header">
                                    <h1 className="text-center">Bank</h1>
                                </div>
                                {this.state.userAc.account ?
                                    <div>{this.state.userAc.account.bank ?
                                        <div className="acc-details">
                                            <p className="d-flex justify-content-between">
                                                <span>
                                                    <b>Name :</b> {this.state.userAc.account.bank.beneficiary}
                                                </span>
                                                <span className="mr-4">
                                                    <b>Account type :</b> {this.state.userAc.account.bank.accountType}
                                                </span>
                                            </p>
                                            <p><b>Bank name :</b> {this.state.userAc.account.bank.bankName}</p>
                                            <p><b>Account no :</b> {this.state.userAc.account.bank.accountNo}</p>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p><b>IFSC code :</b> {this.state.userAc.account.bank.IFSCCode}</p>
                                                    <p><b>Branch Name :</b> {this.state.userAc.account.bank.branchName}</p>
                                                </div>
                                                <div className="d-flex align-items-center mt-3">
                                                    <button className="btn btn-verify mr-1" onClick={() => this.hendleBankAccount("reset", this.state.userAc.account.user)}
                                                    >
                                                        Reset
                                                    </button>
                                                    {this.state.userAc.account.bank.bankStatus === "Pending" ?
                                                        <button className="btn btn-verify"
                                                            onClick={() => this.hendleBankAccount("verify", this.state.userAc.account.user)}
                                                        >
                                                            Verify
                                                        </button>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="kyc-not-found">
                                            <div className="kyc">
                                                <img src="/assate/images/no-data-found.png" alt="" />
                                                <div className="kyc-addBtn">
                                                    <button className="btn btn-verify" onClick={this.handleModalShowHide}>Add bank</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    :
                                    <div className="kyc-not-found">
                                        <div className="kyc">
                                            <img src="/assate/images/no-data-found.png" alt="" />
                                            <div className="kyc-addBtn">
                                                <button className="btn btn-verify" onClick={this.handleModalShowHide}>Add bank</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* modal start */}
                <Modal
                    show={this.state.modalShow}
                    onHide={this.handleModalShowHide}
                    backdrop="static"
                    size="md"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Bank Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Account Holder Name:</label>
                                    <input
                                        onChange={(e) => this.handleEvent(e)}
                                        style={this.state.name === "" && this.state.isModalSubmit ? { border: "1px solid red" } : {}}
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Enter the name" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Account No:</label>
                                    <input
                                        onChange={(e) => this.handleEvent(e)}
                                        style={this.state.accountNo === "" && this.state.isModalSubmit ? { border: "1px solid red" } : {}}
                                        type="number"
                                        name="accountNo"
                                        className="form-control"
                                        placeholder="XXXXXXXXXX" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Bank Name</label>
                                    <input
                                        onChange={(e) => this.handleEvent(e)}
                                        style={this.state.bankName === "" && this.state.isModalSubmit ? { border: "1px solid red" } : {}}
                                        type="text"
                                        name="bankName"
                                        className="form-control"
                                        placeholder="Enter bank name" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Branch Name</label>
                                    <input
                                        onChange={(e) => this.handleEvent(e)}
                                        style={this.state.branchName === "" && this.state.isModalSubmit ? { border: "1px solid red" } : {}}
                                        type="text"
                                        name="branchName"
                                        className="form-control"
                                        placeholder="Enter the branch name" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Account Type</label>
                                    <input
                                        onChange={(e) => this.handleEvent(e)}
                                        style={this.state.accountType === "" && this.state.isModalSubmit ? { border: "1px solid red" } : {}}
                                        type="text"
                                        name="accountType"
                                        className="form-control"
                                        placeholder="Type" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">IFSC Code</label>
                                    <input
                                        onChange={(e) => this.handleEvent(e)}
                                        style={this.state.ifscCode === "" && this.state.isModalSubmit ? { border: "1px solid red" } : {}}
                                        type="text"
                                        name="ifscCode"
                                        className="form-control"
                                        placeholder="ABCXXXXXXX" />
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <ModalFooter>
                        <div className="text-right my-3">
                            <button
                                onClick={() => this.submittedBankDetails()}
                                type="button"
                                className="btn btn-verify"
                                data-toggle="modal">Add
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps)(AccountSection);
import { colors } from '@material-ui/core';
import React from 'react'

export default function CardOrders(props) {
    const data = props.data;
    const title = props.title;
    return (
        <div className="category-container">
            <div className="user-order-container">
                <h4 className="family-Courgette">{title}</h4>
                <table className="details" style={{ minWidth: "100%" }}>
                    <tbody>
                        <tr><td><b>Product:</b></td><td ><span>{data.product.product_name}</span> <span className='badge badge-dark'> x {data.quantity}</span></td></tr>
                        <tr><td><b>Habit:</b></td><td>{data.product.habit}</td></tr>
                        <tr><td><b>Price without tax:</b></td><td>₹ {data.price}</td></tr>
                        <tr><td><b>Food Value:</b></td><td>₹{data.price}X{data.quantity} = <span className='badge badge-dark'>₹ {data.price * data.quantity}</span></td></tr>
                    </tbody>
                </table>
                <div className="details mt-2">
                    <span className="font-weight-bold">Add Ons :</span>
                    <table className="w-100 font-12">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="text-center">Extra Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.adOns.map(adOn => {
                                return <tr key={adOn._id}>
                                    <td>{adOn.adOnDetails}</td>
                                    <td className="text-center">{adOn.adOnExtraPrice}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

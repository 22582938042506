import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CardOrders from '../component/c-orders/cardOrders';

const useStyles = makeStyles({
    list: {
        width: 500,
        background: '#efefef'

    },
    fullList: {
        width: 'auto',
    },
});

export default function DrawerRight(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false
    });
    const [cartData, setCartData] = useState(props.data);

    useEffect(() => { setCartData(props.data) }, [props])

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (

        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, true)}
        >
            {cartData && cartData.length !== 0 ?
                cartData && cartData.map((i, index) => {
                    return (
                        <div key={i._id} className="m-4">
                            <CardOrders
                                title={`Item : ${index + 1}`}
                                data={i}
                            />
                        </div>
                    )
                })
                :
                    <div className='card-not-fund family-Courgette'>
                        <img src='../../../assate/images/add_friends.svg' alt='logo' />
                    <h4>Cart Items Not Found</h4>
                    </div>
            }
        </div>
    );

    return (
        <React.Fragment>
            <button
                onClick={toggleDrawer("right", true)}
                type="button"
                className="btn btn-verify" >
                Card items
            </button>
            <Drawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
            >
                {list("right")}
            </Drawer>
        </React.Fragment>
    );
}

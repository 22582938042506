import React, { Component } from 'react';
import ActionButton from '../action-button';
import Radio from '@material-ui/core/Radio';
import { Modal } from "react-bootstrap";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import BackspaceIcon from '@material-ui/icons/Backspace';
import {
    createOffer,
    deleteOffer,
    getOffer,
    updateOffer,
    addOutletOffers,
    addProductOffers,
    getOfferOutlet,
    deleteOfferOutlet,
    getAllOutlet,
    mainProduct,
    allSearch
} from '../../api/apiService';
import { ToastSuccess, ToastError } from "../toast-notification";
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

class Discount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amountType: true,
            flatUpTo: true,
            outletFood: "outlet",
            discount: "",
            minBill: "",
            upToDiscount: "",
            allOffers: [],
            allOutletOffers: [],
            isSubmitted: false,
            isUpdate: false,
            offerId: "",
            modalShow: false, //Modal open and close----
            modalDetailsShow: false, //Modal offer delete open and close----
            modalType: "", //modal type ----
            OutletAll: [],
            getProduct: [],
            outletOffer: [],
            ProductOffer: [],
            productFieldToggle: false,
            isModal: false,
            selectValue: [],
            selectFoodValue: [],
            dropMenu: false,
            dropFoodMenu: false
        }
        this.offers();
        this.allOutlet(1);
    }
    handleRadioAmountType = (e) => {
        if (e.target.value === "true") {
            this.setState({
                amountType: true,
            }, () => {
                console.log(this.state.amountType);
            })
        } else {
            this.setState({
                amountType: false,
                flatUpTo: true,
                upToDiscount: 0
            }, () => {
                console.log(this.state.amountType);
            })
        }
    }
    handleRadioFlatUpTo = (e) => {
        if (e.target.value === "true") {
            this.setState({
                flatUpTo: true,
                upToDiscount: 0
            }, () => {
                console.log(this.state.flatUpTo);
            })
        } else {
            this.setState({
                flatUpTo: false
            }, () => {
                console.log(this.state.flatUpTo);
            })
        }
    }
    handleRadioOutletFood = (e) => {
        this.setState({
            outletFood: e.target.value
        }, () => {
            console.log(this.state.outletFood);
        })
    }
    handleDiscount = (e) => {
        this.setState({
            discount: e.target.value
        }, () => {
            console.log(this.state.discount);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleMinBill = (e) => {
        this.setState({
            minBill: e.target.value
        }, () => {
            console.log(this.state.minBill);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleUpToDiscount = (e) => {
        this.setState({
            upToDiscount: e.target.value
        }, () => {
            console.log(this.state.upToDiscount);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleUpdateOffer = (m) => {
        console.log(m);
        this.setState({
            isUpdate: true,
            amountType: m.percentage,
            flatUpTo: m.flat,
            outletFood: m.offerType,
            discount: m.amount,
            minBill: m.minBill,
            upToDiscount: m.uptoDiscount,
            offerId: m._id
        })
    }
    // -------------------------Form all data clear --------------
    handleFormClear = () => {
        this.setState({
            amountType: true,
            flatUpTo: true,
            outletFood: "outlet",
            discount: "",
            minBill: "",
            upToDiscount: "",
            isSubmitted: false,
            isUpdate: false,
        })
    }

    // multi dropdown ----------
    dropdownMenuOpen = () => {
        this.setState({ dropMenu: !this.state.dropMenu, dropFoodMenu: false })
    }
    handleClickOption = (e) => {
        let a = this.state.selectValue;
        let selectedOutletId = [];
        var index = a.findIndex(x => x._id === e._id)
        if (index === -1) {
            document.getElementById(e._id).checked = true;
            a.push(e);
        } else {
            var indexNum = a.indexOf(e);
            if (indexNum > -1) {
                document.getElementById(e._id).checked = false;
                a.splice(indexNum, 1);
            }
        }
        a.forEach(m => { selectedOutletId.push(m._id) })
        this.setState({ selectValue: a, outletOffer: selectedOutletId }, () => {
        })
    }
    deletDropSelectItem = (e) => {
        this.setState({
            selectValue: this.state.selectValue.filter(item => item._id !== e._id),
            outletOffer: this.state.outletOffer.filter(item => item._id !== e._id)
        }, () => {
            document.getElementById(e._id).checked = false;
        })
    }
    // food modal -----------------------
    dropdownsingleoutlet = () => {
        this.setState({ dropMenu: !this.state.dropMenu, dropFoodMenu: false })
    }
    handleClickOutletOption = (e) => {
        this.setState({ selectFoodValue: [], ProductOffer: [] });
        let a = [];
        var index = a.findIndex(x => x._id === e._id)
        if (index === -1) {
            a.push(e);
        } else {
            var indexNum = a.indexOf(e);
            if (indexNum > -1) {
                a.splice(indexNum, 1);
            }
        }
        this.setState({ selectValue: a, outletOffer: a[0]._id, productFieldToggle: true, dropMenu: false }, () => {
            this.allProduct(this.state.outletOffer)
        })
    }
    // all select product field -----------
    dropdownFoodMenuOpen = () => {
        this.setState({ dropFoodMenu: !this.state.dropFoodMenu, dropMenu: false })
    }
    handleClickFoodOption = (e) => {
        let a = this.state.selectFoodValue;
        let selectedFoodId = [];
        var index = a.findIndex(x => x._id === e._id)
        if (index === -1) {
            document.getElementById(e._id).checked = true;
            a.push(e);
        } else {
            var indexNum = a.indexOf(e);
            if (indexNum > -1) {
                document.getElementById(e._id).checked = false;
                a.splice(indexNum, 1);
            }
        }
        a.forEach(m => { selectedFoodId.push(m._id) })
        this.setState({ selectFoodValue: a, ProductOffer: selectedFoodId }, () => {
            return this.state.isModal ? this.modalValid() : false
        })
    }
    deletDropFoodSelectItem = (e) => {
        this.setState({
            selectFoodValue: this.state.selectFoodValue.filter(item => item._id !== e._id),
            ProductOffer: this.state.ProductOffer.filter(item => item._id !== e._id)
        }, () => {
            document.getElementById(e._id).checked = false;
        })
    }
    // -------------------------end multi dropdown---------


    // Validation------------
    valid() {
        let checked = true;
        if (this.state.discount === "") {
            return checked = false
        }
        if (this.state.minBill === "") {
            return checked = false
        }
        if (!this.state.flatUpTo) {
            if (this.state.upToDiscount === "") {
                return checked = false
            }
        }
        return checked;
    }
    // Api call section start -------------
    /* 
    * Api
    * New Offer 
    * Create & Update
    */
    newOfferCreate = async () => {
        let discount = parseInt(this.state.discount);
        let minBill = parseInt(this.state.minBill);
        let upToDiscount = parseInt(this.state.upToDiscount);
        let data = {
            "percentage": this.state.amountType,
            "amount": discount,
            "minBill": minBill,
            "flat": this.state.flatUpTo,
            "uptoDiscount": upToDiscount,
            "offerType": this.state.outletFood
        }
        store.dispatch(loader(true));
        if (this.state.isUpdate) {
            /* 
            * Api
            * Offer 
            * update
            */
            try {
                const update = await updateOffer(this.state.offerId, data);
                store.dispatch(loader(false));
                this.offers();
                this.handleFormClear();
                ToastSuccess(update.data.message);
            } catch (ex) {
                console.log(ex);
                ToastError(ex.response.data.message);
            }
        } else {/* 
            * Api
            * New Offer 
            * Create
            */
            try {
                const offer = await createOffer(data);
                store.dispatch(loader(false));
                this.offers();
                this.handleFormClear();
                ToastSuccess(offer.data.message);
            } catch (ex) {
                console.log(ex);
                store.dispatch(loader(false));
                ToastError(ex.response.data.message);
            }
        }
    }
    
    offers = async () => {
        store.dispatch(loader(true));
        try {
            const allOff = await getOffer();
            this.setState({
                allOffers: allOff.data.data
            });
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    // -----------------------------Add outlets offer -----------------
    /* 
    * Api
    * Offer  
    * Add For Outlets
    */
    
    setOutletOffers = async (offerId) => {
        let data = {
            "outlets": this.state.outletOffer
        }
        try {
            const setOffer = await addOutletOffers(offerId, data)
            ToastSuccess(`${setOffer.data.message} offers`);
            this.handleModalclose();
            console.log(setOffer);
        } catch (ex) {
            console.log(ex);
        }
    }
    // -----------------------------Add product offer -----------------
    /* 
    * Api
    * Offer  
    * Add For Products
    */
    setProductOffers = async (offerId) => {
        console.log(this.state.ProductOffer);
        let data = {
            "products": this.state.ProductOffer
        }
        try {
            const setOffer = await addProductOffers(offerId, this.state.outletOffer, data)
            ToastSuccess(`${setOffer.data.message} offers`);
            this.handleModalclose();
        } catch (ex) {
            console.log(ex);
        }
    }
    // ---------------------------------------Delete Offer -------------
    /* 
    * Api
    * Offer Delete
    */
    handleDeleteOffer = async (offerId) => {
        if (window.confirm("If you delete one, the discount of all the outlets will be deleted")) {
            try {
                const offerDelete = await deleteOffer(offerId);
                ToastSuccess(offerDelete.data.message);
                this.offers();
            } catch (ex) {
                ToastError(ex.response.data.message)
            }
        }
    }
    /* 
    * Api
    * Get All Outlets
    */
    
    allOutlet = async (pageNo) => {
        try {
            const outlets = await getAllOutlet(pageNo);
            this.setState({ OutletAll: outlets.data.data })
        } catch (ex) {
            console.log(ex);
        }
    }
    /* 
    * Api
    * Get All products
    */
    allProduct = async (outletId) => {
        store.dispatch(loader(true));
        try {
            const product = await mainProduct(outletId);
            this.setState({ getProduct: product.data.data })
            store.dispatch(loader(false));
            console.log(product);
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }

    /* 
    * Api
    * Search 
    * Outlets & products
    */
    handleSearch = async (type, txt) => {
        let outletId = type === "product" ? this.state.outletOffer[0] : undefined;
        try {
            const search = await allSearch(type, encodeURIComponent(txt.target.value), outletId);
            let dataValue = search.data.data;
            if (!dataValue) {
                if (type === "outlet") {
                    this.allOutlet()
                } else {
                    this.allProduct()
                }

            } else {
                if (type === "outlet") {
                    this.setState({ OutletAll: search.data.data }, () => {
                        console.log(this.state.OutletAll)
                    })
                } else {
                    this.setState({ getProduct: search.data.data }, () => {
                        console.log(this.state.getProduct)
                    })
                }
            }

        } catch (ex) {
            console.log(ex);
        }
    }
    // offer get for oulets and product------
    getOfferOutletAndFood = async () => {
        try {
            const offer = await getOfferOutlet(this.state.offerId);
            this.setState({ allOutletOffers: offer.data.data });
        } catch (ex) {
            console.log(ex);
        }
    }
    // offer delete for oulets and product------
    deleteOfferOutletAndFood = async (id) => {
        store.dispatch(loader(true));
        if (window.confirm("Do you want to delete the offer?")) {
            try {
                const offerDelete = await deleteOfferOutlet(id, this.state.modalType);
                this.getOfferOutletAndFood();
                store.dispatch(loader(false));
                ToastSuccess(offerDelete.data.data.message)
            } catch (ex) {
                console.log(ex);
                store.dispatch(loader(false));
            }
        }
    }
    // Api call section End -------------
    // From submit-----------
    handleSubmit = (event) => {
        // event.preventDefault();
        this.setState({ isSubmitted: true })
        if (this.valid()) {
            this.newOfferCreate()
        }
    };
    // Modal Section start -----------------------
    handleModalclose = () => {
        this.setState({
            modalShow: false,
            modalDetailsShow: false,
            modalType: "",
            offerId: "",
            isModal: false,
            OutletAll: [],
            outletOffer: [],
            ProductOffer: [],
            selectValue: [],
            selectFoodValue: [],
            productFieldToggle: false,
            dropMenu: false,
            dropFoodMenu: false
        }, () => {
            this.allOutlet();
        })
    }
    handleModalDetailsOpen = (offer) => {
        this.setState({ offerId: offer._id })
        let typeOf = offer.offerType;
        if (typeOf === "outlet") {
            this.setState({
                modalDetailsShow: !this.state.modalDetailsShow,
                modalType: "outlet"
            }, () => {
                this.getOfferOutletAndFood();
            })
        }
        if (typeOf === "food") {
            this.setState({
                modalDetailsShow: !this.state.modalDetailsShow,
                modalType: "food"
            }, () => {
                this.getOfferOutletAndFood();
            })
        }

    }
    handleModalOpen = (offer) => {
        this.setState({ offerId: offer._id })
        let typeOf = offer.offerType;
        if (typeOf === "outlet") {
            this.setState({
                modalShow: !this.state.modalShow,
                modalType: "outlet"
            })
        }
        if (typeOf === "food") {
            this.setState({
                modalShow: !this.state.modalShow,
                modalType: "food"
            })
        }

    }
    modalValid = () => {
        let checked = true;
        if (this.state.outletOffer.length < 1) {
            return checked = false
        }
        if (this.state.productFieldToggle) {
            if (this.state.ProductOffer.length < 1) {
                return checked = false
            }
        }
        return checked;
    }
    handleClickModal() {
        let elam = document.getElementById('outlet');
        let elamclass = elam.querySelector('ul');
        let scrolEffect = elamclass.scrollHeight - elamclass.clientHeight - elamclass.scrollTop < 10;
        console.log(scrolEffect);
        if (scrolEffect) {
            alert("This is scroll");
        }
    }
    submittedSetOutlet = () => {
        this.setState({ isModal: true });
        if (this.modalValid()) {
            if (this.state.modalType === "outlet") {
                this.setOutletOffers(this.state.offerId);
            }
            if (this.state.modalType === "food") {
                this.setProductOffers(this.state.offerId);
            }
        }
    }
    // Modal Section end -------------------------
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="category-container">
                                <h4 className="text-secondary mb-4">Discount</h4>
                                {this.state.allOffers.map(item => {
                                    return <div className="subs-box mb-3" key={item._id}>
                                        {/* <h5 className="m-0">Offer</h5> */}
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <div className="offer-clip">
                                                    <h5 className="m-0">{item.flat ? "FLAT" : ""} {item.percentage ? `${item.amount} %` : `₹ ${item.amount}`} OFF</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-baseline">
                                                <button
                                                    className="btn btn-sm btn-verify mr-1"
                                                    onClick={() => this.handleModalDetailsOpen(item)}>Details
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-verify"
                                                    onClick={() => this.handleModalOpen(item)}>Add to {item.offerType}
                                                </button>
                                                <Tooltip title="Edit Offer" placement="right" arrow>
                                                    <div onClick={() => this.handleUpdateOffer(item)}>
                                                        <ActionButton>
                                                            <i className="demo-icon icon-edit">&#xe80f;</i>
                                                        </ActionButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Delete Offer" placement="right" arrow>
                                                    <div onClick={() => this.handleDeleteOffer(item._id)}>
                                                        <ActionButton>
                                                            <i className="demo-icon icon-delete">&#xe80e;</i>
                                                        </ActionButton>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="text-capitalize mb-2">
                                            <b>Type: {item.offerType}</b>
                                        </div>
                                        <div className="sub-box-footer">
                                            <div className="ml-2">
                                                <b>₹ {item.uptoDiscount}</b><br />
                                                <b className="font-12">Up to discount</b>
                                            </div>
                                            <div className="">
                                                <b>₹ {item.minBill}</b><br />
                                                <b className="font-12">Min purchase</b>
                                            </div>
                                            <div className="">
                                                <b>0</b><br />
                                                <b className="font-12">Total outlet</b>
                                            </div>
                                            <Tooltip title={item.isActive ? "Deactive" : "Active"} placement="right-end" arrow>
                                                <Switch
                                                    checked={item.isActive}
                                                    // onChange={(e)=>this.handleChangeStatus(item)}
                                                    name="checkedDiscount"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="category-container mb-3">
                                <div className="d-flex justify-content-between">
                                    <h5 className="text-secondary ml-2">Create Offers</h5>
                                    <button
                                        onClick={() => { this.handleFormClear() }}
                                        className="clear-btn">Clear</button>
                                </div>
                                <div className="category-field">

                                    <RadioGroup aria-label="percentageAmount" name="percentageAmount"
                                        value={`${this.state.amountType}`}
                                        onChange={(e) => this.handleRadioAmountType(e)}
                                    >
                                        <div className="d-flex">
                                            <FormControlLabel value="true" control={<Radio color="default" />} label="Percentage % " />
                                            <FormControlLabel value="false" control={<Radio color="default" />} label="Amount ₹" />
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div className="category-field">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group m-0">
                                                <label className="font-12 m-0">Discount</label>
                                                <input
                                                    onChange={(e) => this.handleDiscount(e)}
                                                    style={this.state.discount === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                    type="number"
                                                    value={this.state.discount}
                                                    className="form-control"
                                                    placeholder={this.state.amountType ? "5% - 100%" : "Amount ₹"} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group m-0">
                                                <label className="font-12 m-0">Min Bill </label>
                                                <input
                                                    onChange={(e) => this.handleMinBill(e)}
                                                    style={this.state.minBill === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                    type="number"
                                                    value={this.state.minBill}
                                                    className="form-control"
                                                    placeholder="Amount" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-field">
                                    <RadioGroup aria-label="flatUp" name="flatUp"
                                        value={`${this.state.flatUpTo}`}
                                        onChange={(e) => this.handleRadioFlatUpTo(e)}
                                    >
                                        <div className="d-flex">
                                            {this.state.amountType ?
                                                <>
                                                    <FormControlLabel value="true" control={<Radio color="default" />} label="Flat" />
                                                    <FormControlLabel value="false" control={<Radio color="default" />} label="Up To ₹" />
                                                </>
                                                :
                                                <FormControlLabel value="true" control={<Radio color="default" />} label="Flat" />
                                            }

                                        </div>
                                    </RadioGroup>
                                    <div className="form-group m-0">
                                        {!this.state.flatUpTo ?
                                            <div>
                                                <label className="font-12 m-0">Up To Discount</label>
                                                <input
                                                    onChange={(e) => this.handleUpToDiscount(e)}
                                                    style={this.state.upToDiscount === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                    type="number"
                                                    value={this.state.upToDiscount}
                                                    className="form-control"
                                                    placeholder="Amount" />
                                            </div>
                                            :
                                            <div></div>
                                        }

                                        <RadioGroup aria-label="outletFood" name="outletFood"
                                            value={`${this.state.outletFood}`}
                                            onChange={(e) => this.handleRadioOutletFood(e)}
                                        >
                                            <div className="d-flex align-items-center">
                                                <b className="mb-2 mr-3">Offer for outlet:</b>
                                                <FormControlLabel
                                                    value="outlet"
                                                    control={<Radio color="default" />}
                                                    label="Outlets"
                                                    disabled={this.state.isUpdate ? this.state.outletFood === "outiet" ? false : true : false}
                                                />
                                                <FormControlLabel
                                                    value="food"
                                                    control={<Radio color="default" />}
                                                    label="Foods"
                                                    disabled={this.state.isUpdate ? this.state.outletFood === "food" ? false : true : false}
                                                />
                                            </div>
                                        </RadioGroup>
                                    </div>
                                    <div className="text-right">
                                        <button
                                            className="btn btn-verify mt-2" onClick={this.handleSubmit}>{this.state.isUpdate ? "Update" : "Add"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* modal start */}
                {/* add ofers modal */}
                <Modal
                    show={this.state.modalShow}
                    onHide={this.handleModalclose}
                    backdrop="static"
                    size="md"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalType === "outlet" ? "Outlet Offers" : "Food offers"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {this.state.modalType === "outlet" ?
                                <div>
                                    <div className="category-field"
                                    // onClick={(e)=>this.handleClickModal(e)}
                                    >
                                        <div className="select-box">
                                            <div
                                                className="options-container"
                                                style={this.state.dropMenu ? {} : { display: "none" }}>
                                                {this.state.OutletAll.map((item, i) => {
                                                    return (
                                                        <div
                                                            className="option"
                                                            key={item._id}
                                                            onClick={() => this.handleClickOption(item)}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="radio"
                                                                value={item._id}
                                                                id={item._id}
                                                                name="category" />
                                                            <label className="ml-1">{item.outlet_name}</label><br />
                                                            <div className="d-flex justify-content-between">
                                                                <div className="para-shot font-12"><b>{item.address_details.address}</b></div>
                                                                <span>
                                                                    {!item.offer ? "" : <kbd>{item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</kbd>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="selected"
                                                onClick={this.dropdownMenuOpen}
                                                style={this.state.outletOffer < 1 && this.state.isModal ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                                            >
                                                {(this.state.dropMenu && this.state.selectValue.length) >= 1 || (!this.state.dropMenu && this.state.selectValue.length) >= 1 ? "" : "Select outlets"}
                                                {this.state.selectValue.map((m, i) => {
                                                    return <span className="badge bg-warning text-dark mx-1" key={i}>
                                                        {m.outlet_name}
                                                        <BackspaceIcon
                                                            onClick={() => this.deletDropSelectItem(m)}
                                                            style={{ fontSize: "14px", color: "red" }}
                                                        />
                                                    </span>
                                                })}
                                            </div>
                                            <div
                                                className="search-box"
                                                style={this.state.dropMenu ? {} : { display: "none" }}
                                            >
                                                <input
                                                    onChange={(e) => this.handleSearch("outlet", e)}
                                                    id="myText"
                                                    type="search"
                                                    placeholder="Search ..."
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="text-right my-3">
                                        <button
                                            onClick={() => this.submittedSetOutlet()}
                                            type="button"
                                            className="btn btn-verify"
                                            data-toggle="modal">Add For Outlet
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="category-field mb-2">
                                        <div className="select-box">
                                            <div
                                                className="options-container"
                                                style={this.state.dropMenu ? {} : { display: "none" }}>
                                                {this.state.OutletAll.map((item, i) => {
                                                    return (
                                                        <div
                                                            className="option"
                                                            key={item._id}
                                                            onClick={() => this.handleClickOutletOption(item)}
                                                        >
                                                            {/* <input
                                                                type="checkbox"
                                                                className="radio"
                                                                value={item._id}
                                                                id={item._id}
                                                                disabled={true}
                                                                name="category" /> */}
                                                            <label>{item.outlet_name}</label><br />
                                                            <div className="d-flex justify-content-between">
                                                                <div className="para-shot font-12"><b>{item.address_details.address}</b></div>
                                                                <span>
                                                                    {!item.offer ? "" : <kbd>{item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</kbd>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="selected"
                                                onClick={this.dropdownMenuOpen}
                                                style={this.state.outletOffer < 1 && this.state.isModal ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                                            >
                                                {(this.state.dropMenu && this.state.selectValue.length) >= 1 || (!this.state.dropMenu && this.state.selectValue.length) >= 1 ? "" : "Select outlets"}
                                                {this.state.selectValue.map((m, i) => {
                                                    return <span className="badge bg-warning text-dark mx-1" key={i}>
                                                        {m.outlet_name}
                                                    </span>
                                                })}
                                            </div>
                                            <div
                                                className="search-box"
                                                style={this.state.dropMenu ? {} : { display: "none" }}
                                            >
                                                <input
                                                    onChange={(e) => this.handleSearch("outlet", e)}
                                                    id="myFoodOutlet"
                                                    type="search"
                                                    placeholder="Search ..."
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.productFieldToggle ?
                                        <div className="category-field">
                                            <div className="select-box">
                                                <div
                                                    className="options-container"
                                                    style={this.state.dropFoodMenu ? {} : { display: "none" }}>
                                                    {this.state.getProduct.map((item, i) => {
                                                        return (
                                                            <div
                                                                className="option"
                                                                key={item._id}
                                                                onClick={() => this.handleClickFoodOption(item)}
                                                            >

                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="radio"
                                                                            value={item._id}
                                                                            id={item._id}
                                                                            name="category" />
                                                                        <label className="ml-1">{item.product_name}</label>
                                                                    </div>
                                                                    <span>
                                                                        {!item.offer ? "" : <kbd>{item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</kbd>}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="selected"
                                                    onClick={this.dropdownFoodMenuOpen}
                                                    style={this.state.ProductOffer.length < 1 && this.state.isModal ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                                                >
                                                    {(this.state.dropFoodMenu && this.state.selectFoodValue.length) >= 1 || (!this.state.dropFoodMenu && this.state.selectFoodValue.length) >= 1 ? "" : "Select foods"}
                                                    {this.state.selectFoodValue.map((m, i) => {
                                                        return <span className="badge bg-warning text-dark mx-1" key={i}>
                                                            {m.product_name}
                                                            <BackspaceIcon
                                                                onClick={() => this.deletDropFoodSelectItem(m)}
                                                                style={{ fontSize: "14px", color: "red" }}
                                                            />
                                                        </span>
                                                    })}
                                                </div>
                                                <div
                                                    className="search-box"
                                                    style={this.state.dropFoodMenu ? {} : { display: "none" }}
                                                >
                                                    <input
                                                        onChange={(e) => this.handleSearch("product", e)}
                                                        id="myFood"
                                                        type="search"
                                                        placeholder="Search ..."
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                    }

                                    <div className="text-right my-3">
                                        <button
                                            onClick={() => this.submittedSetOutlet()}
                                            type="button"
                                            className="btn btn-verify"
                                            data-toggle="modal">Add For Food
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete offer for outlet and product modal */}
                <Modal
                    show={this.state.modalDetailsShow}
                    onHide={this.handleModalclose}
                    backdrop="static"
                    size="md"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalType === "outlet" ? "All Outlet Offers " : "All food offers"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modalType === "outlet" ?
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Outlet Name</th>
                                        <th>offer</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.allOutletOffers.map(item => {
                                            return (
                                                <tr key={item._id}>
                                                    <td>{item.outlet_name}</td>
                                                    <td>
                                                        {!item.offer ? "" : <kbd>{item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</kbd>}
                                                    </td>
                                                    <td>
                                                        <span
                                                            onClick={() => this.deleteOfferOutletAndFood(item._id)}
                                                        >
                                                            <ActionButton>
                                                                <i className="demo-icon icon-delete">&#xe80e;</i>
                                                            </ActionButton>
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div className="panel-group" id="accordion">
                                {this.state.allOutletOffers.map((item, i) => {
                                    return (
                                        <div className="panel panel-default" key={item._id}>
                                            <a data-toggle="collapse" data-target={`#collapse${i}`}
                                                href={`#collapse${i}`}>
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">
                                                        <span className="text-dark">{item.outlet_name}</span>
                                                    </h4>
                                                </div>
                                            </a>
                                            <div id={`collapse${i}`} data-parent="#accordion" className="panel-collapse collapse in">
                                                {item.foods !== 0 ?
                                                    <div className="panel-body">
                                                        <table className="table table-hover mt-1">
                                                            <thead>
                                                                <tr>
                                                                    <th>Food Name</th>
                                                                    <th>Offer</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.foods.map(m => {
                                                                    return (
                                                                        <tr key={m._id}>
                                                                            <td>{m.product_name}</td>
                                                                            <td>
                                                                                {!m.offer ? "" : <kbd>{m.offer.percentage ? `${m.offer.amount}%` : `₹${m.offer.amount}`}</kbd>}
                                                                            </td>
                                                                            <td>
                                                                                <span
                                                                                    onClick={() => this.deleteOfferOutletAndFood(m._id)}
                                                                                >
                                                                                    <ActionButton>
                                                                                        <i className="demo-icon icon-delete">&#xe80e;</i>
                                                                                    </ActionButton>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        }
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Discount);
import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import BackspaceIcon from '@material-ui/icons/Backspace';
import { getAllOutlet, mainProduct, allSearch, actionBookSlot } from '../../api/apiService';
import { ToastSuccess, ToastError } from "../toast-notification";
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
  return {
    loaderDisplay: store.loaderDisplay
  }
}

class SlotActiveTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: "",
      modalShow: false, // modal
      OutletAll: [],
      getProduct: [],
      selectOutlet: [],
      selectFoodItem: [],
      dropMenu: false,
      basePrice: '',
      quantity: '',
      dateId: '',
      slotId: '',
      errorMsg: '',
      dropFoodMenu: false,
      modalSubmitted: false
    }
    this.allOutlet(1);
  }


  /*
  * Modal
  * Show And Hide
  */
  handleModalShowHide = (dId, sId) => {
    this.setState({
      selectOutlet: [],
      selectFoodItem: [],
      dropMenu: false,
      basePrice: '',
      quantity: '',
      dateId: '',
      slotId: '',
      errorMsg: '',
      dropFoodMenu: false,
      modalSubmitted: false,
      productFieldToggle: false
    })
    const status = !this.state.modalShow;
    this.setState({ modalShow: status });
    if (status) {
      this.setState({ dateId: dId, slotId: sId });
    }
  }

  dropdownMenuOpen = () => {
    this.setState({ dropMenu: !this.state.dropMenu, dropFoodMenu: false })
  }

  handleClickOutletOption = (e) => {
    this.setState({ selectOutlet: [e], selectValue: [e.outlet_name], dropMenu: !this.state.dropMenu, selectFoodItem: [], productFieldToggle: true }, () => {
      this.allProduct(this.state.selectOutlet[0]._id);
    })
  }
  // all select product field -----------
  dropdownFoodMenuOpen = () => {
    this.setState({ dropFoodMenu: !this.state.dropFoodMenu, dropMenu: false })
  }
  handleClickFoodOption = (e) => {
    this.setState({ selectFoodItem: [e], dropFoodMenu: !this.state.dropFoodMenu, }, () => {
    })
  }
  handleSetQuantity = (e) => {
    this.setState({ quantity: e.target.value })
  }
  handleSetBasePrice = (e) => {
    this.setState({ basePrice: e.target.value })
  }

  /* 
  * Api
  * Get All Outlets
  */

  allOutlet = async (pageNo) => {
    try {
      const outlets = await getAllOutlet(pageNo);
      this.setState({ OutletAll: outlets.data.data })
    } catch (ex) {
      console.log(ex);
    }
  }
  /* 
  * Api
  * Get All products
  */
  allProduct = async (outletId) => {
    store.dispatch(loader(true));
    try {
      const product = await mainProduct(outletId);
      this.setState({ getProduct: product.data.data })
      store.dispatch(loader(false));
    } catch (ex) {
      console.log(ex);
      store.dispatch(loader(false));
    }
  }

  /* 
    * Api
    * Search 
    * Outlets & products
    */
  handleSearch = async (type, txt) => {
    let id = type === "product" ? this.state.selectOutlet[0]._id : undefined;
    try {
      const search = await allSearch(type, encodeURIComponent(txt.target.value), id);
      let dataValue = search.data.data;
      if (!dataValue) {
        if (type === "outlet") {
          this.allOutlet()
        } else {
          this.allProduct()
        }

      } else {
        if (type === "outlet") {
          this.setState({ OutletAll: search.data.data })
        } else {
          this.setState({ getProduct: search.data.data })
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  /* 
  * Api
  * Action 
  * Book Slot
  */
  handleBookSlot = async () => {
    const data = {
      "dateId": this.state.dateId._id,
      "slotId": this.state.slotId._id,
      "outletId": this.state.selectOutlet[0]._id,
      "productId": this.state.selectFoodItem[0]._id,
      "quantity": this.state.quantity,
      "basePrice": this.state.basePrice
    }
    try {
      const bookSlot = await actionBookSlot(data);
      this.setState({ modalShow: false });
      ToastSuccess(bookSlot.data.message)
    } catch (ex) {
      console.log(ex);
      this.setState({ errorMsg: ex.response.data.message })
    }
  }

  modalValid = () => {
    let checked = true;
    if (this.state.selectOutlet.length < 1) {
      return checked = false
    }
    if (this.state.productFieldToggle) {
      if (this.state.selectFoodItem.length < 1) {
        return checked = false
      }
    }
    if (this.state.quantity === "") {
      return checked = false
    }
    if (this.state.basePrice === "") {
      return checked = false
    }
    return checked;
  }

  submittedAddFood = () => {
    this.setState({ modalSubmitted: true })
    if (this.modalValid()) {
      this.handleBookSlot()
    }
  }


  // dateConvert(date, splitSym = '/') {
  //   var datearray = date.split(splitSym);
  //   let splitArr = datearray[0].split('-');
  //   let newdate = splitArr[0] + '-' + splitArr[1] + '-' + splitArr[2];
  //   return newdate;
  // }
  // checkStatus(slotDate) {
  //   let current_datetime = new Date()
  //   let formatted_date = current_datetime.getFullYear() + "-" + (("0" + (current_datetime.getMonth() + 1)).slice(-2)) + "-" + ("0" + current_datetime.getDate()).slice(-2);
  //   let cnvDate = this.dateConvert(slotDate, 'T');
  //   let arr_status = ["Active", "Expired", "Coming Soon"];
  //   let statusColor = ["#09a02c", "#F22338", "#e8a00c"];
  //   let finalArr = [];
  //   if (cnvDate === formatted_date) {
  //     finalArr['status'] = arr_status[0];
  //     finalArr['color'] = statusColor[0];
  //     return finalArr;
  //   } else if (cnvDate > formatted_date) {
  //     finalArr['status'] = arr_status[2];
  //     finalArr['color'] = statusColor[2];
  //     return finalArr;
  //   } else {
  //     finalArr['status'] = arr_status[1];
  //     finalArr['color'] = statusColor[1];
  //     return finalArr;
  //   }
  // }
  // // Api call ----
  // handleCopy = async (sDate) => {
  //   store.dispatch(loader(true));
  //   try {
  //     const slotCopy = await copySlot(sDate)
  //     console.log(slotCopy.data.data);
  //     store.dispatch(loader(false));
  //     ToastSuccess(slotCopy.data.message);
  //     this.props.myFunction();
  //   } catch (ex) {
  //     console.log(ex);
  //     store.dispatch(loader(false));
  //     ToastError(ex.data.message)
  //   }
  // }
  render() {
    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Slot date</th>
              <th>Slot</th>
              {/* <th className="text-center" style={{ width: "10%" }}>Status</th> */}
            </tr>
          </thead>
          <tbody>
            {this.props.ActData.sort((a, b) => a.slotDate > b.slotDate ? 1 : -1).map(item => {
              return <tr key={item._id}>
                <td><Moment format="DD-MM-yy">{item.slotDate}</Moment></td>

                <td>
                  <table style={{ minWidth: "100%", border: "1px solid !important",textAlign:"center" }}>
                    <thead>
                      <tr style={{ color: "GrayText" }}>
                        <th style={{ width: "40%" }}>Name</th>
                        <th style={{ width: "20%" }}>Start Time</th>
                        <th style={{ width: "20%" }}>End Time</th>
                        <th style={{ width: "20%" }}>Base Price</th>
                        <th style={{ width: "20%" }}>Public/Private<br/>(Code)</th>
                        <th style={{ width: "20%" }}>Status</th>
                        <th style={{ width: "20%" }}>Add Food</th>
                      </tr>
                    </thead>
                    <tbody style={{ color: "#000" }}>
                      {item.slots.sort((a, b) => a.slotStartTime > b.slotStartTime ? 1 : -1).map(m => {
                        const currentTime = moment().format('HH:mm');
                        const currentDate = moment().format('YYYY-DD-MM');
                        const SDate = moment(moment(item.slotDate).format('YYYY-DD-MM'));
                        const sTime = moment(moment(m.slotStartTime).format('HH:mm'));
                        const eTime = moment(moment(m.slotEndTime).format('HH:mm'));

                        let statusColor;
                        if (SDate._i === currentDate) {
                          statusColor = eTime._i >= currentTime && sTime._i <= currentTime ? "green" : sTime._i && eTime._i >= currentTime ? "#014F4B" : "red";
                        } else {
                          statusColor = "orange";
                        }

                        let status;
                        if (SDate._i === currentDate) {
                          status = eTime._i >= currentTime && sTime._i <= currentTime ? "Active" : sTime._i && eTime._i >= currentTime ? "Comming today" : "Deactive";
                        } else {
                          status = "Comming soon";
                        }

                        return (
                          <tr key={m._id}>
                            <td><b>{m.name}</b></td>
                            <td><b><Moment format="HH:mm" >{m.slotStartTime}</Moment></b></td>
                            <td><b><Moment format="HH:mm">{m.slotEndTime}</Moment></b></td>
                            <td><b>{m.basePrice}</b></td>
                            <td style={{ color: "blue" }}><b>{m.isPrivate ? <>Private<br /><span style={{ color: "#000" }}>({m.code})</span></> : ""}</b></td>
                            <td style={{ color: statusColor }}><b>{status}</b></td>
                            <td>
                              {status !== "Active" && status !== "Deactive" && (
                                <button className='btn btn-verify' onClick={() => this.handleModalShowHide(item, m)}>Add</button>
                              ) || <button className='btn btn-verify' style={{ userSelect: "none" }} disabled>Add</button>}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                </td>
                {/* <td className="text-center"><label onClick={() => this.handleCopy(item.slotDate)}><ActionButton><FileCopyIcon /></ActionButton></label>
                  <span className="badge badge-success" style={{ backgroundColor: this.checkStatus(item.slotDate)['color'] }}>{this.checkStatus(item.slotDate)['status']}</span>
                </td> */}
              </tr>
            })}
          </tbody>
        </table>
        {/* modal start */}
        <Modal
          show={this.state.modalShow}
          onHide={this.handleModalShowHide}
          backdrop="static"
          size="md"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Food</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className='error-msg'>{this.state.errorMsg}</p>
              <div className="category-field mb-2">
                <label className="font-12 m-0">Select Resturent</label>
                <div className="select-box">
                  <div
                    className="options-container"
                    style={this.state.dropMenu ? {} : { display: "none" }}>
                    {this.state.OutletAll.map((item, i) => {
                      return (
                        <div
                          className="option"
                          key={item._id}
                          onClick={() => this.handleClickOutletOption(item)}
                        >
                          <label>{item.outlet_name}</label><br />
                          <div className="d-flex justify-content-between">
                            <div className="para-shot font-12"><b>{item.address_details.address}</b></div>
                            <span>
                              {!item.offer ? "" : <kbd>{item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</kbd>}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="selected"
                    onClick={this.dropdownMenuOpen}
                    style={this.state.selectOutlet < 1 && this.state.modalSubmitted ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                  >
                    {(this.state.dropMenu && this.state.selectOutlet.length) >= 1 || (!this.state.dropMenu && this.state.selectOutlet.length) >= 1 ? "" : "Select outlets"}
                    {this.state.selectOutlet.map((m, i) => {
                      return <span className="badge bg-warning text-dark mx-1" key={i}>
                        {m.outlet_name}
                      </span>
                    })}
                  </div>
                  <div
                    className="search-box"
                    style={this.state.dropMenu ? {} : { display: "none" }}
                  >
                    <input
                      onChange={(e) => this.handleSearch("outlet", e)}
                      id="myFoodOutlet"
                      type="search"
                      placeholder="Search ..."
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              {this.state.productFieldToggle ?
                <>
                  <div className="category-field">
                    <label className="font-12 m-0">Select Food</label>
                    <div className="select-box">
                      <div
                        className="options-container"
                        style={this.state.dropFoodMenu ? {} : { display: "none" }}>
                        {this.state.getProduct.map((item, i) => {
                          return (
                            <div
                              className="option"
                              key={item._id}
                              onClick={() => this.handleClickFoodOption(item)}
                            >

                              <div className="d-flex justify-content-between">
                                <div>
                                  <img src={item.image ? item.image : "/assate/images/food-logo.png"} className="img-fluid table-img" alt="" />
                                  <label className="ml-1">{item.product_name}</label>
                                </div>
                                <span>
                                  {!item.offer ? "" : <kbd>{item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</kbd>}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="selected"
                        style={this.state.selectFoodItem < 1 && this.state.modalSubmitted ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                        onClick={this.dropdownFoodMenuOpen}
                      >
                        {(this.state.dropFoodMenu && this.state.selectFoodItem.length) >= 1 || (!this.state.dropFoodMenu && this.state.selectFoodItem.length) >= 1 ? "" : "Select foods"}
                        {this.state.selectFoodItem.map((m, i) => {
                          return <span className="badge bg-warning text-dark mx-1" key={i}>
                            {m.product_name}
                          </span>
                        })}
                      </div>
                      <div
                        className="search-box"
                        style={this.state.dropFoodMenu ? {} : { display: "none" }}
                      >
                        <input
                          onChange={(e) => this.handleSearch("product", e)}
                          id="myFood"
                          type="search"
                          placeholder="Search ..."
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <label className="font-12 m-0">Quantity</label>
                    <input
                      onChange={ev => this.handleSetQuantity(ev)}
                      style={this.state.quantity === "" && this.state.modalSubmitted ? { border: "1px solid red" } : {}}
                      type="number"
                      value={this.state.quantity}
                      className="form-control"
                      placeholder="Quantity" />
                  </div>
                  <div className="form-group m-0">
                    <label className="font-12 m-0">Base Price</label>
                    <input
                      onChange={ev => this.handleSetBasePrice(ev)}
                      style={this.state.basePrice === "" && this.state.modalSubmitted ? { border: "1px solid red" } : {}}
                      type="number"
                      value={this.state.basePrice}
                      className="form-control"
                      placeholder="Amount" />
                  </div>
                </>
                :
                <div></div>
              }
              <div className="text-right my-3">
                <button
                  onClick={() => this.submittedAddFood()}
                  type="button"
                  className="btn btn-verify"
                  data-toggle="modal">Add
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SlotActiveTable);

import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

class ActiveFoods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeData: this.props.data,
        }
    }
    render() {
        return (
            <div>
                <div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Auction Date</th>
                                <th>Auction Type</th>
                                <th>Slot name</th>
                                <th><span>Food</span></th>
                                <th>Outlet Name</th>
                                <th className="text-center">Auction Details</th>
                                <th className="text-center">No Of Winers</th>
                                <th className="text-center">Base Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.activeData.map(item => {
                                return <tr key={item._id} className="text-secondary">
                                    <td>
                                        {item.dateId.slots.map(m => {
                                            if (m._id === item.slotId) {
                                                return <Moment key={m._id} format="DD-MM-YYYY HH:mm">
                                                    {m.slotStartTime}
                                                </Moment>
                                            }
                                        })}
                                    </td>
                                    <td>
                                        <p className='m-0'>{item.isPrivate ? "Private" : "Public"}</p>
                                        {item.isPrivate ? item.code : ""}
                                    </td>
                                    <td>
                                        {item.dateId.slots.map(m => {
                                            if (m._id === item.slotId) {
                                                return m.name
                                            }
                                        })}
                                    </td>
                                    <td>
                                        <label className="d-flex m-0">
                                            <img src={item.productId.image ? item.productId.image : "/assate/images/food-logo.png"} className="img-fluid table-img mr-3" alt="" />
                                            <div>
                                                <Link to={`/outlets/add-on-product-table/${item.productId._id}`}>{item.productId.product_name}</Link>
                                                <p className="font-10 m-0">₹{item.productId.sell_price_without_tax}</p>
                                            </div>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="d-flex m-0">
                                            <img src={item.outletId.outlet_images ? item.outletId.outlet_images : "/assate/images/store-logo.png"} className="img-fluid table-img mr-3" alt="" />
                                            <div>
                                                <Link to={`/outlets/products/${item.outletId._id}`}>{item.outletId.outlet_name}</Link>
                                                <p className="font-10 m-0">{item.outletId.address_details.address}</p>
                                            </div>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <Link to={`/auction/auction-bid-details/${item._id}`}>Details</Link>
                                    </td>
                                    <td className="text-center">{item.noOfWiners}</td>
                                    <td className="text-center">{item.basePrice}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    {this.state.activeData.length < 1 ? <h3 className="text-center w-100">No data</h3> : ""}
                </div>
            </div>
        );
    }
}

export default ActiveFoods;
import React from 'react';
import ActionButton from '../action-button';
import { Modal } from "react-bootstrap";
import { ToastSuccess, ToastError } from '../toast-notification';
import ItemManagement from '../../outlet/outlet-container';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import {
  getSingleProduct,
  deleteAddon,
  addSingleAddon,
  updateAddon,
  addCustomization
} from '../../api/apiService';

class AddOnProduct extends React.Component {
  isSubmitted = false;
  constructor(props) {
    super(props);
    this.state = {
      newCust: false,
      newAddOnsName: "",
      isMultiSelectNew: false,
      addOnProduct: [],
      mainFood: [],
      countAddonItem: "",
      addonProductId: "",
      addOnPro: '',
      index: "",
      newAdOnDetails: "",
      newAdOnExtraPrice: "",
      modalComentary: false,
      edit: false,
      checked: null,
      ind: null,
      newAdOnDetailsError: "",
      newAdOnExtraPriceError: "",
      newAddOnsNameError: "",
    }
    this.allAddonItem();
  };

  handleParachange = (e, index, optionIndex) => {
    const { name, value, checked } = e.target;
    const { addOnProduct } = this.state;
    const updatedAddOnProduct = [...addOnProduct];

    if (optionIndex !== undefined) {
      updatedAddOnProduct[index].options[optionIndex][name] = value;
    } else {
      updatedAddOnProduct[index][name] = value;
      updatedAddOnProduct[index].isMultiSelect = checked?true:false;
    }

    this.setState({ addOnProduct: updatedAddOnProduct });
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };
  handleChangeNewAddOns = (event) => {
    this.setState({
      newAddOnsName: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }

  handleChangeNewAdOnDetails = (event) => {
    this.setState({
      newAdOnDetails: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeNewAdOnExtraPrice = (event) => {
    this.setState({
      newAdOnExtraPrice: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  valid = () => {
    let checked = true;
    this.setState({
      newAdOnDetailsError: "",
      newAdOnExtraPriceError: "",
      newAddOnsNameError: "",
    })
    if (this.state.newAdOnDetails.length < 1) {
      this.setState({ newAdOnDetailsError: "Please enter add-on name" });
      checked = false;
    }
    if (this.state.newAdOnExtraPrice.length < 1) {
      this.setState({ newAdOnExtraPriceError: "Please enter add-on price" });
      checked = false;
    }
    if (this.state.newAddOnsNameError.length < 1) {
      this.setState({ newAddOnsNameError: "Please enter add-ons Name" });
      checked = false;
    }
  }
  modalValid = () => {
    let checked = true;
    if (this.state.newAdOnDetails === "" && this.state.newAdOnExtraPrice == "") {
      checked = false
    }
    return checked
  }
  submittedAdOn = () => {
    this.setState({ isModal: true })
    if (this.modalValid()) {
      this.assignAdOn();
    }
  }
  assignAdOn = () => {
    const productId = this.props.match.params.productId;
    const index = this.state.index;
    let bodyData = {
      adOnDetails: this.state.newAdOnDetails,
      adOnExtraPrice: this.state.newAdOnExtraPrice
    };
    const isConfirmed = window.confirm("Are you sure you want to add the customization?");
    if (isConfirmed) {
      // console.log(productId,index,data);
      addSingleAddon(productId, index, bodyData)
        .then(response => {
          ToastSuccess(response.data.message);
          console.log('Addon added successfully', response.data);
          this.allAddonItem();
          // window.location.reload();
        })
        .catch(error => {
          ToastError('Error adding addon');
          console.error('Error adding addon:', error);
        });
    } else {
      console.log("Error adding customization");
    }
  }
  assignCust = () => {
    const productId = this.props.match.params.productId;
    let bodyData = {
      isMultiSelect: this.state.isMultiSelectNew,
      adOnName: this.state.newAddOnsName,
      options: []
    };
    const isConfirmed = window.confirm("Are you sure you want to add new customization?");
    if (isConfirmed) {
      console.log(productId, bodyData);
      addCustomization(productId, bodyData)
        .then(response => {
          ToastSuccess(response.data.message);
          console.log('Customization added successfully', response.data);
          this.allAddonItem();
          // window.location.reload();
        })
        .catch(error => {
          ToastError('Error adding Customization');
          console.error('Error adding Customization:', error);
        });

    } else {
      console.log("Error adding customization");
    }
  }
  //  Api call-------
  allAddonItem = () => {
    let self = this;
    getSingleProduct(this.props.match.params.productId).then(function (response) {
      self.setState({
        addOnProduct: response.data.data.addons,
        mainFood: response.data.data,
        countAddonItem: response.data.data.addons.length
      })
    })
      .catch(function (error) {
        console.log(error);
      })
  };

  handleUpdateAddon = (productId, index) => {
    const isConfirmed = window.confirm("Are you sure you want to update addons?");
    const addOn = this.state.addOnProduct[index];
    const bodyData = {
      adOnName: addOn.adOnName,
      isMultiSelect: addOn.isMultiSelect,
      options: addOn.options.map(option => ({
        adOnDetails: option.adOnDetails,
        adOnExtraPrice: option.adOnExtraPrice
      }))
    };
    if (isConfirmed) {
      console.log("bodyData: ", bodyData);
      updateAddon(productId, index, bodyData)
        .then(response => {
          ToastSuccess(response.data.message);
          console.log('Addon updated successfully', response.data);
          // this.allAddonItem();
          window.location.reload();
        })
        .catch(error => {
          ToastError('Error updating addon');
          console.error('Error updating addon:', error);
        });
    } else {
      console.log('Addon deletion cancelled.');
    }
  };


  handleDeleteAddon = (productId, index, addonProductId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this addon?");
    if (isConfirmed) {
      deleteAddon(productId, index, addonProductId)
        .then(response => {
          ToastSuccess(response.data.message);
          console.log('Addon deleted successfully', response.data);
          this.allAddonItem();
        })
        .catch(error => {
          ToastError('Error deleting addon');
          console.error('Error deleting addon:', error);
        });
    } else {
      console.log('Addon deletion cancelled.');
    }
  };


  render() {
    return (
      <>
        <ItemManagement demoPathName={"/outlets"} outletPathName={"Outlets"} fontPathName={"Product"}>
          <div>
            <div className="row">
              <div className="col-sm-6">
                <div className="user-details-container my-4 d-flex justify-content-between align-content-end">
                  <div className="d-flex w-75">
                    <div className="food-img-sec">
                      <img src={this.state.mainFood.image ? this.state.mainFood.image : "/assate/images/food-logo.png"} className="addon-product-table-img" alt="" />
                    </div>
                    <div>
                      <h4>{this.state.mainFood.product_name}</h4>
                      <div>
                        <p className="m-0 sub-title"><b>Price without tax : </b> {this.state.mainFood.sell_price_without_tax}</p>
                        <p className="description">{this.state.mainFood.description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <h1>{this.state.countAddonItem}</h1>
                    <div className="sub-title">Add-on items</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                {this.state.newCust ? (<>
                  <div className="user-details-container my-4 d-flex justify-content-between align-content-end">
                    <div className="col-12">
                      <div style={{ display: "flex", minWidth: "100%", justifyContent: "space-between" }}>
                        <span>New Add-on</span>
                        <span onClick={() => this.setState({ newCust: false })}>
                          <span class="material-icons" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323" cursor="pointer">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            </svg>
                          </span>
                        </span>
                      </div>
                      <div className="form-group m-0">
                        <input
                          onChange={ev => this.handleChangeNewAddOns(ev)}
                          type="text"
                          className="form-control"
                          style={this.state.newAddOnsNameError !== "" ? { border: "1px solid red" } : {}}
                          placeholder="Enter new add-on name"
                        />
                      </div>
                      <p className="error-msg mb-3">{this.state.newAddOnsNameError}</p>
                      <div style={{ display: "flex", minWidth: "100%", justifyContent: "space-between" }}>
                        <span className="form-check">
                          <Checkbox
                            className="p-0 ml-1"
                            id="multiSelectCheckbox"
                            onChange={() => this.setState({ isMultiSelectNew: !this.state.isMultiSelectNew })}
                          />
                          <label className="form-check-label" htmlFor="multiSelectCheckbox">  Multiple Select</label>
                        </span>
                        {/* <span className="text-right my-3"> */}
                          <button
                            onClick={() => this.assignCust()}
                            type="button"
                            className="btn btn-verify"
                            data-toggle="modal"
                            disabled={this.state.newAddOnsName.length===0}>+Add
                          </button>
                        {/* </span> */}
                      </div>
                    </div>
                  </div>
                </>) : (<>
                  <div className="text-right my-3">
                    <button
                      onClick={() => this.setState({ newCust: true })}
                      type="button"
                      className="btn btn-verify"
                      data-toggle="modal">+ New AddOn
                    </button>
                  </div>
                </>)}
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.addOnProduct.map((item, index) => {
              return <div key={item._id} className="col-md-4">
                <div className="category-container my-4">
                  <div className="d-flex justify-content-between">
                    <input type="text" value={this.state.ind === index && this.state.edit ? null : item.adOnName}
                      className={this.state.ind === index && this.state.edit ? "edit-field-list" : "normal-field-list text-success"}
                      disabled={this.state.ind === index && this.state.edit ? false : true}
                      onChange={(e) => this.handleParachange(e, index)}
                      name="adOnName"
                    />
                    {this.state.ind === index && this.state.edit ? (
                      <></>
                    ) : (<>
                      <span
                        onClick={() => this.setState({ modalComentary: true, index, addOnPro: item.adOnName })}
                      >
                        <ActionButton>
                          <i className="demo-icon icon-add">&#xe802;</i>
                        </ActionButton>
                      </span>
                    </>)}
                    <span className="user-table-auction-item">Multiple
                      <Checkbox
                        className="p-0 ml-1"
                        checked={this.state.ind === index && this.state.edit ? item.isMultiSelect : item.isMultiSelect}
                        // checked={this.state.ind === index && this.state.edit ? this.state.checked : item.isMultiSelect}
                        disabled={this.state.ind === index && this.state.edit ? false : true}
                        // disabled={this.state.ind === index && this.state.edit ? true : true}
                        onChange={(e) => this.handleParachange(e, index)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </span>
                    <div onClick={() => this.setState({ edit: !this.state.edit, ind: index })} >
                      <ActionButton>
                        <i className={`demo-icon ${this.state.ind === index && this.state.edit ? "text-color-primary " : ""}icon-edit`}>&#xe80f;</i>
                      </ActionButton>
                    </div>
                  </div>
                  <hr style={{}} />
                  <ul className="add-on-list">
                    {item.options.map((i, optionIndex) => {
                      return <li key={i._id}>
                        <input type="text" value={this.state.ind === index && this.state.edit ? null : i.adOnDetails}
                          className={this.state.ind === index && this.state.edit ? "edit-field-list" : "normal-field-list"}
                          disabled={this.state.ind === index && this.state.edit ? false : true}
                          onChange={(e) => this.handleParachange(e, index, optionIndex)}
                          name="adOnDetails"
                        />
                        <p className="d-flex align-items-center">
                          Price:
                          <input type="text" value={this.state.ind === index && this.state.edit ? null : i.adOnExtraPrice}
                            className={this.state.ind === index && this.state.edit ? "edit-field-list w-100 ml-1" : "normal-field-list"}
                            style={{ width: "inherit" }}
                            disabled={this.state.ind === index && this.state.edit ? false : true}
                            onChange={(e) => this.handleParachange(e, index, optionIndex)}
                            name="adOnExtraPrice"
                          />
                        </p>
                        {this.state.ind === index && this.state.edit ?
                          <div></div>
                          :
                          <div onClick={() => this.handleDeleteAddon(this.props.match.params.productId, index, i._id)}>
                            <ActionButton>
                              <i className="demo-icon icon-delete text-danger">&#xe80e;</i>
                            </ActionButton>
                          </div>
                        }
                      </li>
                    })}
                  </ul>
                  <div className="text-right" onClick={() => this.handleUpdateAddon(this.props.match.params.productId, index)}>
                    <button type="button" className="btn btn-verify" style={this.state.ind === index && this.state.edit ? { display: "initial" } : { display: "none" }}>update</button>
                  </div>
                </div>
              </div>
            })}
          </div>
          <Modal
            show={this.state.modalComentary}
            onHide={() => this.setState({ modalComentary: false, index: "", addOnPro: "" })}
            backdrop="static"
            size="md"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Customization for {this.state.addOnPro}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-12">
                <label>Name:</label>
                <div className="form-group m-0">
                  <input
                    onChange={ev => this.handleChangeNewAdOnDetails(ev)}
                    type="text"
                    className="form-control"
                    style={this.state.newAdOnDetailsError !== "" ? { border: "1px solid red" } : {}}
                    placeholder="Enter add-on name" />
                </div>
                <p className="error-msg mb-3">{this.state.newAdOnDetailsError}</p>
              </div>
              <div className="col-12">
                <label>Price:</label>
                <div className="form-group m-0">
                  <input
                    onChange={ev => this.handleChangeNewAdOnExtraPrice(ev)}
                    type="number"
                    className="form-control"
                    style={this.state.newAdOnExtraPriceError !== "" ? { border: "1px solid red" } : {}}
                    placeholder="Enter add-on price" />
                </div>
                <p className="error-msg mb-3">{this.state.newAdOnExtraPriceError}</p>
              </div>
              <div className="text-right my-3">
                <button
                  onClick={() => this.submittedAdOn()}
                  type="button"
                  className="btn btn-verify"
                  data-toggle="modal">+Add
                </button>
              </div>
            </Modal.Body>

          </Modal>
        </ItemManagement>
      </>
    )
  }
}
export default AddOnProduct;

import React, { Component } from 'react';
import AuthContainer from '../common/auth-container';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import { login, getProfileDetils } from '../api/apiService';


class Login extends Component {
  isSubmitted = false;
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      mobileError: "",
      errorMessage: "",
    }
    const token = localStorage.getItem('token');
    if (token) {
      this.profileDetails();
    }
  };
  handleChangeMobile = (event) => {
    this.setState({
      mobile: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  };
  // validation check.......
  valid() {
    let isChecked = true;
    this.setState({
      mobileError: "",
    });
    if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.state.mobile)) {
      this.setState({
        mobileError: 'Invalid mobile no',
      });
      isChecked = false;
    }
    return isChecked;
  };
  profileDetails = () => {
    let self = this;
    getProfileDetils().then(function (response) {
      self.props.history.push(`/dashboard`);
    })
      .catch(function (error) {

      });
  };
  // api call
  login = (e) => {
    let self = this;
    login(this.state.mobile)
      .then(function (response) {
        ToastSuccess(response.data.message);
        self.props.history.push(`/otp-verify/${response.data.data.key}`)
      })
      .catch(function (error) {
        ToastError(error.response.data.message);
        self.setState({ errorMessage: error.response.data.message })

      });
  };
  // From submitted----
  submit(e) {
    e.preventDefault();
    this.isSubmitted = true;
    if (this.valid()) {
      this.login();
    }
  }
  render() {
    return (
      <AuthContainer>
        <form onSubmit={(e)=>this.submit(e)}>
          <p className="error-msg">{this.state.errorMessage}</p>
          <input
            type="text"
            className="user-input"
            placeholder="Enter your mobile"
            onChange={ev => this.handleChangeMobile(ev)}
            style={this.state.mobileError !== "" ? { border: "1px solid red" } : {}}
            autoFocus
          />
          <p className="error-msg">{this.state.mobileError}</p>
          <button
            style={/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.state.mobile) ? { backgroundColor: "#D2A33B", color: "#FFFFFF" } : {}}
            className="user-login-btn bg-btn-color"
            type="submit"
          >Submit
          </button>
        </form>
      </AuthContainer>
    );
  }
}
export default Login;
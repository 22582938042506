import React from 'react';
import ItemManagement from '../../outlet/outlet-container';
import OutletResturentDetails from './outlet-resturent-details';
import FoodTabs from './food-tab';
import { ToastSuccess, ToastError } from "../toast-notification";
import {
  mainProduct,
  productActiveStatus,
  productDeleteStatus
} from '../../api/apiService';

import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';
const mapStateToProps = (store) => {
  return {
    loaderDisplay: store.loaderDisplay
  }
}
class OutletItemTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allProductList: [],
      countPage: [],
      pageNo: 1
    }
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);
    this.outletMainProdect(this.state.pageNo);
  }
  outletId = this.props.match.params.outletId;
  handleFoodPage = (m) => {
    this.outletMainProdect(m.selected + 1);
    this.setState({ pageNo: m.selected + 1 })
  }

  rerenderParentCallback(e) {
    this.outletMainProdect(this.state.pageNo);
  }
  // Api call-------

  handleFoodStatus = (productId) => {
    let self = this;
    productActiveStatus(productId).then(function (resStatus) {
      ToastSuccess(resStatus.data.message);
      self.outletMainProdect(self.state.pageNo);
    })
      .catch(function (error) {
        console.log(error);
      })
  }
  handleDeleteStatus = (productId) => {
    let self = this;
    productDeleteStatus(productId).then(function (resStatus) {
      ToastSuccess(resStatus.data.message);
      self.outletMainProdect(self.state.pageNo);
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  outletMainProdect = (page) => {
    let self = this;
    store.dispatch(loader(true))
    mainProduct(`${this.outletId}?pageNo=${page}`).then(function (response) {
      self.setState({
        allProductList: response.data.data,
        countPage: response.data.pageInfo
        // pageCount: response.data.pageInfo.maxPageNo
      })
      store.dispatch(loader(false))
    })
      .catch(function (error) {
        console.log(error);
      })
  }
  render() {
    return (
      <>
        <ItemManagement demoPathName={"/outlets"} outletPathName={"Outlet / Resturent"} fontPathName={"Outlet item"}>
          <OutletResturentDetails data={this.outletId} />
          <FoodTabs
            foodItem={this.state.allProductList}
            pagInfo={this.state.countPage}
            data={this.outletId}
            foodPageFunc={this.handleFoodPage}
            foodStatus={this.handleFoodStatus}
            deleteStatus={this.handleDeleteStatus}
            rerenderParentCallback={this.rerenderParentCallback}
          />
        </ItemManagement>
      </>
    )
  }
}
export default connect(mapStateToProps)(OutletItemTable);
export const loader = (isShow) =>{
    return{
        type: "LOADER",
        loaderDisplay: isShow
    };
};
export const socketId = (id) =>{
    return{
        type: "SOCKETID",
        socketId: id
    };
};


import React, { Component } from 'react';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import { Multiselect } from 'multiselect-react-dropdown';
import {
    addProduct,
    getCategory,
    getSingleProduct,
    productUpdate,
    addProductImg,
    uploadImage
} from '../api/apiService';

class productInfo extends Component {
    isUpdate = false;
    isSubmitted = false;
    constructor(props) {
        super(props);
        this.state = {
            productName: "",
            productNameError: "",
            category: "0",
            categoryError: "",
            subCategory: "",
            subCategoryError: "",
            localName: "",
            localNameError: "",
            sellPrice: "",
            sellPriceError: "",
            tex: "",
            // texError: "",
            productionTime: "",
            productionTimeError: "",
            foodHabit: "",
            foodHabitError: "",
            description: "",
            maxOrderQyt: "",
            maxOrderQytError: "",
            packageCharge: "",
            packageChargeError: "",
            image: null,
            rowImage: null,
            imageError: "",
            options: [],
            subCategoryList: [],
            nameOfCategory: ""
        }
        if (this.props.match.params.productId) {
            this.isUpdate = true;
            this.singleProduct(this.props.match.params.productId);
        } else {
            this.isUpdate = false;
        }
        this.allCategory();
    }
    handleChangeProductName = (e) => {
        this.setState({
            productName: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeCategory = (e) => {
        if (e.target.value === '0') {
            console.log(e.target.value);
            this.setState({ subCategoryList: [] })
        } else {
            this.state.options.map(item => {
                if (item._id === e.target.value) {
                    this.setState({ subCategoryList: item.sub_category });
                }
                return true;
            });
        }
        this.setState({
            category: e.target.value,
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeSubCategory = (value) => {
        this.setState({
            subCategory: value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeLocalName = (e) => {
        this.setState({
            localName: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeSell = (e) => {
        this.setState({
            sellPrice: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeTex = (e) => {
        this.setState({
            tex: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeProductionTime = (e) => {
        this.setState({
            productionTime: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeFoodHabit = (e) => {
        this.setState({
            foodHabit: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleProductDescription = (e) => {
        this.setState({
            description: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handleMaxOrderQyt = (e) => {
        this.setState({
            maxOrderQyt: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    handlePackageCharge = (e) => {
        this.setState({
            packageCharge: e.target.value
        }, () => {
            return this.isSubmitted ? this.valid() : false;
        });
    }
    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                rowImage: img
            }, () => {
                return this.isSubmitted ? this.valid() : false;
            })
        }
    };
    //  validation /// 
    valid() {
        this.setState({
            productNameError: "",
            categoryError: "",
            subCategoryError: "",
            localNameError: "",
            sellPriceError: "",
            // texError: "",
            productionTimeError: "",
            foodHabitError: "",
            maxOrderQytError: "",
            packageChargeError: "",
            imageError: "",
        });
        let checked = true;
        if (this.state.productName.length < 3) {
            this.setState({ productNameError: "Please enter product name" });
            checked = false;
        }
        if (this.state.category === "0") {
            this.setState({ categoryError: "Please enter category" });
            checked = false;
        }
        if (this.state.subCategory < 3) {
            this.setState({ subCategoryError: "Please enter sub-category" });
            checked = false;
        }
        if (this.state.localName.length < 3) {
            this.setState({ localNameError: "Please enter local name" });
            checked = false;
        }
        if (this.state.sellPrice === "") {
            this.setState({ sellPriceError: "Please enter sell price" });
            checked = false;
        }
        // if (this.state.tex === "") {
        //     this.setState({ texError: "Please enter Tex" });
        //     checked = false;
        // }
        if (this.state.productionTime.length < 1) {
            this.setState({ productionTimeError: "Please enter production time" });
            checked = false;
        }
        if (this.state.foodHabit.length < 1) {
            this.setState({ foodHabitError: "Please enter food habit" });
            checked = false;
        }
        if (this.state.maxOrderQyt === "") {
            this.setState({ maxOrderQytError: "Please enter max-quantity" });
            checked = false;
        }
        if (this.state.packageCharge === "") {
            this.setState({ packageChargeError: "Enter this packaging charge" });
            checked = false;
        }
        if (this.state.image === null) {
            this.setState({ imageError: "Please your product image" });
            checked = false;
        }
        return checked;
    }
    //   Api Call ----
    prodauctImage = (productId) => {
        let self = this;
        if (this.state.rowImage) {
            uploadImage(this.state.rowImage, "products").then(function (response) {
                const data = {
                    "image": response.data.data.location
                }
                addProductImg(productId, data).then(function (response) {
                    console.log(response);
                    self.props.history.goBack()
                }).catch(function (error) {
                    console.log(error);
                    self.props.history.goBack()
                })
            })
                .catch(function (error) {
                    console.log(error);
                })
        } else {
            self.props.history.goBack()
        }

    }
    allCategory = () => {
        let self = this;
        getCategory().then(function (response) {
            self.setState({ options: response.data.data.main_category });
        })
            .catch(function (error) {
                console.log(error);
            });
    }
    singleProduct = (productId) => {
        let self = this;
        getSingleProduct(productId).then(function (response) {
            console.log(response.data.data);
            let itemData = response.data.data;
            self.setState({
                productName: itemData.product_name,
                subCategory: itemData.sub_category,
                category: itemData.category._id,
                nameOfCategory: itemData.category.category_name,
                localName: itemData.local_name,
                sellPrice: itemData.sell_price_without_tax,
                tex: itemData.tax,
                productionTime: itemData.production_time,
                foodHabit: itemData.habit,
                description: itemData.description,
                maxOrderQyt: itemData.maximum_order_quantity,
                packageCharge: itemData.packaging_charges,
                image: itemData.image,
            })
        })
            .catch(function (error) {
                console.log(error);
            });
    }
    newProductAdd = () => {
        let self = this;
        const data = {
            "product_name": this.state.productName,
            "description": this.state.description,
            "category": this.state.category,
            "sub_category": this.state.subCategory,
            "local_name": this.state.localName,
            "sell_price_without_tax": this.state.sellPrice,
            "tax": this.state.tex,
            "production_time": this.state.productionTime,
            "habit": this.state.foodHabit,
            "maximum_order_quantity": this.state.maxOrderQyt,
            "packaging_charges": this.state.packageCharge
        }
        if (this.isUpdate) {
            productUpdate(this.props.match.params.productId, data).then(function (response) {
                ToastSuccess(response.data.message);
                self.prodauctImage(self.props.match.params.productId);

            })
                .catch(function (error) {
                    console.log(error);
                    ToastError(error.response.data.message);
                })
        } else {
            addProduct(self.props.match.params.outletId, data).then(function (response) {
                ToastSuccess(response.data.message);
                self.prodauctImage(response.data.data._id);
            })
                .catch(function (error) {
                    ToastError(error.response.data.message);
                    console.log(error);
                });
        }
    }
    //     button submitted  --
    submited = () => {
        console.log(this.state.category);
        this.isSubmitted = true;
        this.setState({
            productNameError: "",
            categoryError: "",
            subCategoryError: "",
            localNameError: "",
            sellPriceError: "",
            // texError: "",
            productionTimeError: "",
            foodHabitError: "",
            maxOrderQytError: "",
            packageChargeError: "",
            imageError: "",
        });
        if (this.valid()) {
            this.newProductAdd();


        }
    }
    render() {
        return (
            <>
                <div className="my-3">
                    <button onClick={() => this.submited()} type="button" className="btn btn-verify">{this.isUpdate ? "Upload" : "Add"}</button>
                    <button type="button" onClick={() => this.props.history.goBack()} className="btn btn-cancel">Cancel</button>
                </div>
                <div className="container-fluid add-user-container ">
                    <h5 className='mt-4 mb-3'>Product Information</h5>
                    <div className="row">
                        <div className="col-sm-3 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Product name</label>
                                <input onChange={(e) => this.handleChangeProductName(e)}
                                    style={this.state.productNameError !== "" ? { border: "1px solid red" } : {}}
                                    type="text" value={this.state.productName} className="form-control" placeholder="Product Name" />
                            </div>
                            <p className="error-msg mb-3">{this.state.productNameError}</p>
                        </div>
                        <div className="col-sm-3 col-12">
                            <label className="font-12 m-0">Category</label>
                            <select onChange={(e) => this.handleChangeCategory(e)}
                                style={this.state.categoryError !== "" ? { border: "1px solid red" } : {}}
                                className="form-control" >
                                <option value="0">{this.state.nameOfCategory === "" ? "Select category" : this.state.nameOfCategory}</option>
                                {this.state.options.map(item => {
                                    return <option key={item._id} value={item._id}>{item.category_name}</option>
                                })}
                            </select>
                            <p className="error-msg mb-3">{this.state.categoryError}</p>
                        </div>
                        <div className="col-sm-3 col-12">
                            <label className="font-12 m-0">Sub category</label>
                            <div style={this.state.subCategoryError !== "" ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}>
                                <Multiselect
                                    onSelect={this.handleChangeSubCategory}
                                    options={this.state.subCategoryList}
                                    isObject={false}
                                    placeholder="Select Item"
                                    showCheckbox={true}
                                    selectedValues={this.state.subCategory}
                                />
                            </div>
                            <p className="error-msg mb-3">{this.state.subCategoryError}</p>
                        </div>
                        <div className="col-sm-3 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Local name</label>
                                <input
                                    onChange={(e) => this.handleChangeLocalName(e)}
                                    style={this.state.localNameError !== "" ? { border: "1px solid red" } : {}}
                                    type="text" value={this.state.localName} className="form-control" placeholder="Local Name" />
                            </div>
                            <p className="error-msg mb-3">{this.state.localNameError}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Sell price</label>
                                <input onChange={(e) => this.handleChangeSell(e)}
                                    style={this.state.sellPriceError !== "" ? { border: "1px solid red" } : {}}
                                    type="number" value={this.state.sellPrice} className="form-control" placeholder="Sell Price Without tax per Pc" />
                            </div>
                            <p className="error-msg mb-3">{this.state.sellPriceError}</p>
                        </div>
                        <div className="col-sm-3 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Tax</label>
                                <input onChange={(e) => this.handleChangeTex(e)}
                                    // style={this.state.texError !== "" ? { border: "1px solid red" } : {}}
                                    type="number" value={this.state.tex} className="form-control" placeholder="Tax" />
                            </div>
                            {/* <p className="error-msg mb-3">{this.state.texError}</p> */}
                        </div>
                        <div className="col-sm-3 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Production time</label>
                                <input onChange={(e) => this.handleChangeProductionTime(e)}
                                    style={this.state.productionTimeError !== "" ? { border: "1px solid red" } : {}}
                                    type="number" value={this.state.productionTime} className="form-control" placeholder="Production time (Min)" />
                            </div>
                            <p className="error-msg mb-3">{this.state.productionTimeError}</p>
                        </div>
                        <div className="col-sm-3 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Religion/ Habit</label>
                                <select onChange={(e) => this.handleChangeFoodHabit(e)}
                                    style={this.state.foodHabitError !== "" ? { border: "1px solid red" } : {}}
                                    className="form-control">
                                    <option>{this.state.foodHabit === "" ? "Religion/ Habit" : this.state.foodHabit}</option>
                                    <option>Non-Vegetarian</option>
                                    <option>Vegetarian</option>
                                    <option>Eggetarian</option>
                                    <option>Vegan</option>
                                </select>
                            </div>
                            <p className="error-msg mb-3">{this.state.foodHabitError}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Max order quantity</label>
                                <input onChange={(e) => this.handleMaxOrderQyt(e)}
                                    style={this.state.maxOrderQytError !== "" ? { border: "1px solid red" } : {}}
                                    type="number" value={this.state.maxOrderQyt} className="form-control" placeholder="Max-order quantity" />
                            </div>
                            <p className="error-msg mb-3">{this.state.maxOrderQytError}</p>
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Packaging charges</label>
                                <input onChange={(e) => this.handlePackageCharge(e)}
                                    style={this.state.packageChargeError !== "" ? { border: "1px solid red" } : {}}
                                    type="number" value={this.state.packageCharge} className="form-control" placeholder="Packaging charges" />
                            </div>
                            <p className="error-msg mb-3">{this.state.packageChargeError}</p>
                        </div>
                        <div className="col-sm-6 col-12">
                            <div className="form-group m-0">
                                <label className="font-12 m-0">Product discription</label>
                                <textarea onChange={(ev) => this.handleProductDescription(ev)}
                                    className="form-control product-info-textarea" name="w3review" rows="5" placeholder="Product discription" value={this.state.description} />
                            </div>
                        </div>
                        <div className="col-sm-3 col-12">
                            <div className="new-outlet-image-container">
                                <div>
                                    <label>Product image</label>
                                    <label className="upload-image-container cursor-pointer" htmlFor="file3">
                                        <img src={this.state.image} alt=""
                                            style={this.state.imageError !== "" ? { border: "1px solid red" } : {}}
                                            className="upload-image" />
                                        <i style={this.state.image != null ? { color: "transparent" } : {}}
                                            className="demo-icon icon-camera">&#xe807;</i>
                                        <input type="file" id="file3" accept="image/*" onChange={(e) => this.onImageChange(e)} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default productInfo;
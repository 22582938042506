import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AllOrderTable from './all-order-table';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={6}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
export default function AllOrderDetailsTab(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [searchTxt, setSearchTxt] = React.useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    /*
    * Orders Search text
    */
    const handleOrderSearch = async (e) => {
        setSearchTxt(e.target.value)
    };

    return (
        <div className="category-container mt-4">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        aria-label="full width tabs example"
                    >
                        <Tab label="All Orders" {...a11yProps(0)} />
                        <Tab label="Submitted" {...a11yProps(1)} />
                        <Tab label="Preparing" {...a11yProps(2)} />
                        <Tab label="Ready For Pickup" {...a11yProps(3)} />
                        <Tab label="Out For Delivery" {...a11yProps(4)} />
                        <Tab label="Completed" {...a11yProps(5)} />
                        <Tab label="Outlet Canceled" {...a11yProps(6)} />
                    </Tabs>
                </AppBar>
                <div className="table-search">
                    <div className="search-container">
                        <input onChange={(e) => handleOrderSearch(e)}
                            type="search"
                            placeholder="Search.." 
                            ame="search"
                            autoFocus
                        />

                    </div>
                </div>
            </div>
            <div>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <AllOrderTable
                            status="all"
                            orderCunt={props.orderCunt}
                            perPage={20}
                            searchTxt = {searchTxt}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <AllOrderTable
                            status="submitted"
                            orderCunt={props.orderCunt}
                            perPage={20}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <AllOrderTable
                            status="preparing"
                            orderCunt={props.orderCunt}
                            perPage={20}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <AllOrderTable
                            status="ready-for-pickup"
                            orderCunt={props.orderCunt}
                            perPage={20}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4} dir={theme.direction}>
                        <AllOrderTable
                            status="out-for-delivery"
                            orderCunt={props.orderCunt}
                            perPage={20}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={5} dir={theme.direction}>
                        <AllOrderTable
                            status="completed"
                            orderCunt={props.orderCunt}
                            perPage={20}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={6} dir={theme.direction}>
                        <AllOrderTable
                            status="outlet-canceled"
                            orderCunt={props.orderCunt}
                            perPage={20}
                        />
                    </TabPanel>
                </SwipeableViews>

            </div>
        </div>
    );
}

import React, { Component } from 'react';
import { getOutletOrderStatus } from '../../api/apiService';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { CSVLink } from "react-csv";

import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}


class OutletOrderListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ouletAllOrders: props.ouletAllOrders,
            pageCount: props.pageCount,
            handleOrderPageNo: props.handleOrderPageNo,
            // outletId:props.outletId
        }
        console.log(this.props)
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.ouletAllOrders !== prevProps.ouletAllOrders) {
            this.setState({ ouletAllOrders: this.props.ouletAllOrders });
        }
        if (this.props.pageCount !== prevProps.pageCount) {
            this.setState({ pageCount: this.props.pageCount });
        }
        if (this.props.handleOrderPageNo !== prevProps.handleOrderPageNo) {
            this.setState({ handleOrderPageNo: this.props.handleOrderPageNo });
        }
    }

    render() {
        return (
            <div>
                {this.state.ouletAllOrders.length < 1 ? <h2 className="text-center">No orders found</h2>
                    :
                    <div>
                        <table className="table dash-table table-hover text-secondary">
                            <thead>
                                <tr className="text-left">
                                    <th>Date/Amount</th>
                                    <th>Order Id/Type</th>
                                    <th>User</th>
                                    <th>Outlet</th>
                                    <th>Payment Mode</th>
                                    <th>Payment Status</th>
                                    <th>Order Status</th>
                                    <th className="text-center">Delivery Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.ouletAllOrders.map(item => {
                                    return <tr key={item._id} className="text-left">
                                    <td>
                                        <Moment format="DD-MM-YY HH:mm">
                                            {item.createdDate}
                                        </Moment>
                                        <br />
                                       <span className="font-12"> Amount <span className="font-12 badge badge-info">₹{item.orderAmount}</span></span>

                                    </td>
                                    <td>
                                        <Link to={`/orders/user-order-ditails/${item.orderId}`}>{item.orderId}</Link><br />
                                        <span className={item.orderType === 0 ? "text-success" : "text-warning"}>{item.orderType === 0 ? "Normal" : "Auction"}</span>

                                    </td>
                                    <td><Link to={`/users-management/user-details/${item.userId}`}>{item.user.full_name}</Link></td>
                                    <td><Link style={{ fontSize: "15px" }} to={`/outlets/products/${item.outletId}`}>{item.outlet.outlet_name}</Link>
                                    <br/>
                                    <span className="font-12">{item.pickUpAddress.landmark}</span>
                                    </td>
                                    <td>{item.paymentMode}</td>
                                    <td>
                                        <span className={`badge ${item.paymentStatus === "SUCCESS" ? "badge-success" : "badge-danger"}`}>{item.paymentStatus}</span>
                                    </td>
                                    <td>
                                        <span className={`badge ${item.orderStatus === "completed" ? "badge-success" : item.orderStatus === "preparing" ?
                                            "badge-primary" : item.orderStatus === "ready-for-pickup" ?
                                            "badge-info": item.orderStatus === "out-for-delivery" ?
                                                "badge-secondary" : item.orderStatus === "submitted" ?
                                                    "badge-warning" : "badge-danger"}`}>
                                            {item.orderStatus}
                                        </span>
                                    </td>
                                    <td className="text-center">{item.deliveryType === "take_away" ? "Take away" : item.deliveryStatus}
                                    <br/>
                                    <span className="font-12">{item.deliveryPatner}</span>
                                    </td>
                                </tr>
                                })}
                            </tbody>
                        </table>

                        <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            pageCount={this.state.pageCount.maxPageNo}
                            onPageChange={this.state.handleOrderPageNo}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />

                    </div>
                    
                }

            </div>
        );
    }
}

export default connect(mapStateToProps)(OutletOrderListTable);
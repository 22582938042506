import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import ActionButton from '../action-button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ToastSuccess, ToastError } from "../toast-notification";
import {
    getComplementaries,
    createComplementaries,
    UpdateComplementaries,
    deleteComplementaries
} from '../../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

class Complimentary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isUpdate: false,
            allComplimentary: [],
            complimentName: "",
            complimentPrice: "",
            complimentId: ""
        }
        this.getAllComplimentary();
    }
    handleComplimentName = (e) => {
        this.setState({ complimentName: e.target.value }, () => {
            console.log(this.state.complimentName);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleComplimentPrice = (e) => {
        this.setState({ complimentPrice: e.target.value }, () => {
            console.log(this.state.complimentPrice);
            return this.state.isSubmitted ? this.valid() : false;
        })
    }
    handleUpdateCompliment = (m) => {
        this.setState({
            isUpdate: true,
            complimentId: m._id,
            complimentName: m.name,
            complimentPrice: m.price
        })
    }
    handleFormClear = () => {
        this.setState({
            isSubmitted: false,
            isUpdate: false,
            complimentName: "",
            complimentPrice: "",
            complimentId: ""
        })
    }
    // ------------validaition --------
    valid() {
        let checked = true;
        if (this.state.complimentName === "") {
            checked = false;
        }
        if (this.state.complimentPrice === "") {
            checked = false;
        }
        return checked
    }
    // ---------------------------Api Call start-----------------
    // --------------get complimentary -------------
    getAllComplimentary = async () => {
        store.dispatch(loader(true));
        try {
            const compliment = await getComplementaries();
            this.setState({
                allComplimentary: compliment.data.data
            });
            store.dispatch(loader(false));
            ToastSuccess(compliment.data.data.message)
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
            ToastError(ex.response.data.message)
        }
    }
    // --------------create and update complimentary -------------
    complimentCreateUpdate = async () => {
        store.dispatch(loader(true));
        let price = parseInt(this.state.complimentPrice);
        const data = {
            "name": this.state.complimentName,
            "price": price
        }
        if (this.state.isUpdate) {
            try {
                const complimentUpdate = await UpdateComplementaries(this.state.complimentId, data);
                store.dispatch(loader(false));
                ToastSuccess(complimentUpdate.data.data.message)
                this.handleFormClear();
                this.getAllComplimentary();
            } catch (ex) {
                console.log(ex);
                store.dispatch(loader(false));
                ToastError(ex.response.data.message)
            }
        } else {
            try {
                const complimentCreate = await createComplementaries(data);
                store.dispatch(loader(false));
                ToastSuccess(complimentCreate.data.data.message)
                this.handleFormClear();
                this.getAllComplimentary();
            } catch (ex) {
                console.log(ex);
                store.dispatch(loader(false));
                ToastError(ex.response.data.message)
            }
        }
    }
    //  --------Delete complimentary-----------
    deleteComplimentaries = async (id) => {
        if (window.confirm("Are you sure this complimentaries is delete")) {
            try {
                const Delete = await deleteComplementaries(id);
                ToastSuccess(Delete.data.message);
                this.handleFormClear();
                this.getAllComplimentary();
            } catch (ex) {
                console.log(ex);
                ToastError(ex.message)
            }
        }
    }
    // ------------------------------Api Call end-----------------
    //----From submit---------
    handleSubmit = () => {
        this.setState({ isSubmitted: true })
        if (this.valid()) {
            this.complimentCreateUpdate();
        }
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="category-container">
                                <h4 className="text-secondary mb-4">All complementaries</h4>
                                {this.state.allComplimentary.map(item => {
                                    return (
                                        <div key={item._id} className="subs-box d-flex justify-content-between">
                                            <div className="ml-3">
                                                <h5 className="m-0 text-capitalize">{item.name}</h5>
                                                <b className="font-12 text-secondary">Price: ₹{item.price}</b>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <Tooltip title="Edit Offer" placement="right" arrow>
                                                    <div
                                                        onClick={() => this.handleUpdateCompliment(item)}
                                                    >
                                                        <ActionButton>
                                                            <i className="demo-icon icon-edit">&#xe80f;</i>
                                                        </ActionButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Delete Offer" placement="right" arrow>
                                                    <div
                                                        onClick={() => this.deleteComplimentaries(item._id)}
                                                    >
                                                        <ActionButton>
                                                            <i className="demo-icon icon-delete">&#xe80e;</i>
                                                        </ActionButton>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="category-container mb-3">
                                <div className="d-flex justify-content-between">
                                    <h5 className="text-secondary ml-2">Create complementaries</h5>
                                    <button
                                        onClick={() => { this.handleFormClear() }}
                                        className="clear-btn">Clear
                                    </button>
                                </div>
                                <div className="category-field">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group m-0">
                                                <label className="font-12 m-0">Complimentary name</label>
                                                <input
                                                    onChange={(e) => this.handleComplimentName(e)}
                                                    style={this.state.complimentName === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                    type="text"
                                                    value={this.state.complimentName}
                                                    className="form-control"
                                                    placeholder="Enter Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group m-0">
                                                <label className="font-12 m-0">Price</label>
                                                <input
                                                    onChange={(e) => this.handleComplimentPrice(e)}
                                                    style={this.state.complimentPrice === "" && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                    type="number"
                                                    value={this.state.complimentPrice}
                                                    className="form-control"
                                                    placeholder="Enter Amount" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <button
                                            className="btn btn-verify mt-2"
                                            onClick={this.handleSubmit}
                                        >
                                            {this.state.isUpdate ? "Update" : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(Complimentary);
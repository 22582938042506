import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TimePicker from 'rc-time-picker';
import { createSlot, getSlot } from '../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import moment from 'moment';
import { ToastSuccess } from "../component/toast-notification";
import SlotTab from '../component/c-auction-slot/slot-tab';

import Checkbox from '@material-ui/core/Checkbox';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const mapStateToProps = (store) => {
  return {
    loaderDisplay: store.loaderDisplay
  }
}
class AuctionSlot extends Component {
  constructor() {
    super();
    this.state = {
      slotDate: "",
      slotName: "",
      basePrice: 0,
      startTime: moment(),
      endTime: moment(),
      typePrivate: false,
      isSubmitted: false,
      activeSlot: [],
      expireSlot: []
    }
    // this.myFunction = this.allSlot.bind(this);
    this.allSlot();
  }

  handleSlotDate = (e) => {
    this.setState({
      slotDate: e.target.value
    }, () => {
      return this.state.isSubmitted ? this.valid() : false;
    });
  }
  handleSlotName = (e) => {
    this.setState({
      slotName: e.target.value
    }, () => {
      return this.state.isSubmitted ? this.valid() : false;
    });
  }

  handlePrice = (e) => {
    this.setState({
      basePrice: e.target.value
    }, () => {
      return this.state.isSubmitted ? this.valid() : false;
    });
  }

  handleStartTime = (value) => {
    this.setState({
      startTime: value
    }, () => {
      console.log(this.state.startTime);
      return this.state.isSubmitted ? this.valid() : false;
    });
  }
  handleEndTime = (value) => {
    this.setState({
      endTime: value
    }, () => {
      console.log(this.state.endTime);
      return this.state.isSubmitted ? this.valid() : false;
    });
  }
  handleRadioTypePrivate = () => {
    this.setState({
      typePrivate: !this.state.typePrivate
    }, () => {
      console.log(this.state.typePrivate);
    });
  }
  valid() {
    let checked = true;
    if (this.state.slotDate.length < 8) {
      checked = false;
    }
    if (this.state.slotName.length < 1) {
      checked = false;
    }
    if (this.state.startTime >= this.state.endTime) {
      checked = false;
    }
    return checked
  }
  // Api call----- 
  allSlot = async () => {
    store.dispatch(loader(true));
    try {
      const slotAll = await getSlot();
      const currentDate = moment(moment().local().format('YYYY-MM-DD'));
      const exp = [];
      const act = [];

      slotAll.data.data.forEach(m => {
        const slotDate = moment(m.slotDate).local();
        if (slotDate < currentDate) {
          exp.push(m);
        } else {
          act.push(m);
        }
      });
      this.setState({
        expireSlot: exp,
        activeSlot: act
      });
      // this.setState({ expireSlot: slotAll.data.data })
      store.dispatch(loader(false));
    } catch (ex) {
      console.log(ex);
      store.dispatch(loader(false));
    }
  }
  newSlot = async () => {
    store.dispatch(loader(true));
    const data = {
      "slotDate": this.state.slotDate,
      "name": this.state.slotName,
      "basePrice": this.state.basePrice,
      "slotStartTime": this.state.startTime.format('HH:mm'),
      "slotEndTime": this.state.endTime.format('HH:mm'),
      "isPrivate": this.state.typePrivate
    }
    try {
      const soltCreate = await createSlot(data)
      store.dispatch(loader(false));
      ToastSuccess(soltCreate.data.message);
      this.allSlot();
      this.setState({
        slotDate: "",
        slotName: "",
        basePrice: 0,
        startTime: moment(),
        endTime: moment(),
        typePrivate: false,
        isSubmitted: false
      })
    } catch (error) {
      console.log(error);
      store.dispatch(loader(false));
    }
  }
  submitted = () => {
    this.setState({ isSubmitted: true });
    if (this.valid()) {
      this.newSlot();
    }
  }
  render() {
    return (
      <div className="my-4">
        <div className="row">
          <div className="col-sm-8 order-2 order-sm-12">
            <div className="category-container">
              <h4 className="text-secondary">Slot List</h4>
              <SlotTab data={this.state.activeSlot} data1={this.state.expireSlot} myFunction={this.myFunction} />
            </div>
          </div>
          <div className="col-sm-4 order-1 order-sm-12">
            <div className="add-category-sticky">
              <div className="category-container">
                <div className="d-flex justify-content-between">
                  <h5 className="text-secondary ml-2">Create Slot</h5>
                </div>
                <div className="category-field">
                  <div className="form-group m-0">
                    <label className="font-12 m-0">Slot date</label>
                    <input
                      onChange={(e) => this.handleSlotDate(e)}
                      style={this.state.slotDate.length < 8 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                      type="date"
                      value={this.state.slotDate}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="category-field">
                  <div className="form-group m-0">
                    <label className="font-12 m-0">Slot Name</label>
                    <input
                      onChange={(e) => this.handleSlotName(e)}
                      style={this.state.slotName.length < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                      type="text"
                      value={this.state.slotName}
                      className="form-control"
                      placeholder="Note" />
                  </div>
                </div>

                <div className="category-field">
                  <div className="form-group m-0">
                    <label className="font-12 m-0">Base Price</label>
                    <input
                      onChange={(e) => this.handlePrice(e)}
                      style={this.state.basePrice < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                      type="number"
                      value={this.state.basePrice}
                      className="form-control"
                      placeholder="Note" />
                  </div>
                </div>

                <div className="category-field">
                  <div className="form-group m-0">
                    <label className="font-12 m-0">Start Time</label>
                    <TimePicker
                      onChange={this.handleStartTime}
                      // style={this.state.startTime >= this.state.endTime && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                      placeholder="HH:MM"
                      defaultValue={this.state.startTime}
                      value={this.state.startTime}
                      use12Hours={false}
                      showSecond={false}
                    />
                  </div>
                </div>
                <div className="category-field">
                  <div className="form-group m-0">
                    <label className="font-12 m-0">End Time</label>
                    <TimePicker
                      onChange={this.handleEndTime}
                      style={this.state.startTime >= this.state.endTime && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                      placeholder="HH:MM"
                      defaultValue={this.state.endTime}
                      value={this.state.endTime}
                      use12Hours={false}
                      showSecond={false}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <FormControlLabel
                      label="Private"
                      className="text-secondary"
                      control={<Checkbox color="default" checked={this.state.typePrivate} onChange={this.handleRadioTypePrivate} />}
                    />
                    <button className="btn btn-verify mt-2"
                      onClick={() => this.submitted()}
                    >Add</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AuctionSlot);
import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../action-button';
import { Modal } from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import ImageIcon from '@material-ui/icons/Image';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { ToastSuccess, ToastError } from "../toast-notification";
import ReactPaginate from 'react-paginate';
import {
  addAddon,
  productDeleteStatus,
  assignComplementaries,
  getComplementaries,
  deleteComplimentOutlet
} from '../../api/apiService';

class FoodListTable extends React.Component {
  isSubmitted = false;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectedValues: [],
      selectedValuesError: "",
      foodHabit: "",
      foodHabitError: "",
      quantity: null,
      quantityError: "",
      maxQty: null,
      maxQtyError: "",
      productId: "",
      pageCount: null,
      image: null,
      addonName: "",
      description: "",
      price: "",
      modalComentary: false,
      allComplimentary: [],
      complimentary: "",
      isModal: false
    }
    this.getAllComplimentary();
  }

  handleClose = () => this.setState({
    show: false,
    image: null,
    addonName: "",
    description: "",
    price: "",
  });

  handleSelectItem = (value) => {
    this.setState({
      selectedValues: value[0]._id,
      image: value[0].image,
      addonName: value[0].product_name,
      description: value[0].description,
      price: value[0].sell_price_without_tax,
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }

  handleChangeFoodHabit = (e) => {
    this.setState({
      foodHabit: e.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }

  handleChangeQuantity = (event) => {
    this.setState({
      quantity: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }

  handleChangeMaxQty = (event) => {
    this.setState({
      maxQty: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }

  valid = () => {
    let checked = true;
    this.setState({
      selectedValuesError: "",
      foodHabitError: "",
      quantityError: "",
      maxQtyError: "",
    })
    if (this.state.selectedValues.length < 1) {
      this.setState({ selectedValuesError: "Please select this" })
      checked = false;
    }
    if (this.state.foodHabit.length < 3) {
      this.setState({ foodHabitError: "Please enter food habit" });
      checked = false;
    }
    if (this.state.quantity === null) {
      this.setState({ quantityError: "Enter the Quantity" })
      checked = false;
    }
    if (this.state.maxQty === null) {
      this.setState({ maxQtyError: "Enter the max-quantity" })
      checked = false;
    }
    return checked;
  }

  modalValid = () => {
    let checked = true;
    if (this.state.complimentary === "") {
      checked = false
    }
    return checked
  }

  getAllComplimentary = async () => {
    try {
      const compliment = await getComplementaries();
      this.setState({
        allComplimentary: compliment.data.data
      });
      ToastSuccess(compliment.data.data.message)
    } catch (ex) {
      console.log(ex);
      ToastError(ex.response.data.message)
    }
  }

  assignComplement = async () => {
    let data = {
      "products": [this.state.productId]
    }
    try {
      const assign = await assignComplementaries(this.state.complimentary, data);
      ToastSuccess(assign.data.message)
      this.props.rerenderParentCallback(1);
      this.setState({ modalComentary: false, complimentary: "", isModal: false, productId: "" })
    } catch (ex) {
      console.log(ex);
      ToastError(ex.message)
    }
  }

  deleteCompliOutletAndFood = async (id) => {
    if (window.confirm("Do you want to delete the complimentaries ?")) {
      try {
        const complimentDelete = await deleteComplimentOutlet(id, "food");
        ToastSuccess(complimentDelete.data.message)
        this.props.rerenderParentCallback();
      } catch (ex) {
        console.log(ex);
        ToastError(ex.message)
      }
    }
  }

  handleDelete = async (productId) => {
    try {
      const confirm=window.confirm('After delete any food, can not recover it.Are you sure?');
      if(confirm){
        const response = await productDeleteStatus(productId);
        ToastSuccess(response.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      ToastError(error.response.data.message);
    }
  }

  submittedFoodCompliment = () => {
    this.setState({ isModal: true })
    if (this.modalValid()) {
      this.assignComplement();
    }
  }

  render() {
    return (
      <>
        {this.props.foodItem.length < 1 ? <h2 className="text-center">Food Not available</h2>
          :
          <div>
            <table className="table table-hover text-capitalize">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Food Name (Customizable)</th>
                  <th>Price</th>
                  <th>Tax</th>
                  <th>packaging_charges</th>
                  <th>Complimentary</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.foodItem.map((item, index) => {
                    return <tr key={item._id}>
                      <td>
                        <img src={item.image ? item.image : "/assate/images/food-logo.png"} className="img-fluid table-img" alt="" />
                      </td>
                      <td>
                        <Link to={`/outlets/add-on-product-table/${item._id}`}>{item.product_name} </Link>
                        {item.addons&&item.addons.length>0?
                        <i style={{color:'red'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8 c1.57,0,3.04,0.46,4.28,1.25l1.45-1.45C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12s4.48,10,10,10c1.73,0,3.36-0.44,4.78-1.22 l-1.5-1.5C14.28,19.74,13.17,20,12,20z M19,15h-3v2h3v3h2v-3h3v-2h-3v-3h-2V15z"/>                        </svg>
                        </i>:''}<br />
                        {!item.offer ? "" : <b>Offer {item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</b>}<br/>
                      </td>
                      <td>{item.sell_price_without_tax}</td>
                      <td>{item.tax}</td>
                      <td>{item.packaging_charges}</td>
                      <td>
                        {!item.complementary ?
                          <span onClick={() => this.setState({ modalComentary: true, productId: item._id })}>
                            <ActionButton>
                              <i className="demo-icon icon-add">&#xe802;</i>
                            </ActionButton>
                          </span>
                          :
                          <span className="badge bg-light text-dark">
                            <div className="d-flex align-items-center">
                              {item.complementary.name}
                              <BackspaceIcon
                                onClick={() => this.deleteCompliOutletAndFood(item._id)}
                                style={{ fontSize: "16px", color: "red", cursor: "pointer" }}
                              />
                            </div>
                          </span>
                        }
                      </td>
                      <td><span onClick={() => this.props.foodStatus(item._id)} className={`badge ${item.isActive ? "badge-success" : "badge-danger"} cursor-pointer`}>{item.isActive ? "Active" : "Deactive"}</span></td>
                      <td>
                        <span className="user-table-auction-item">
                          <Link to={`/outlets/product-info/${item.outlet}/${item._id}`}>
                            <ActionButton>
                              <i className="demo-icon icon-edit">&#xe80f;</i>
                            </ActionButton>
                          </Link>
                          <a onClick={() => this.handleDelete(item._id)} >
                            {/* {item.isDelete ? "Retrieve" : "Delete"} */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323" cursor='pointer'>
                              <path d="M0 0h24v24H0z" fill="none"/>
                              <path d="M0 0h24v24H0V0z" fill="none"/>
                              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
                            </svg>
                          </a>
                        </span>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
            <div>
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                pageCount={this.props.pagInfo.maxPageNo}
                onPageChange={this.props.foodPageFunc}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        }

        <Modal
          show={this.state.modalComentary}
          onHide={() => this.setState({ modalComentary: false, complimentary: "", isModal: false, productId: "" })}
          backdrop="static"
          size="md"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Complimentaries for food</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off">
              <div className="form-group">
                <label>Select Complementary</label>
                <select
                  value={this.state.complimentary}
                  className="form-control"
                  onChange={(e) => this.setState({ complimentary: e.target.value })}
                >
                  <option value="">Select Complementary</option>
                  {this.state.allComplimentary.map((items) => {
                    return <option value={items._id}>{items.name}</option>
                  })}
                </select>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => this.setState({ modalComentary: false, complimentary: "", isModal: false, productId: "" })} className="btn btn-danger">Cancel</button>
            <button onClick={this.submittedFoodCompliment} className="btn btn-primary">Save</button>
          </Modal.Footer>
        </Modal>

      </>
    )
  }
}

export default FoodListTable;

import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";
import { ToastSuccess, ToastError } from "../toast-notification";
import { Modal } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import BackspaceIcon from "@material-ui/icons/Backspace";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import {
  getOutlet,
  outletActiveStatus,
  addOutletManager,
  getComplementaries,
  assignComplementaries,
  deleteComplimentOutlet,
  outletActiveOpenStatus,
} from "../../api/apiService";
import moment from "moment";
import { connect } from "react-redux";
import { loader } from "../../Actions/index";
import store from "../../store";
import { Tooltip } from "@material-ui/core";

const mapStateToProps = (store) => {
  return {
    loaderDisplay: store.loaderDisplay,
  };
};

class OutletResturentDetails extends Component {
  time = moment().local().format("HH:mm");
  times = moment().local();
  date = moment().local().format("YYYY-MM-DD");
  constructor(props) {
    super(props);
    this.state = {
      singleOutletDetails: [],
      checkedOutletActive: false,
      isOpen: true,
      resturent: "",
      resturentClosingTime: "",
      resturentBreak: "",
      resturentBreakTimeIn: "",
      resturentBreakTimeStart: "",
      modalShow: false,
      isSubmitted: false,
      allComplimentary: [],
      complimentary: "",
      mobileNo: "",
      modalType: "",
    };
    this.getAllComplimentary();
    this.singleOutlet();
  }

  handleModalClose = (type) => {
    this.setState({ modalShow: !this.state.modalShow, modalType: type });
  };
  handleMobileNo = (e) => {
    this.setState(
      {
        mobileNo: e.target.value,
      },
      () => {
        return this.state.isSubmitted ? this.valid() : false;
      }
    );
  };
  // validation ///////////
  valid() {
    let checked = true;
    if (this.state.modalType === "manager") {
      if (this.state.mobileNo.length !== 10) {
        checked = false;
      }
      return checked;
    }
    if (this.state.modalType === "complimantory") {
      if (this.state.complimentary === "") {
        checked = false;
      }
      return checked;
    }
  }
  //  api call ------
  // All Complimantory ---------
  getAllComplimentary = async () => {
    try {
      const compliment = await getComplementaries();
      this.setState({
        allComplimentary: compliment.data.data,
      });
      ToastSuccess(compliment.data.data.message);
    } catch (ex) {
      console.log(ex);
      ToastError(ex.response.data.message);
    }
  };
  // Outlet Complimantory Add ---------
  assignComplement = async () => {
    let data = {
      outlets: [this.state.singleOutletDetails._id],
      // "products": [this.state.productId]
    };
    try {
      const assign = await assignComplementaries(
        this.state.complimentary,
        data
      );
      ToastSuccess(assign.data.message);
      this.setState({
        modalShow: false,
        modalType: false,
        isSubmitted: false,
        complimentary: "",
      });
      this.singleOutlet();
    } catch (ex) {
      console.log(ex);
      ToastError(ex.message);
    }
  };
  // Manager Add ----------
  newManagerAdd = async () => {
    store.dispatch(loader(true));
    let data = {
      mobile: this.state.mobileNo,
    };
    try {
      const addMng = await addOutletManager(this.props.data, data);
      console.log(data);
      this.setState({
        modalShow: false,
        mobileNo: "",
        isSubmitted: false,
      });
      this.singleOutlet();
      store.dispatch(loader(false));
    } catch (ex) {
      console.log(ex);
      console.log(ex.message);
      store.dispatch(loader(false));
    }
  };
  handleChangeStatus = (event) => {
    let self = this;
    outletActiveStatus(this.props.data)
      .then(function (resStatus) {
        self.setState((prvCheck) => ({
          checkedOutletActive: !prvCheck.checkedOutletActive,
        }));
        ToastSuccess(resStatus.data.message);
        window.location.reload();
        // this.singleOutlet();
      })
      .catch(function (error) {
        console.log(error);
        ToastError(error.response.data.message);
      });
  };

  handleChangeOpenStatus = (event) => {
    let self = this;
    outletActiveOpenStatus(this.props.data)
      .then(function (resStatus) {
        self.setState((prvCheck) => ({ isOpen: !prvCheck.isOpen }));
        ToastSuccess(resStatus.data.message);
        window.location.reload();
        // this.singleOutlet();
      })
      .catch(function (error) {
        console.log(error);
        ToastError(error.response.data.message);
      });
  };

  singleOutlet = () => {
    let self = this;
    getOutlet(self.props.data)
      .then(function (response) {
        self.setState({
          checkedOutletActive: response.data.data.isActive,
          isOpen: response.data.data.isOpen,
          singleOutletDetails: response.data.data,
        });
        console.log(self.state.singleOutletDetails);

        var now = moment(new Date()); //todays date
        var end = moment(
          `${self.date} ${self.state.singleOutletDetails.closing_time}`
        );
        var duration = moment.duration(end.diff(now));
        var sec = duration.asSeconds();
        var minutes = (sec % 3600) / 60;
        var hours = sec / 3600;
        self.setState({
          resturentClosingTime: `${hours.toFixed()}:${minutes.toFixed()}`,
          // resturentClosingTime: String(duration.format("hh:mm")),
        });

        if (
          self.time > self.state.singleOutletDetails.opening_time &&
          self.time < self.state.singleOutletDetails.closing_time
        ) {
          self.setState({ resturent: "open" });
        } else {
          self.setState({ resturent: "closed" });
        }

        // break_time

        var endS = moment(
          `${self.date} ${self.state.singleOutletDetails.break_start}`
        );
        var durations = moment.duration(endS.diff(now));
        self.setState({
          resturentBreakTimeStart: String(durations.format("hh:mm:ss")),
        });
        var endin = moment(
          `${self.date} ${self.state.singleOutletDetails.break_end}`
        );
        var durationin = moment.duration(endin.diff(now));
        self.setState({
          resturentBreakTimeIn: String(durationin.format("hh:mm:ss")),
        });
        if (self.time > self.state.singleOutletDetails.break_start) {
          self.setState({ resturentBreak: "breakin" });
        }
        if (self.time <= self.state.singleOutletDetails.break_start) {
          self.setState({ resturentBreak: "breakstart" });
        }
        if (self.time > self.state.singleOutletDetails.break_end) {
          self.setState({ resturentBreak: "breakclose" });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // offer delete for oulet ------
  deleteCompliOutletAndOutlet = async () => {
    let outletId = this.state.singleOutletDetails._id;
    if (window.confirm("Do you want to delete the complimentaries ?")) {
      try {
        const complimentDelete = await deleteComplimentOutlet(
          outletId,
          "outlet"
        );
        ToastSuccess(complimentDelete.data.message);
        this.singleOutlet();
      } catch (ex) {
        console.log(ex);
        ToastError(ex.message);
      }
    }
  };
  // Api call end---------
  // Modal Add  /////////////////////
  handleMobileNoAdd = () => {
    this.setState({ isSubmitted: true });
    if (this.valid()) {
      if (this.state.modalType === "manager") {
        this.newManagerAdd();
      }
      if (this.state.modalType === "complimantory") {
        this.assignComplement();
      }
    }
  };
  render() {
    return (
      <>
        {this.state.singleOutletDetails.length === 0 ? (
          <div></div>
        ) : (
          <div className="category-container my-3">
            <div className="resturent-details-container">
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="details-img-container">
                        <img
                          src={this.state.singleOutletDetails.outlet_images}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="bg-white p-2">
                        <p className="resturent-name">
                          {this.state.singleOutletDetails.outlet_name}
                        </p>
                        {this.state.singleOutletDetails.isActive &&(<>
                        <div className="d-flex align-items-center mb-2">
                          <div
                            className={`tag-icon ${this.state.resturent === "open"
                                ? "success-badge"
                                : "danger-badge"
                              } mr-1`}
                          >
                            <UpdateOutlinedIcon />
                          </div>
                          <div>
                            {this.state.resturent === "open" ? (
                              <span style={{ color: "#32c975" }}>
                                Close in {this.state.resturentClosingTime} hours
                              </span>
                            ) : (
                              <span style={{ color: "#ffa54e" }}>Closed</span>
                            )}
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          {this.state.resturentBreak === "breakin" || "breakin" ? (
                            <div className="tag-icon danger-badge mr-1">
                              <UpdateOutlinedIcon />
                            </div>
                          ) : null}

                          <div>
                            {this.state.resturentBreak === "breakstart" ? (
                              <span style={{ color: "#ffa54e" }}>
                                Break Start in{" "}
                                {this.state.resturentBreakTimeStart}
                              </span>
                            ) : this.state.resturentBreak === "breakin" ? (
                              <span style={{ color: "#ffa54e" }}>
                                Now break time, Reopen in {this.state.resturentBreakTimeIn}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        </>)}
                        <p className="resturent-address">
                          {
                            this.state.singleOutletDetails.address_details
                              .address
                          }
                        </p>
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-12">
                            <span className="font-12 font-weight-bold">
                              {" "}
                              Cost For Two ₹
                              {this.state.singleOutletDetails.cost_for_two}{" "}
                            </span>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div>
                              <span className="font-12 font-weight-bold">
                                Active / Deactive
                              </span>
                              <Switch
                                checked={this.state.checkedOutletActive}
                                onChange={this.handleChangeStatus}
                                name="checkedOutletActive"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </div>
                            {this.state.singleOutletDetails.isActive &&(
                              <div>
                                <span className="font-12 font-weight-bold">
                                  Open / Closed
                                </span>
                                <Switch
                                  checked={this.state.isOpen}
                                  onChange={this.handleChangeOpenStatus}
                                  name="isOpen"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.singleOutletDetails.isActive &&(
                  <div className="col-md-5">
                    <div>
                      <div className="resturent-time-box mr-2">
                        <p className="resturent-time-title">Resturent Time</p>
                        <div className="d-flex">
                          <div className="resturent-time success-badge mr-2">
                            <span className="time-type">Open</span>
                            <span className="time">
                              {this.state.singleOutletDetails.opening_time}
                            </span>
                          </div>
                          <div className="resturent-time danger-badge">
                            <span className="time-type">Close</span>
                            <span className="time">
                              {this.state.singleOutletDetails.closing_time}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="resturent-time-box">
                        <p className="resturent-time-title">Delivery Time</p>
                        <div className="d-flex">
                          <div className="resturent-time success-badge mr-2">
                            <span className="time-type">Start</span>
                            <span className="time">
                              {this.state.singleOutletDetails.delivery_start_time}
                            </span>
                          </div>
                          <div className="resturent-time danger-badge">
                            <span className="time-type">End</span>
                            <span className="time">
                              {this.state.singleOutletDetails.delivery_end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="resturent-time-box">
                        <p className="resturent-time-title">Break Time</p>
                        <div className="d-flex">
                          <div className="resturent-time success-badge mr-2">
                            <span className="time-type">Start</span>
                            <span className="time">
                              {this.state.singleOutletDetails.break_start}
                            </span>
                          </div>
                          <div className="resturent-time danger-badge">
                            <span className="time-type">End</span>
                            <span className="time">
                              {this.state.singleOutletDetails.break_end}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-start align-items-end flex-wrap">
                      <div>
                        <p className="text-capitalize">
                          <b>
                            {this.state.singleOutletDetails.managerDetails !==
                              null
                              ? this.state.singleOutletDetails.managerDetails
                                .full_name
                              : ""}
                          </b>
                        </p>
                        <button
                          onClick={() => this.handleModalClose("manager")}
                          type="button"
                          className="btn btn-verify"
                        >
                          {this.state.singleOutletDetails.managerDetails !== null
                            ? "Change manager"
                            : "Add manager"}
                        </button>
                      </div>
                      <div className="m-2"></div>
                      <div>
                        <div>
                          {!this.state.singleOutletDetails.complementary ||
                            this.state.singleOutletDetails.complementary ===
                            "null" ? (
                            ""
                          ) : (
                            <span className="text-capitalize badge bg-light text-dark font-15 my-2">
                              {this.state.singleOutletDetails.complementary.name}
                              <Tooltip
                                title="Delete complimantory"
                                placement="right"
                                arrow
                              >
                                <BackspaceIcon
                                  onClick={() =>
                                    this.deleteCompliOutletAndOutlet()
                                  }
                                  style={{
                                    fontSize: "16px",
                                    color: "red",
                                    cursor: "pointer",
                                    marginLeft: "3px",
                                  }}
                                />
                              </Tooltip>
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => this.handleModalClose("complimantory")}
                          type="button"
                          className="btn btn-verify"
                        >
                          {!this.state.singleOutletDetails.complementary ||
                            this.state.singleOutletDetails.complementary === "null"
                            ? "Add"
                            : "Change"}{" "}
                          complimantary
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <Modal
          show={this.state.modalShow}
          onHide={() => this.handleModalClose()}
          backdrop="static"
          size="sm"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>
                {this.state.modalType === "manager"
                  ? "Add manager"
                  : "Complimantory"}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.modalType === "manager" ? (
              <>
                <div className="form-group m-0">
                  <label className="font-12 m-0">Mobile no</label>
                  <input
                    onChange={(e) => this.handleMobileNo(e)}
                    style={
                      this.state.mobileNo.length !== 10 &&
                        this.state.isSubmitted
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="number"
                    value={this.state.mobileNo}
                    className="form-control"
                    placeholder="Enter your mobile number"
                  />
                </div>
                <div className="text-right mt-2">
                  <button
                    onClick={() => this.handleMobileNoAdd()}
                    type="button"
                    className="btn btn-verify"
                  >
                    Add
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  className="category-field mb-2"
                  // onChange={(e) => this.handleSearch("outlet", e)}
                  style={
                    this.state.complimentary === "" && this.state.isSubmitted
                      ? { border: "1px solid red", borderRadius: "4px" }
                      : { border: "1px solid #cccccc", borderRadius: "4px" }
                  }
                >
                  <Multiselect
                    isObject={true}
                    onRemove={(value) => {
                      this.setState({ complimentary: "" }, () => {
                        return this.state.isSubmitted ? this.valid() : false;
                      });
                    }}
                    onSelect={(value) => {
                      this.setState({ complimentary: value[0]._id }, () => {
                        return this.state.isSubmitted ? this.valid() : false;
                      });
                    }}
                    options={this.state.allComplimentary}
                    displayValue="name"
                    placeholder="Select complimentaries"
                    selectionLimit={1}
                    closeOnSelect={true}
                  />
                </div>
                <div className="text-right my-3">
                  <button
                    onClick={() => this.handleMobileNoAdd()}
                    type="button"
                    className="btn btn-verify"
                    data-toggle="modal"
                  >
                    Add For Outlet
                  </button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps)(OutletResturentDetails);

import React, { Component } from 'react';

class AuthContainer extends Component {
    render() {
        return (
            <>
            <div className="auth-bg-image">
                <img src="/assate/images/BG.svg"  alt="images" />
            </div>
                <div className="auth-container">
                    <div className="user-login">
                    <div className="text-center"><img src="/assate/images/hungry-logo.svg" className="logo-auth" alt="hungry" /></div>
                    {this.props.children}
                    </div>
            </div>
            </>
        );
    }
}
export default AuthContainer;

import React, { useEffect } from 'react';
import UserDetailsTab from '../component/c-usermanagement/user-datails-tab';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import Switch from '@material-ui/core/Switch';
import {
    userDetails,
    userActiveStatus,
    userUpdateAdmin,
    getOutletByOwner,
    changeNormalUser,
    userUpdateMerchant
} from '../api/apiService';
import DrawerRight from '../common/drawerRight';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function UserDetails(props) {
    const [details, setDetails] = React.useState({});
    const [tails, setTails] = React.useState(false);
    const [allOwnerOutlet, setAllOwnerOutlet] = React.useState([]);
    const [state, setState] = React.useState({
        checkedUserActive: false,
        checkedSuperAdmin: false,
        checkedMerchant: false,
    });
    const handleChange = (event) => {
        setState({ checkedUserActive: event.target.checked, checkedSuperAdmin: state.checkedSuperAdmin });
        activeStatus(props.match.params.userId);
    };
    const handleChangeAdmin = (event) => {
        updateAdmin(props.match.params.userId);
        setState({ checkedSuperAdmin: event.target.checked, checkedUserActive: state.checkedUserActive });
    };

    const rerenderParentCallback = (id) => {
        getUserDetails(id);
    }

    // api call
    useEffect(() => {
        getUserDetails(props.match.params.userId);
    }, []);
    const getUserDetails = async (userId) => {
        try {
            const uDetails = await userDetails(userId);
            setTails(uDetails.data.data.is_merchant_user);
            setDetails(uDetails.data.data);
            setState({
                checkedUserActive: uDetails.data.data.isActive,
                checkedSuperAdmin: uDetails.data.data.is_admin_user,
                checkedMerchant: uDetails.data.data.is_merchant_user
            });
            if (uDetails.data.data.is_merchant_user === true) {
                outletByOwner(userId);
            }
        } catch (error) {
            console.log(error);
        };
    }
    const outletByOwner = async (id) => {
        try {
            const allOutletByOwner = await getOutletByOwner(id);
            setAllOwnerOutlet(allOutletByOwner.data.data)
        } catch (er) {
            console.log(er);
        }
    }
    /*
    * Api
    * Change Normal user
    */
    const changeUser = async () => {
        if (window.confirm("Do you want to normal user?")) {
            try {
                const user = await changeNormalUser(props.match.params.userId);
                ToastSuccess(user.data.message);
                getUserDetails(props.match.params.userId)
            } catch (er) {
                console.log(er);
                ToastError(er.response.data.message);
            }
        }
    }
    /*
    * Api
    * user Active & Deactive
    */
    const activeStatus = (userId) => {
        userActiveStatus(userId).then(function (response) {
            ToastSuccess(response.data.message);
        })
            .catch(function (error) {
                console.log(error);
                ToastError(error.response.data.message);
            });
    }

    /*
    * Api
    * user change super admin
    */
    const updateAdmin = (userId) => {
        userUpdateAdmin(userId).then(function (response) {
            ToastSuccess(response.data.message);
        })
            .catch(function (error) {
                console.log(error);
                ToastError(error.response.data.message);
            });
    }
    const handleChangeMerchent = (event) => {
        userUpdateMerchant(details._id).then(function (response) {
            ToastSuccess(response.data.message);
            getUserDetails(details._id);
        })
            .catch(function (error) {
                console.log(error);
                ToastError(error.response.data.message);
            });
    }

    return (
        <>
            <div className="user-details-container my-4 text-capitalize">
                {details === 'undefined' || '' || null ?
                    <div></div>
                    :
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="user-details-header">
                                <div className="user-details-profile">
                                    <img src="/assate/images/profile.png" alt="User" className="user-details-img" />
                                </div>
                                <div className='mx-4'>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <h4>{details.full_name}</h4>
                                        <p className="font-12">Refer Code: <span className="text-color-primary">{details.referral_code}</span></p>
                                    </div>
                                    <p>{details.address_details?.length > 0 ? details.address_details[0].address : "address"}</p>
                                    <span className="text-color-primary mr-3">Lat({details.address_details?.length > 0 ? details.address_details[0].lat : ""})</span>
                                    <span className="text-color-primary">Long({details.address_details?.length > 0 ? details.address_details[0].long : ""})</span>
                                    <div className='mt-3'>
                                        <DrawerRight data={details.cart} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div>
                                <p>{details.email}</p>
                                <span className="text-color-primary">+91 {details.mobile}</span>
                            </div>
                            {details.is_merchant_user ?
                                <button
                                    onClick={changeUser}
                                    className="btn btn-verify mt-3">Change Normal User
                                </button>
                                :
                                <button
                                    className="btn btn-light mt-3">Normal User
                                </button>}
                        </div>
                        <div className="col-md-3 col-12">

                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={state.checkedUserActive} onChange={handleChange} name="Activate" />}
                                    label="Activate/Deactivate"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={state.checkedMerchant} onChange={handleChangeMerchent} name="Merchent" />}
                                    label="Merchent"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={state.checkedSuperAdmin} onChange={handleChangeAdmin} name="Super Admin" />}
                                    label="Super Admin"
                                />
                            </FormGroup>
                        </div>
                    </div>
                }
            </div>
            <div className="category-container mb-4">
                <UserDetailsTab
                    data={allOwnerOutlet}
                    isOwner={tails}
                    uDetails={details}
                    userId={props.match.params.userId}
                    rerenderParentCallback={rerenderParentCallback}
                />
            </div>
        </>
    );
}

export default UserDetails;
import React, { Component } from 'react';
import AllOrderDetailsTab from '../component/c-orders/all-order-details-tab';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countOrder: {}
        }
    }
    orderCunt = (count) => {
        this.setState({countOrder:count})
    }
    render() {
        return (
            <div className="my-4">
                <div className="d-sm-flex justify-content-between">
                    <div className="d-flex align-items-center justify-content-between notifi-box">
                        <div>
                            <div className="icon-container icon-1"><i className="demo-icon icon-add font-20">&#xe81b;</i></div>
                        </div>
                        <div className="text-center">
                            <div className="title">{this.state.countOrder.countAll}</div>
                            <div className="sub-title">Total Orders</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box">
                        <div>
                            <div className="icon-container icon-2"><i className="demo-icon icon-user font-20">&#xe81b;</i></div>
                        </div>
                        <div className="text-center">
                            <div className="title">{this.state.countOrder.countCompleted}</div>
                            <div className="sub-title">Completed</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box">
                        <div>
                            <div className="icon-container icon-3"><i className="demo-icon icon-owner font-20">&#xe81b;</i></div>
                        </div>
                        <div className="text-center">
                            <div className="title">{this.state.countOrder.countOutForDelivery}</div>
                            <div className="sub-title">Out for delivery</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box">
                        <div>
                            <div className="icon-container icon-3"><i className="demo-icon icon-owner font-20">&#xe81b;</i></div>
                        </div>
                        <div className="text-center">
                            <div className="title">{this.state.countOrder.countReadyForPickup}</div>
                            <div className="sub-title">Ready for Pickup</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box">
                        <div>
                            <div className="icon-container icon-3"><i className="demo-icon icon-owner font-20">&#xe81b;</i></div>
                        </div>
                        <div className="text-center">
                            <div className="title">{this.state.countOrder.countPreparing}</div>
                            <div className="sub-title">Preparing</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box">
                        <div>
                            <div className="icon-container icon-4"><i className="demo-icon icon-manager font-20">&#xe81b;</i></div>
                        </div>
                        <div className="text-center">
                            <div className="title">{this.state.countOrder.countOutletCanceled}</div>
                            <div className="sub-title">Outlet Canceled</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box">
                        <div>
                            <div className="icon-container icon-4"><i className="demo-icon icon-manager font-20">&#xe81b;</i></div>
                        </div>
                        <div className="text-center">
                            <div className="title">{this.state.countOrder.countCanceled}</div>
                            <div className="sub-title">Canceled</div>
                        </div>
                    </div>
                </div>
                <AllOrderDetailsTab
                    orderCunt={this.orderCunt}
                />
            </div>
        );
    }
}

export default Orders;
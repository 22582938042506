import React, { Component } from 'react';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { getAllUsers, postMultiNotify, uploadImage, allSearch } from '../../api/apiService';
import { ToastSuccess, ToastError, ToastDefault } from "../../component/toast-notification";
import SendIcon from '@material-ui/icons/Send';
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

class NotificationFromTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUserData: [],
            searchData: [],
            title: "",
            msg: "",
            image: null,
            msgImg: null,
            isSubmited: false,
            selectValue: [],
            dropMenu: false,
            searchText: ""
        }
        this.allUser();
    }
    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value })

    }
    handleChangeMsg = (e) => {
        this.setState({ msg: e.target.value })
    }
    onMsgImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                msgImg: img
            })
        }
    }// multi dropdown ----------
    dropdownMenuOpen = () => {
        this.setState({ dropMenu: !this.state.dropMenu })
    }
    handleClickOption = (e) => {
        let a = this.state.selectValue;
        let selectedOutletId = [];
        var index = a.findIndex(x => x._id === e._id)
        if (index === -1) {
            document.getElementById(e._id).checked = true;
            a.push(e);
        } else {
            var index = a.indexOf(e);
            if (index > -1) {
                document.getElementById(e._id).checked = false;
                a.splice(index, 1);
            }
        }
        a.forEach(m => { selectedOutletId.push(m._id) })
        this.setState({ selectValue: a, outletOffer: selectedOutletId });
    }
    deletDropSelectItem = (e) => {
        this.setState({
            selectValue: this.state.selectValue.filter(item => item._id !== e._id),
            outletOffer: this.state.outletOffer.filter(item => item._id !== e._id)
        }, () => {
            document.getElementById(e._id).checked = false;
        })
    }
    // Validation----------
    valid = () => {
        let checked = true
        if (this.state.selectValue.length === 0) {
            checked = false;
        }
        if (this.state.title === "") {
            checked = false;
        }
        if (this.state.msg === "") {
            checked = false;
        }
        return checked;
    }
    // Sand message --------
    sendMsg = () => {
        this.setState({ isSubmited: true })
        if (this.valid()) {
            this.sendImgMsg();
        }
    }
    // api call--------------------------
    // -------------------------------------Search Outlets & products -------------
    handleSearch = async (type, txt) => {
        this.setState({ searchText: txt.target.value })
        try {
            const search = await allSearch(type, encodeURIComponent(txt.target.value));
            let dataValue = search.data.data;
            this.setState({ searchData: dataValue })
        } catch (ex) {
            console.log(ex);
        }
    }
    sendImgMsg = async () => {
        store.dispatch(loader(true));
        if (this.state.msgImg) {
            try {
                const loadImg = await uploadImage(this.state.msgImg, "notifications");
                const data = {
                    "title": this.state.title,
                    "message": this.state.msg,
                    "imageUrl": loadImg.data.data.location,
                    "type": "general",
                    "save": false
                }
                let users = this.state.selectValue;
                for (let i = 0; i < users.length; i++) {
                    this.sendMultiNotify(users[i]._id, data)
                }
            } catch (ex) {
                store.dispatch(loader(false));
                console.log(ex.data.message);
            }
        } else {
            const data = {
                "title": this.state.title,
                "message": this.state.msg,
                "type": "general"
            }
            let users = this.state.selectValue;
            for (let i = 0; i < users.length; i++) {
                this.sendMultiNotify(users[i]._id, data)
            }
        }
    }
    // send notification -----------
    sendMultiNotify = async (userId, dataBody) => {
        store.dispatch(loader(true));
        try {
            const notify = await postMultiNotify(userId, dataBody);
            this.setState({
                title: "",
                msg: "",
                image: null,
                msgImg: null,
                isSubmited: false,
                selectValue: [],

            })
            store.dispatch(loader(false));

            if (notify.data.data.includes('success')) {
                ToastDefault(notify.data.data);
            }
            if (notify.data.data.includes('failed')) {
                ToastError(notify.data.data);
            }
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    };
    // Get All Users ----------------------------
    allUser = async (userType, pageNo) => {
        try {
            const users = await getAllUsers(userType, pageNo);
            this.setState({ allUserData: users.data.data })
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        return (
            <div>
                <div className="category-container overflow-auto">
                    <div className="msg-box-logo">
                        <h5 className="text-secondary">Single Notification</h5>
                        <img src="../assate/images/notification-bell.png" alt="" />
                    </div>
                    <div className="pt-0">
                        <div>
                            <div className="row mt-3">
                                <div className="col-sm-9">
                                    <div>
                                        <div className="select-box">
                                            <div
                                                className="options-container"
                                                style={this.state.dropMenu ? { maxHeight: "218px" } : { display: "none" }}>
                                                {this.state.searchText === "" ?
                                                    this.state.allUserData.map((item, i) => {
                                                        return (
                                                            <div
                                                                className="option"
                                                                key={item._id}
                                                                onClick={() => this.handleClickOption(item)}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="radio"
                                                                    value={item._id}
                                                                    id={item._id}
                                                                    name="category" />
                                                                <span className="ml-2">{item.full_name}</span><br />
                                                                <span className='font-12'>{item.mobile}</span>
                                                            </div>
                                                        );
                                                    })
                                                    :
                                                    this.state.searchData && this.state.searchData.map((item, i) => {
                                                        return (
                                                            <div
                                                                className="option"
                                                                key={item._id}
                                                                onClick={() => this.handleClickOption(item)}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="radio"
                                                                    value={item._id}
                                                                    id={item._id}
                                                                    name="category" />
                                                                <span className="ml-2">{item.full_name}</span><br />
                                                                <span className='font-12'>{item.mobile}</span>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                            <div className="selected"
                                                onClick={this.dropdownMenuOpen}
                                                style={this.state.selectValue < 1 && this.state.isSubmited ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                                            >
                                                {(this.state.dropMenu && this.state.selectValue.length) >= 1 || (!this.state.dropMenu && this.state.selectValue.length) >= 1 ? "" : "Select Users"}
                                                {this.state.selectValue.map((m, i) => {
                                                    return <span className="badge bg-warning text-dark mx-1" key={i}>
                                                        {m.full_name}
                                                        <BackspaceIcon
                                                            onClick={() => this.deletDropSelectItem(m)}
                                                            style={{ fontSize: "14px", color: "red" }}
                                                        />
                                                    </span>
                                                })}
                                            </div>
                                            <div
                                                className="search-box"
                                                style={this.state.dropMenu ? {} : { display: "none" }}
                                            >
                                                <input
                                                    onChange={(e) => this.handleSearch("user", e)}
                                                    id="myText"
                                                    type="search"
                                                    placeholder="Search ..."
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="error-msg"></p>
                                    <div className="form-group m-0">
                                        <input
                                            onChange={ev => this.handleChangeTitle(ev)}
                                            type="text"
                                            value={this.state.title}
                                            className="form-control"
                                            style={this.state.title === "" && this.state.isSubmited ? { border: "1px solid red" } : {}}
                                            placeholder="Title" />
                                    </div>
                                    <p className="error-msg"></p>
                                </div>
                                <div className="col-sm-3 pl-0">
                                    <div>
                                        <label className="msg-ing-container cursor-pointer" htmlFor="msgImg">
                                            <img
                                                src={this.state.image} alt=""
                                                value={this.state.image}
                                                // style={imageTwoError !== "" ? { border: "1px solid red" } : {}}
                                                className="upload-image" />
                                            <i style={this.state.image !== null ? { color: "transparent" } : {}}
                                                className="demo-icon icon-camera">&#xe807;</i>
                                            <input type="file" id="msgImg" accept="image/*" onChange={(e) => this.onMsgImage(e)} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="msg-container">
                                <textarea
                                    style={this.state.msg === "" && this.state.isSubmited ? { border: "1px solid red" } : {}}
                                    value={this.state.msg}
                                    onChange={(e) => this.handleChangeMsg(e)}
                                    rows="4" placeholder="Message ....." />
                                {/* <p className="error-msg"></p> */}
                            </div>
                            <div className="text-right">
                                <button
                                    onClick={() => this.sendMsg()}
                                    type="button"
                                    className="btn btn-verify mt-2" data-toggle="modal">
                                    <SendIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(NotificationFromTwo);
import React, { Component } from 'react';
import AuthContainer from '../common/auth-container';
import api from '../api';
import { Link } from 'react-router-dom';
import { ToastSuccess, ToastError } from "../component/toast-notification";

class OtpVerify extends Component {
    isSubmitted = false;
    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            otpError: "",
            errorMsg: "",
            time: {},
            seconds: 30,
        }
        if (!this.props.match.params.key) {
            this.props.history.push(`/`);
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    secondsToTime(secs) {
        let divisor_for_minutes = secs % (60 * 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            "s": seconds
        };
        return obj;
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
        }
    }

    handleChangeOtp = (event) => {
        this.setState({ otp: event.target.value },
            () => { return this.isSubmitted ? this.valid() : false; });

    };
    // Validation check
    valid() {
        let checked = true;
        this.setState({ otpError: "" });
        if (this.state.otp.length !== 4) {
            this.setState({ otpError: "Invalid OTP" });
            checked = false;
        }
        return checked;
    };
    // api call
    otpVerification = (e) => {
        let self = this;
        api.post('/verify-login', { key: this.props.match.params.key, otp: this.state.otp })
            .then(function (response) {
                localStorage.setItem('token', response.data.data.token);
                window.location = "/dashboard";
            })
            .catch(error => {
                self.setState({ errorMsg: error.response.data.message })
            });
    };
    //////////   Resend Otp api call
    ResendOtp = (e) => {
        let self = this;
        api.get(`/resend-otp/${this.props.match.params.key}`)
            .then(function (response) {
                ToastSuccess(response.data.message);
            })
            .catch(function (error) {
                ToastError(error.response.data.message);
                self.setState({ errorMessage: error.response.data.message })
            });

    };
    // From Submitted----
    submit(e) {
        e.preventDefault();
        this.isSubmitted = true;
        if (this.valid()) {
            this.otpVerification();
        }
    }
    render() {
        this.startTimer();
        return (
            <div>
                <AuthContainer>

                    <form onSubmit={(e) => this.submit(e)}>
                        <p className="error-msg">{this.state.errorMsg}</p>
                        <input
                            type="password"
                            className="user-input"
                            placeholder="Enter your OTP"
                            onChange={ev => this.handleChangeOtp(ev)}
                            style={this.state.otpError !== "" ? { border: "1px solid red" } : {}}
                            autoFocus
                        />
                        <p className="error-msg">{this.state.otpError}</p>
                        <button
                            style={this.state.otp.length === 4 ? { backgroundColor: "#D2A33B", color: "#FFFFFF" } : {}}
                            className="user-login-btn bg-btn-color"
                            type="submit">Submit
                        </button>
                        <div className="re-otp">
                            <span>Don't received code yet?
                            <Link to="#"
                            style={this.state.seconds === 0 ? {pointerEvents:"auto", color: "#D2A33B"} : {pointerEvents:"none"}}
                                    onClick={() => this.ResendOtp()}> Resend
                            </Link>
                            </span>
                            <span>{this.state.time.s} Secs</span>
                        </div>
                    </form>
                </AuthContainer>
            </div>
        );
    }

}

export default OtpVerify;
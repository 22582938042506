import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../action-button';

class OwnerOutletList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Restaurant Name</th>
                  <th>Store Address</th>
                  <th>Openig time</th>
                  <th>Closing time</th>
                  <th className="text-center">Publish / Unpublish</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                    this.props.data.map(item => {
                    return <tr key={item._id}>
                      <td><img className="img-fluid table-img" src={item.outlet_images ? item.outlet_images : "/assate/images/store-logo.png"} alt="" /></td>
                      <td><Link to={`/outlets/products/${item._id}`}>{item.outlet_name}</Link></td>
                      <td style={{ width: "26%" }}><div className="table-address">{item.address_details.address}</div></td>
                      <td>{item.opening_time}</td>
                      <td>{item.closing_time}</td>
                      <td className="text-center">{item.isActive ? <span className="badge badge-success">Publish</span> : <span className="badge badge-danger">Unpublish</span>}</td>
                      <td>
                        <div className="user-table-auction-item">
                          <Link to={`/outlets/product-info/${item._id}`}>
                            <ActionButton>
                              <i className="demo-icon icon-add">&#xe802;</i>
                            </ActionButton>
                          </Link>
                          <Link to={`/outlets/new-outlet/${item._id}`}>
                            <ActionButton>
                              <i className="demo-icon icon-edit">&#xe80f;</i>
                            </ActionButton>
                          </Link>
                          {/* <ActionButton>
                            <i className="demo-icon icon-delete">&#xe80e;</i>
                          </ActionButton> */}
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
                {/* <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Outlet Name</th>
                            <th>Product Name</th>
                            <th>Restaurant Name</th>
                            <th>Qty.</th>
                            <th>Sell Price</th>
                            <th>Residential Address</th>
                            <th>Bill Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map(item => {
                                return <tr key={item._id}>
                                    <td>{item.outlet_name}</td>
                                    <td>Consequat maecenas porttitor</td>
                                    <td>Vulputate quam</td>
                                    <td>4</td>
                                    <td>$750</td>
                                    <td>99/8 Jessore Road, Satgachi, South Dum Dum...</td>
                                    <td>$269</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
             */}
            </>
        );
    }
}

export default OwnerOutletList;
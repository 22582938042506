import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import { getProfileDetils } from '../api/apiService';
import { useHistory } from 'react-router-dom';
import SideManu from '../component/sideManu';
import { io } from "socket.io-client";
import { socketId } from '../Actions/index';
import { useDispatch } from 'react-redux';
import SideDrawer from '../component/side-drawer';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        socketId: state.loaderReducer.socketId,
        loaderDisplay: state.loaderDisplay
    }
}

function AdminContainer(props) {
    const history = useHistory();
    const [userData, setUserData] = useState();
    const [totalUsage, setTotalUsage] = useState("");
    const dispatch = useDispatch()

    // api call //////////////////////////
    useEffect(() => {
        profileDetails();
        //  Start Socket-io-client------------
        const socket = io(process.env.REACT_APP_SOCKET_URL,
            {
                withCredentials: true,
                transports: ["websocket"],
                auth: {
                    token: localStorage.getItem('token')
                }
            });

        socket.on("connection", (socket) => {
            console.log(socket); // prints { x: "42", EIO: "4", transport: "polling" }
        });

        socket.on("connect_error", (err) => {
            console.log(err);
        });

        socket.on("connect", () => {
            dispatch(socketId(socket));
            socket.emit("init", "admin")
            initSocket(socket);
        });

        socket.on("disconnect", () => {
            console.log(socket.id); // undefined
        });
        socket.connect();
        //  End Socket-io-client------------

    }, []);

    const initSocket = (socket) => {
        socket.on("current-users", (data) => {
            setTotalUsage(data.total)
        });
    }
    const logout = () => {
        // localStorage.removeItem('token');
        if (window.confirm("Admin logout")) {
            localStorage.clear();
            history.push(`/login`);
            window.location.reload();
        }
    }
    const profileDetails = () => {
        getProfileDetils().then(function (response) {
            setUserData(response.data.data);
            localStorage.setItem('referCode', response.data.data.referral_code);
        })
            .catch(function (error) {
                console.log(error.message);
                localStorage.clear();
                window.location.reload();
            });
    };
    return (
        <>
            <section className="nav-section sticky d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <img src="/assate/images/hungry-logo.svg" alt="hungry top" className="logo" />
                </div>
                <div className="d-flex align-items-center">
                    <div className="current-user">
                        <span className="current-user-count">{totalUsage}</span>
                        <span className="userName font-10 text-secondary mr-5">Current Users</span>
                    </div>
                    <span className="userName text-secondary">Hi! {userData?.full_name}</span>
                    <div>
                        <div className="dropdown d-inline-block">
                            <div type="button" data-toggle="dropdown">
                                <img src="/assate/images/profile.png" className="img-fluid user-profile" alt="hungry top" />
                                <KeyboardArrowDownIcon />
                            </div>
                            <div className="nav-drop dropdown-menu dropdown-menu-right">
                                {/* <Link className="dropdown-item nav-item" to="#"><AccountCircleIcon className="mr-2" /> Profile</Link> */}
                                {/* <Link className="dropdown-item nav-item" to="#"><SettingsIcon className="mr-2" /> Settings</Link> */}
                                <Link onClick={logout} className="dropdown-item nav-item text-danger" to="#"><ExitToAppIcon className="mr-2" /> Log out</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <SideDrawer />
            </section>
            <div className="d-flex p-t-60">
                <div className='left-panel'>
                    <div className="">
                        <SideManu />
                    </div>
                </div>
                <div className='right-panel'>
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default connect(mapStateToProps)(AdminContainer);




const initialState = {
    loaderDisplay: false,
    socketId: null
}

const loader = (state, action) => {
    return { ...state, loaderDisplay: action.loaderDisplay }
}
const socketId = (state, action) => {
    return { ...state, socketId: action.socketId }
}

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOADER":
            return loader(state, action);
        case "SOCKETID":
            return socketId(state, action);
        case "DECREMENT":
            return state - 1;
        case "RESET":
            return 0;
        default:
            return state;
    }
};
export default loaderReducer;
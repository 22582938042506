import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastSuccess = (content) => {
  return toast.success(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}

const ToastError = (content) => {
  return toast.error(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}

const ToastInfo = (content) => {
  return toast.info(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}
const ToastDefault = (content) => {
  return toast(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}

const ToastWarn = (content) => {
  return toast.warn(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}

export {
    ToastSuccess,
    ToastError,
    ToastInfo,
    ToastDefault,
    ToastWarn
}
import React, { Component } from 'react';
import ActionButton from '../component/action-button';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import {
    allCategory,
    uploadImage,
    addCategory,
    addSubCategory,
    updateCategory
} from '../api/apiService';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}
class Category extends Component {
    constructor() {
        super();
        this.state = {
            categories: [],
            categoryName: "",
            image: null,
            selectBox: "",
            addSubCategory: "",
            rowImage: null,
            isSubmitted: false,
            isSubmitOne: false,
            categoryId: "",
            dropMenu: false, // select box  start -----
            selectValue: [],
            searchCategoryList: [],
            searchText: ""
        }
        this.fetchCategory();
    }
    handleAddCategory = (ev) => {
        this.setState({
            categoryName: ev.target.value
        }, () => {
            return this.state.isSubmitted ? this.validCategory() : false;
        })
        console.log();
    }
    hendleClear = () => {
        this.setState({
            categoryName: "",
            image: null,
            categoryId: "",
            isSubmitOne: false,
            addSubCategory: "",
            selectValue: [],
            searchText: ""
        })
    }
    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                rowImage: img
            }, () => {
                return this.state.isSubmitted ? this.validCategory() : false;
            })
        }
    };
    handleAddSubCategory = (event) => {
        this.setState({
            addSubCategory: event.target.value,
        }, () => {
            return this.state.isSubmitOne ? this.validSubCategory() : false;
        });

    }
    handleSearch = (e) => {
        let filter = e.target.value.toUpperCase();
        let list = this.state.categories;
        let arr = [];
        for (var i = 0; i < list.length; i++) {
            let a = list[i].category_name.toUpperCase();
            if (a.indexOf(filter) > -1) {
                arr.push(list[i]);
            }
        }
        this.setState({ searchCategoryList: arr, searchText: filter });
    }
    //  Vaslidation ---
    validCategory = () => {
        let checked = true;
        if (this.state.categoryName.length < 1) {
            checked = false;
        }
        if (this.state.image === null) {
            checked = false;
        }
        return checked;
    }
    validSubCategory = () => {
        let checkedOne = true;
        if (this.state.selectValue.length === 0) {
            checkedOne = false;
        }
        if (this.state.addSubCategory.length < 1) {
            checkedOne = false;
        }
        return checkedOne;
    }
    //  Api call----
    fetchCategory = async () => {
        try {
            store.dispatch(loader(true));
            const category = await allCategory();
            this.setState({ categories: category.data.data.main_category })
            store.dispatch(loader(false));
        } catch (error) {
            console.log(error);
            store.dispatch(loader(false));
        }
    }
    // Submitted ----
    submitCategory = async () => {
        this.setState({ isSubmitted: true })
        if (this.validCategory()) {
            try {
                var data;
                if (this.state.rowImage) {
                    const img = await uploadImage(this.state.rowImage, "categories");
                    data = {
                        "category_name": this.state.categoryName,
                        "image": img.data.data.location
                    }
                } else {
                    data = {
                        "category_name": this.state.categoryName,
                        "image": this.state.image
                    }
                }

                if (this.state.categoryId === "") {
                    const createCategory = await addCategory(data);
                    this.fetchCategory();
                    ToastSuccess(createCategory.data.message);
                } else {
                    const categoryUpdate = await updateCategory(this.state.categoryId, data);
                    this.fetchCategory();
                    ToastSuccess(categoryUpdate.data.message);
                }
            } catch (ex) {
                console.log(ex);
                ToastError(ex.response.data.message);
            }

        }
    }

    async submitSubCategory() {
        this.setState({ isSubmitOne: true });
        const data = {
            "sub_category_name": this.state.addSubCategory,
            "image": "url"
        }
        if (this.validSubCategory()) {
            try {
                const createSubCategory = await addSubCategory(this.state.selectValue[0]._id, data);
                this.fetchCategory();
                this.hendleClear();
                ToastSuccess(createSubCategory.data.message);
            } catch (error) {
                console.log(error);
                ToastError(error.response.data.message);
            }
        }
    }
    render() {
        return (
            <div className="my-4">
                <div className="row">
                    <div className='col-sm-8'>
                        <div className="category-container ">
                            <h2 className="text-secondary">Categories</h2>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.categories.map(item => {
                                        return <tr key={item._id}>
                                            <td>
                                                <img src={item.image ? item.image : "/assate/images/food-logo.png"} className="table-img" alt="" />
                                            </td>
                                            <td>{item.category_name}</td>
                                            <td>
                                                {item.sub_category.map(i => {
                                                    return <span key={i} className="badge bg-btn-color p-1 mr-1">{i}<i className="demo-icon mx-1 cursor-pointer">&#xe808;</i></span>
                                                })}
                                            </td>
                                            <td className="text-center">{item.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Deactive</span>}</td>
                                            <td>
                                                <span onClick={() => this.setState({
                                                    categoryId: item._id,
                                                    categoryName: item.category_name,
                                                    image: item.image,
                                                })
                                                }>
                                                    <ActionButton>
                                                        <i className="demo-icon icon-edit">&#xe80f;</i>
                                                    </ActionButton>
                                                </span>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className="add-category-sticky">
                            <div className="category-container mb-3">
                                <div className="d-flex justify-content-between">
                                    <h5 className="text-secondary ml-2">Create category</h5>
                                    {this.state.categoryId === "" ? "" : <button onClick={() => this.hendleClear()} className="clear-btn">Clear</button>}
                                </div>
                                <div className="category-field">
                                    <label className="font-12 m-0">Category</label>
                                    <div className="form-group d-flex m-0">
                                        <div className="category-image-container">
                                            <div>
                                                <label className="upload-category-image cursor-pointer" htmlFor="file3">
                                                    <img src={this.state.image} alt=""
                                                        style={this.state.image === null && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                                        value={this.state.image}
                                                        className="category-image" />
                                                    <i style={this.state.image != null ? { color: "transparent" } : {}}
                                                        className="demo-icon icon-camera">&#xe807;</i>
                                                    <input type="file" id="file3" accept="image/*" onChange={(e) => this.onImageChange(e)} />
                                                </label>
                                            </div>
                                        </div>
                                        <input
                                            onChange={(ev) => this.handleAddCategory(ev)}
                                            style={this.state.categoryName.length < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                            type="text"
                                            value={this.state.categoryName}
                                            className="form-control"
                                            placeholder="Category" />
                                    </div>
                                    <div className="text-right">
                                        <button onClick={() => this.submitCategory()} className="btn btn-verify mt-2 ">{this.state.categoryId === "" ? "Add" : "Update"}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="category-container">
                                <h5 className="text-secondary ml-2">Create Sub Category</h5>
                                <div className="category-field">
                                    <label className="font-12 m-0">Select Category</label>



                                    <div className="select-box">
                                        <div
                                            className="options-container"
                                            style={this.state.dropMenu ? {} : { display: "none" }}>
                                            {this.state.searchCategoryList.length === 0 && this.state.searchText === "" ?
                                                this.state.categories.map((item, i) => {
                                                    return (
                                                        <div
                                                            className="option"
                                                            key={item._id}
                                                            onClick={() => {
                                                                this.setState({ selectValue: [item] }, () => {
                                                                    console.log(this.state.selectValue[0]._id);
                                                                    return this.state.isSubmitOne ? this.validSubCategory() : false;
                                                                })
                                                            }}
                                                        >
                                                            <label className="ml-1 text-capitalize">{item.category_name}</label>
                                                        </div>
                                                    );
                                                })
                                                :
                                                this.state.searchCategoryList.map((item, i) => {
                                                    return (
                                                        <div
                                                            className="option"
                                                            key={item._id}
                                                            onClick={() => {
                                                                this.setState({ selectValue: [item] }, () => {
                                                                    return this.state.isSubmitOne ? this.validSubCategory() : false;
                                                                })
                                                            }}
                                                        >
                                                            <label className="ml-1 text-capitalize">{item.category_name}</label>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div className="selected"
                                            onClick={() => this.setState({ dropMenu: !this.state.dropMenu })}
                                            style={this.state.selectValue.length === 0 && this.state.isSubmitOne ? { border: "1px solid red", borderRadius: "4px" } : { border: "1px solid #cccccc", borderRadius: "4px" }}
                                        >
                                            {(this.state.dropMenu && this.state.selectValue.length) >= 1 || (!this.state.dropMenu && this.state.selectValue.length) >= 1 ? "" : "Select Category"}
                                            {this.state.selectValue.map((m, i) => {
                                                return <span className="badge bg-warning text-dark mx-1 text-capitalize" key={i}>
                                                    {m.category_name}
                                                </span>
                                            })}
                                        </div>
                                        <div
                                            className="search-box"
                                            style={this.state.dropMenu ? {} : { display: "none" }}
                                        >
                                            <input
                                                onKeyUp={(e) => this.handleSearch(e)}
                                                type="search"
                                                placeholder="Search ..."
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="category-field">
                                    <div className="form-group m-0">
                                        <label className="font-12 m-0">Sub Category</label>
                                        <input
                                            onChange={(e) => { this.handleAddSubCategory(e) }}
                                            style={this.state.addSubCategory.length < 1 && this.state.isSubmitOne ? { border: "1px solid red" } : {}}
                                            type="text"
                                            value={this.state.addSubCategory}
                                            className="form-control"
                                            placeholder="Sub Category" id="usr" />
                                    </div>
                                    <div className="text-right">
                                        <button onClick={() => this.submitSubCategory()} className="btn btn-verify mt-2">Add</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Category);